<template>
  <div>
    <portal-target name="onboarding-overlay"/>
    <move-banner v-if="showMoveBanner"></move-banner>
    <router-view></router-view>

    <portal-target name="onboarding-footer"/>

    <div
      v-if="$store.state.routerSpinner"
      id="get-current-mixin-wrap"
    >
      <CSpinner
        id="get-current-mixin-loader"
        color="primary"
      />
    </div>

    <p
      class="app-copyright"
      :class="{'_dark-theme': $store.getters.darkMode}"
    >
      © Copyright {{year}} Cryptovizor. All rights reserved
      <span v-if="isNotProduction">. sha commit: {{ hash }}</span>
    </p>

    <portal v-if="isBrave" to="brave-notify-modal">
      <div>
        <CModal
          :title="`${$t('lk.account.browserNotificationsEnable')}`"
          scrollable
          centered
          size="xl"
          :show="showBraveModal"
          @update:show="hideBraveModal"
        >
          <div>
            <span v-html="$t('lk.account.browserNotificationsBraveErrorStart')"/>
            <CButton
            @click="copyLink"
            color='secondary'
            class="notificationInfoModal__action-button">
              {{ $t('general.copyLink') }}
              <CIcon
              name="copy" />
            </CButton>
            {{ $t('lk.account.browserNotificationsBraveErrorEnd') }}
          </div>
          <template #footer>
            <CButton
              color="primary"
              @click="hideBraveModal"
            >
              OK
            </CButton>
          </template>
        </CModal>
      </div>
    </portal>
    <portal
      v-if="isGuest || showLoginModal"
      to="login"
    >
      <login-modal
        :show="showLoginModal"
      />
    </portal>

  </div>
</template>

<script>
import {copyTextToClipboard} from '@/assets/js/plugins/copyText'
import {mapActions, mapGetters, mapMutations, mapState} from "vuex"
import {NOTIFICATIONS_TURNED_OFF_MANUALLY, WATCH_NOTIFY_INSTRUCTIONS_BRAVE} from '@/assets/js/constants'
import MoveBanner from './components/common/MoveBanner.vue'
import router, {routeLang} from '@/router'
import {setDocumentTitle} from '@/assets/js/routerFunctions/checkLangHandler'
import LoginModal from '@/components/LoginModal'
import Settings from './pages/lk/Settings.vue'

export default {
  components: { MoveBanner, LoginModal},
  name: 'App',
  data() {
    return {
      settings: Settings,
      year: process.env.VUE_APP_BUILD_YEAR || new Date().getFullYear(),
      showBraveModal: false,
      // tablesData: localStorage.getItem('tablesData'),
      // intervalId: null,
    }
  },
  computed: {
    showMoveBanner(){
      if(window.location.href.includes('panel.tt.tools')){
        setTimeout(()=>{window.location.href = "https://panel.cvizor.com/"}, 30000)
        return true
      }
      return false
    },
    ...mapGetters({
      isBrowserTabActive: 'isBrowserTabActive',
      getPermissions: 'user/getPermissions',
      getTariff: 'user/tariff',
      getEduTariffs: 'user/eduTariffs',
      userId: 'user/id',
      showLoginModal: 'user/showLoginModal',
      isGuest: 'user/isGuest'
    }),
    ...mapState({
      swRegistration: state => state.browserNotify.swRegistration,
      swIsBlocked: state => state.browserNotify.swIsBlocked,
      isSubscribed: state => state.browserNotify.isSubscribed,
      isLoading: state => state.browserNotify.isLoading,
      userData: state => state.user.userData,
      braveError: state => state.browserNotify.braveError,
      isBrave: state => state.browserNotify.isBrave,
      isSafari: state => state.browserNotify.isSafari,
    }),
    viewNotifications() {
      return this.getPermissions('view_notifications')
    },
    hasEduTariff(){
      return this.getPermissions('view-education')
    },
    hasEduDaytradingFutures(){
      return this.getEduTariffs.includes('standard_edu') || this.getEduTariffs.includes('all_in_edu')
    },
    hasEduWeb3(){
      return this.getEduTariffs.includes('web3_edu')
    },
    hasTariff(){
      return this.getTariff
    },
    fromEdu(){
      return localStorage.getItem('referrer') === 'edu.cvizor.com'
    },
    fromWeb3(){
      return localStorage.getItem('referrer') === 'web3.cvizor.com'
    },
    fromCvizor(){
      return localStorage.getItem('referrer') === 'cvizor.com'
    },
    isNotProduction() {
      return process.env.NODE_ENV !== 'production' || !!process.env.VUE_APP_DEV
    },
    hash() {
      return process.env.VUE_APP_GIT_HASH
    },
  },
  watch: {
    'userData.id'(id) {
      if (id && this.userData?.emailVerifiedAt) {
        if (this.swRegistration) {
          this.subscribeUserMethod()
        } else {
          this.swRegistrationAction().then(this.subscribeUserMethod)
        }
      }
    },
    // isBrowserTabActive:{
    //   immediate: true,
    //   handler: function (val) {
    //     if (!val) {
    //       this.startInterval()
    //     } else {
    //       clearInterval(this.intervalId)
    //     }
    //   }
    // },
    userId: {
      immediate: true,
      handler(value) {
        this.redirectHandler(value)
      }
    },
    '$i18n.locale': {
      async handler(value) {
        if(this.$store.state.initLocale){
          const rLang = routeLang()
          if(rLang && rLang !== value){
            await router.push(router.currentRoute)
          }
          setDocumentTitle()
        }
      }
    }
    /*isVerify: {
      immediate: true,
      handler(value) {
        this.redirectHandler(value)
      }
    }*/
  },
  created() {
    if(document.referrer){
      let ref = null
      if(document.referrer.includes('://edu.cvizor.com')){
        ref = 'edu.cvizor.com'
      }else if(document.referrer.includes('://web3.cvizor.com')){
        ref = 'web3.cvizor.com'
      }else if(document.referrer.includes('://cvizor.com')){
        ref = 'cvizor.com'
      }
      if(ref && ref !== localStorage.getItem('referrer')){
        localStorage.setItem('referrer', ref)
        window.location.reload()
      }
    }

    let initDarkMode = localStorage.getItem('settings.global.darkMode')
    if(initDarkMode === null || initDarkMode === undefined){
      initDarkMode = 1
    }

    let initLocale = localStorage.getItem('settings.global.locale')

    if(initLocale && ['ru', 'en'].indexOf(initLocale) !== -1){
      this.$i18n.locale = initLocale
    }

    this.$store.dispatch('setInit', {
      global: {
        darkMode: !!initDarkMode,
      }
    })

    this.SET_IS_BRAVE(!!navigator.brave)

    this.changeDeviceWidth(window.innerWidth)

    window.addEventListener('resize', this.changeDeviceWidth)
    window.addEventListener('getBars', this.getBarsHandler)
    window.addEventListener('visibilitychange', this.browserTabClick)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.changeDeviceWidth)
    window.removeEventListener('getBars', this.getBarsHandler)
    window.removeEventListener('visibilitychange', this.browserTabClick)
    // clearInterval(this.intervalId);
  },
  methods: {
    // startInterval(){
    //   this.intervalId = setInterval(() => {
    //     this.lsChangeHandler()
    //   }, 1000)
    // },
    // lsChangeHandler(){
    //   if(this.tablesData !== localStorage.getItem('tablesData') && !this.$store.getters.isBrowserTabActive){
    //     this.$store.commit('setTablesData', JSON.parse(localStorage.getItem('tablesData')))
    //   }
    // },
    browserTabClick(){
      if(document.visibilityState === 'visible') this.$store.commit('setIsBrowserTabActive', true)
      if(document.visibilityState === 'hidden') this.$store.commit('setIsBrowserTabActive', false)
    },
    copyLink() {
      copyTextToClipboard('brave://settings/privacy', (status) => {
        if (status) {
          this.$toastr.success(this.$t('lk.referrals.copy_referral_link'));
        } else {
          this.$toastr.error(this.$t('lk.referrals.copy_referral_link_fail'));
        }
      });
    },
    ...mapActions({
      swRegistrationAction: 'browserNotify/swRegistrationAction',
      subscribeUser: 'browserNotify/subscribeUser',
    }),
    ...mapMutations({
      SET_IS_BRAVE: 'browserNotify/SET_IS_BRAVE',
      SET_BRAVE_ERROR: 'browserNotify/SET_BRAVE_ERROR',
    }),
    redirectHandler(value){
      if(value){
        if(this.fromEdu && !this.hasEduDaytradingFutures){
          if(!(location.pathname || location.href || '').includes('lk/subscribe/daytrading-futures')){
            localStorage.removeItem('referrer')
            location.href = '/lk/subscribe/daytrading-futures'
          }
          /*@todo проблема при редиректе - после проверки hasEduTariff направляет на подтверждение емайл
          this.$router.push({
            name: 'training-detail',
            params: { slug: 'daytrading-futures' }
          })*/
        }else if(this.fromWeb3 && !this.hasEduWeb3){
          if(!(location.pathname || location.href || '').includes('lk/subscribe/web3')){
            localStorage.removeItem('referrer')
            location.href = '/lk/subscribe/web3'
          }
          /*this.$router.push({
            path: 'lk/subscribe/web3'
          })*/
        }else if(this.fromCvizor && !this.userData.tariff){
          localStorage.removeItem('referrer')
          this.$router.push({
            name:'lk-subscribe',
          })
        }
      }
    },
    changeDeviceWidth() {
      this.$store.commit('mediaQuery/SET_DEVICE_WIDTH', window.innerWidth)
    },
    subscribeUserMethod() {
      const notificationsTurnedOffManually = !!localStorage.getItem(NOTIFICATIONS_TURNED_OFF_MANUALLY)
      const watchNotifyInstructionsBrave = !!localStorage.getItem(WATCH_NOTIFY_INSTRUCTIONS_BRAVE)

      if (!this.isSubscribed && (Notification.permission === 'default'
          || (Notification.permission === 'granted' && !notificationsTurnedOffManually))
        && (this.swRegistration || this.isSafari) && !this.swIsBlocked && !this.isLoading
        && this.viewNotifications) {
        this.subscribeUser()
          .catch(() => {
            if (this.isBrave) {
              if (this.braveError && !watchNotifyInstructionsBrave) {
                this.showBraveModal = true
              } else {
                this.SET_BRAVE_ERROR(false)
              }

              this.$watch('braveError', (val) => {
                this.showBraveModal = val
              })
            }
          })
      }
    },
    hideBraveModal() {
      this.showBraveModal = false

      if (this.isBrave) {
        this.SET_BRAVE_ERROR(false)
        localStorage.setItem(WATCH_NOTIFY_INSTRUCTIONS_BRAVE, '1')
      }
    },
    getBarsHandler(e) {
      if(!e.detail || !e.detail.symbol || !e.detail.resolution || !e.detail.bars){
        return
      }
      const key = e.detail.symbol + '_' + e.detail.resolution
      if(!this.$store.state.deltaMap[key]){
        this.$store.state.deltaMap[key] = {}
      }
      if(!this.$store.state.raketa[key]){
        this.$store.state.raketa[key] = {}
      }
      e.detail.bars.forEach(item => {
        this.$store.state.deltaMap[key][item.time] = [item.delta || 0, item.adelta || 0, item.trades || 0]
        if(item.raketa !== undefined){
          this.$store.state.raketa[item.time] = item.raketa || 0
        }
      })
    }
  },
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'assets/scss/style';

.c-body {
  &.onboarding-delta--active {
    .resizer {
      display: none;
    }

    .overall-volume-wrap,
    .market-volume {
      width: 35vw !important;
    }

    #volume-group {
      overflow: hidden;
    }

    #move-to-top-arrow {
      display: none !important;
    }
  }
}

.c-app,
.min-vh-100,
.c-wrapper {
  min-height: 100vh;
  min-height: 100dvh;
}
</style>

<style lang="scss" scoped>
.app-copyright {
  @include media-breakpoint-down(lg) {
    display: none;
  }
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  font-size: 0.75rem;
  color: #4c5571; // var(--color)
  &._dark-theme {
    color: #a4a4ac; // var(--color)
  }
}

</style>
