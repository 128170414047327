<template>
  <CModal
    class="trends-notification-modal"
    :title="$t('lk.notifications.modalTitle')"
    scrollable
    centered
    size="lg"
    :show="modalNotificationOpen"
    @update:show="closeEvent"
  >
    <div class="notify-control mb-4">
      <div class="browser">
        <web-push-toggle
          v-if="
            ($store.getters['user/getPermissions']('view_notifications') &&
              !isSubscribed) ||
            isWebNotifyDisabled
          "
        />
      </div>
      <div class="telegram">
        <confirm-tg :isRequestPassed="telegramVerify" />
        <div class="d-flex align-items-center">
          <span
            class="mr-1"
            v-if="!trendsBotEnabled"
          >
            {{ $t("lk.notifications.trendsBotText") }}
          </span>
          <confirm-tg
            :isShow="!trendsBotEnabled"
            :link="trendsBotUrl"
            :isRequestPassed="!!trendsBotEnabled"
            :text="$t('lk.notifications.trendsBotLink')"
          />
          <!-- <a :href="trendsBotUrl" target="_blank">
            {{ $t('lk.notifications.trendsBotLink') }}
          </a> -->
        </div>
      </div>
    </div>
    <div class="divider mb-4 bg-dark"></div>
    <div class="top-notification mb-4">
      <div class="title">
        Уведомления по монетам
        <a
          :href="trendsBotUrl"
          :target="'_blank'"
          :class="'subTitle ml-3 text-primary'"
        >
          {{ $t("lk.notifications.trendsBotLinkText") }}
        </a>
      </div>
      <div class="left">
        <span class="d-flex mb-3"> Таймфрейм: </span>
        <div class="badge-group">
          <Badge
            :value="'30m'"
            :active="trendsNotify?.m30?.id !== undefined"
            @click="handleTfClick('m30')"
          />
          <Badge
            :value="'1h'"
            :active="trendsNotify?.m60?.id !== undefined"
            @click="handleTfClick('m60')"
          />
          <Badge
            :value="'2h'"
            :active="trendsNotify?.m120?.id !== undefined"
            @click="handleTfClick('m120')"
          />
          <Badge
            :value="'4h'"
            :active="trendsNotify?.m240?.id !== undefined"
            @click="handleTfClick('m240')"
          />
          <Badge
            :value="'12h'"
            :active="trendsNotify?.m720?.id !== undefined"
            @click="handleTfClick('m720')"
          />
        </div>
      </div>
      <div class="right">
        <span class="d-flex mb-3"> Куда отправлять </span>
        <CButtonGroup class="d-flex">
          <CButton
            v-tooltip="{ content: getTooltipContent('telegram') }"
            :color="'dark'"
            :class="['cbutton', { disabled: getSendTypeDisabled('telegram') }]"
            :variant="getSendType('tfTrend', 'telegram') >= 0 ? '' : 'outline'"
            :disabled="loading"
            @click="handleSendType('tfTrend', 'telegram')"
          >
            telegram
          </CButton>
          <CButton
            v-tooltip="{ content: getTooltipContent('browser') }"
            :color="'dark'"
            :variant="getSendType('tfTrend', 'browser') >= 0 ? '' : 'outline'"
            :class="['cbutton', { disabled: getSendTypeDisabled('browser') }]"
            :disabled="loading"
            @click="handleSendType('tfTrend', 'browser')"
          >
            browser
          </CButton>
        </CButtonGroup>
      </div>
      <div class="middle">
        <span class="d-flex mb-3">
          {{ $t("trends.sendByTrend") }}
        </span>
        <CButtonGroup class="d-flex">
          <CButton
            :disabled="loading"
            color="dark"
            class="cbutton"
            :variant="getSendType('tfTrend', 'up') >= 0 ? '' : 'outline'"
            @click="handleSendType('tfTrend', 'up')"
          >
            {{ $t("trends.up") }}
          </CButton>
          <CButton
            :disabled="loading"
            color="dark"
            class="cbutton"
            :variant="getSendType('tfTrend', 'down') >= 0 ? '' : 'outline'"
            @click="handleSendType('tfTrend', 'down')"
          >
            {{ $t("trends.down") }}
          </CButton>
        </CButtonGroup>
      </div>
      <div class="bottom">
        <div class="d-flex mb-3 bottom__title">
          <span>Отслеживаемые монеты:</span>
          <CButton
            color="primary"
            @click="handleSelectAllButton"
          >
            Выбрать все
          </CButton>
          <CButton
            color="light"
            @click="handleDeselectAllButton"
          >
            {{ $t("general.cancelAll") }}
          </CButton>
          <CInputCheckbox
            v-if="isFutures"
            :checked.sync="perpTrendsSingleGrouppedMessagesState"
            custom
            label="Группировать сообщения"
          />
          <CInputCheckbox
            v-else
            :checked.sync="trendsSingleGrouppedMessagesState"
            custom
            label="Группировать сообщения"
          />
        </div>
        <!--        :clearSearchOnBlur="() => false"-->
        <!--        :clearSearchOnSelect="true"-->
        <v-select
          v-if="trendsNotify?.tfTrend"
          ref="vselect"
          :filter="customFilter"
          :closeOnSelect="false"
          v-model="trendsNotify.tfTrend.coins"
          :options="coins"
          :getOptionKey="(option) => option.coin_id"
          :getOptionLabel="(option) => option.coin_name"
          class="v-select-trends flex-grow-1 flex-shrink-1"
          multiple
          placeholder=""
        >
          <!--          @close="dropdownIsOpen = false"-->
          <!--          @open="openDropDown"-->
          <!--          @search="search"-->
          <template v-slot:option="option">
            <div class="rounded-pill">
              <!--              :class="{ _disabled: disabledOptionKey && option[disabledOptionKey] }"-->
              <span class="badge">
                {{ option["coin_name"] }}
              </span>
            </div>
          </template>
          <template
            #selected-option-container="{
              option,
              deselect,
              multiple,
              disabled,
            }"
          >
            <span class="vs__selected badge">
              {{ option["coin_name"] }}

              <button
                v-if="multiple"
                :disabled="disabled"
                @click="deselect(option)"
                type="button"
                class="vs__deselect"
                :title="`${$t('general.deselect')} ${option['coin_name']}`"
                :aria-label="`${$t('general.deselect')} ${option['coin_name']}`"
              >
              </button>
            </span>
          </template>
          <template
            #list-header
            v-if="futuresList?.length"
          >
            <div class="list-header">
              <div
                class="v-select-option-list rounded-pill bg-base-card-bg"
                @click="setFutures"
              >
                <span>Futures {{ `Coins:${futuresList.length}` }}</span>
              </div>
            </div>
          </template>
        </v-select>
      </div>
    </div>
    <div class="divider mb-4 bg-dark"></div>
    <div class="top-notification mb-4">
      <div class="title"> Уведомления по топам </div>
      <div class="left">
        <span class="d-flex mb-3">
          {{ $t("trends.onlyPositive") }}
        </span>
        <CButtonGroup class="d-flex">
          <CButton
            v-tooltip="{
              content: getTooltipContent('telegram'),
            }"
            :disabled="loading"
            color="dark"
            class="cbutton"
            :variant="getSendType('up', 'telegram') >= 0 ? '' : 'outline'"
            :class="{ disabled: getSendTypeDisabled('telegram') }"
            @click="handleSendType('up', 'telegram')"
          >
            telegram
          </CButton>
          <CButton
            v-tooltip="{
              content: getTooltipContent('browser'),
            }"
            :disabled="loading"
            color="dark"
            class="cbutton"
            :variant="getSendType('up', 'browser') >= 0 ? '' : 'outline'"
            :class="{ disabled: getSendTypeDisabled('browser') }"
            @click="handleSendType('up', 'browser')"
          >
            browser
          </CButton>
        </CButtonGroup>
      </div>
      <div class="right">
        <label class="mb-3">
          {{ $t("trends.onlyNegative") }}
        </label>
        <CButtonGroup class="d-flex">
          <CButton
            v-tooltip="{
              content: getTooltipContent('telegram'),
            }"
            :disabled="loading"
            color="dark"
            class="cbutton"
            :variant="getSendType('down', 'telegram') >= 0 ? '' : 'outline'"
            :class="{ disabled: getSendTypeDisabled('telegram') }"
            @click="handleSendType('down', 'telegram')"
          >
            telegram
          </CButton>
          <CButton
            v-tooltip="{
              content: getTooltipContent('browser'),
            }"
            :disabled="loading"
            color="dark"
            class="cbutton"
            :variant="getSendType('down', 'browser') >= 0 ? '' : 'outline'"
            :class="{ disabled: getSendTypeDisabled('browser') }"
            @click="handleSendType('down', 'browser')"
          >
            browser
          </CButton>
        </CButtonGroup>
      </div>
    </div>
    <div class="top-notification mb-4">
      <div class="title"> Четко по страте </div>
      <div class="left">
        <span class="d-flex mb-3">
          {{ $t("trends.up") }}
        </span>
        <CButtonGroup class="d-flex mb-2">
          <CButton
            v-tooltip="{
              content: getTooltipContent('telegram'),
            }"
            :disabled="loading"
            color="dark"
            class="cbutton"
            :variant="getSendType('strataUp', 'telegram') >= 0 ? '' : 'outline'"
            :class="{ disabled: getSendTypeDisabled('telegram') }"
            @click="handleSendType('strataUp', 'telegram')"
          >
            telegram
          </CButton>
          <CButton
            v-tooltip="{
              content: getTooltipContent('browser'),
            }"
            :disabled="loading"
            color="dark"
            class="cbutton"
            :variant="getSendType('strataUp', 'browser') >= 0 ? '' : 'outline'"
            :class="{ disabled: getSendTypeDisabled('browser') }"
            @click="handleSendType('strataUp', 'browser')"
          >
            browser
          </CButton>
        </CButtonGroup>
      </div>
      <div class="right">
        <label class="mb-3">
          {{ $t("trends.down") }}
        </label>
        <CButtonGroup class="d-flex">
          <CButton
            v-tooltip="{
              content: getTooltipContent('telegram'),
            }"
            :disabled="loading"
            color="dark"
            class="cbutton"
            :variant="
              getSendType('strataDown', 'telegram') >= 0 ? '' : 'outline'
            "
            :class="{ disabled: getSendTypeDisabled('telegram') }"
            @click="handleSendType('strataDown', 'telegram')"
          >
            telegram
          </CButton>
          <CButton
            v-tooltip="{
              content: getTooltipContent('browser'),
            }"
            :disabled="loading"
            color="dark"
            class="cbutton"
            :variant="
              getSendType('strataDown', 'browser') >= 0 ? '' : 'outline'
            "
            :class="{ disabled: getSendTypeDisabled('browser') }"
            @click="handleSendType('strataDown', 'browser')"
          >
            browser
          </CButton>
        </CButtonGroup>
      </div>
    </div>
    <CInputCheckbox
      v-if="isFutures"
      :checked.sync="perpTrendsGrouppedMessagesState"
      custom
      label="Группировать сообщения"
    />
    <CInputCheckbox
      v-else
      :checked.sync="trendsGrouppedMessagesState"
      custom
      label="Группировать сообщения"
    />
    <template v-slot:footer>
      <div class="d-flex w-100 m-0">
        <CLoadingButtonCustom
          class="cbtn mr-3 flex-grow-1"
          color="success"
          :loading="loading || isLoading"
          :disabled="loading"
          @click.native="saveNotification"
        >
          <CIcon
            name="cil-save"
            class="my-0 mr-1"
          />
          {{ $t("general.save") }}
        </CLoadingButtonCustom>
        <CButton
          :disabled="loading"
          class="cbtn flex-grow-1"
          color="dark"
          @click="closeEvent"
        >
          {{ $t("general.cancel") }}
        </CButton>
      </div>
    </template>
  </CModal>
</template>
<script>
import axios from "axios"
import Badge from "@/components/common/Badge"
import VSelect from "vue-select"
import WebPushToggle from "@/components/notifications/WebPushToggle"
import ConfirmTg from "@/components/account/ConfirmTg"
import { mapGetters, mapState } from "vuex"
import { getAltSearchTickersValue } from "@/assets/js/searchDataTickers"
import { sortByAlphabet } from "@/assets/js/helpers"
import screenerSettings from "@/assets/js/screenerSettings"
import { createWatcherExhangeAndScreen } from "@/mixins/watch-exhange-and-screen"
import { SCREENER_PAGE_FUTURES } from "@/shared/constants"

export default {
  name: "TrendsNotificationModalv2",
  components: {
    Badge,
    VSelect,
    WebPushToggle,
    ConfirmTg,
  },
  mixins: [
    createWatcherExhangeAndScreen((context) => {
      context.getData()
    }),
  ],
  props: {
    modalNotificationOpen: {},
    value: {},
    notification: {},
    sendType: {
      type: Array,
      default: () => [],
    },
    loading: {},
    trendsNotify: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      futuresListCache: null,
      settings: screenerSettings,
      isLoading: false,
      collapseNotifySettings: false,
      localNotification: null,
      trendsGrouppedMessagesState: null,
      trendsSingleGrouppedMessagesState: null,
      perpTrendsSingleGrouppedMessagesState: null,
      perpTrendsGrouppedMessagesState: null,
      trendsBotUrl: process.env.VUE_APP_TRENDS_BOT_URL,
      group: false,
      coins: [],
      selectedCoin: null,
    }
  },
  mounted () {
    this.getData()
  },
  computed: {
    futuresList() {
      const data = sortByAlphabet(
        this.settings?.futuresList.map((item) =>
          this.coins.find((coin) => coin.coin_name === item.ticker),
        ),
        "coin_name",
      )
      if (data.length) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.futuresListCache = [...data]
      }
      return this.futuresListCache || data
    },
    hasEvents() {
      let data = false
      Object.values(this.trendsNotify).forEach((item) => {
        if (
          ["m30", "m60", "m120", "m240", "m720"].includes(item.event_name) &&
          item.id !== undefined
        ) {
          data = true
          return
        }
      })
      return data
    },
    ...mapGetters({
      telegramVerify: "user/telegramVerify",
    }),
    ...mapState({
      isSubscribed: (state) => state.browserNotify.isSubscribed,
      user: (state) => state.user.userData,
    }),
    isWebNotifyDisabled() {
      return !this.isSubscribed
    },
    isTgNotifyDisabled() {
      return !this.telegramVerify
    },
    trendsBotEnabled() {
      return this.user.trendsBotEnabled
    },
    isFutures() {
      return this.$store.getters.getLastScreen === SCREENER_PAGE_FUTURES
    }
  },
  watch: {
    notification: {
      deep: true,
      handler(val) {
        this.localNotification = val
      },
    },
    "trendsNotify.tfTrend.coins": {
      deep: true,
      handler (val) {
        Object.values(this.trendsNotify).forEach((item) => {
          if (
            ["m30", "m60", "m120", "m240", "m720"].includes(item.event_name) &&
            item.id !== undefined
          ) {
            item.coins = val
          }
        })
      },
    }
  },
  methods: {
    getData() {
      axios.get("/api/v1/trends_coins/user", {
          params: {
            is_futures: this.$store.getters.getLastScreen === SCREENER_PAGE_FUTURES ? 1 : 0,
          },
        })
        .then(({ data }) => {
          this.coins = data?.data.map((coin) => ({
            coin_id: coin.id,
            coin_name: coin.name,
            coin_name_alt: getAltSearchTickersValue(coin.name, true),
          })) ?? []
          this.trendsSingleGrouppedMessagesState = this.user?.settings?.notifications?.trendsSingleGrouppedMessages
          this.trendsGrouppedMessagesState = this.user?.settings?.notifications?.trendsGrouppedMessages
          this.perpTrendsSingleGrouppedMessagesState = this.user?.settings?.notifications?.perpTrendsSingleGrouppedMessages
          this.perpTrendsGrouppedMessagesState = this.user?.settings?.notifications?.perpTrendsGrouppedMessages
        })
    },
    setFutures() {
      this.trendsNotify.tfTrend.coins = [
        ...this.trendsNotify.tfTrend.coins,
        ...this.futuresList,
      ].filter((obj, index, arr) => {
        return arr.findIndex((o) => o.coin_id === obj.coin_id) === index
      })
    },
    customFilter(options, search) {
      return options.filter((option) => {
        return (
          option.coin_name.toLowerCase().includes(search.toLowerCase()) ||
          (option.coin_name_alt &&
            option.coin_name_alt.toLowerCase().includes(search.toLowerCase()))
        )
      })
    },
    handleSelectAllButton() {
      this.trendsNotify.tfTrend.coins = this.coins
    },
    handleDeselectAllButton() {
      this.trendsNotify.tfTrend.coins = []
    },
    closeEvent() {
      this.$emit("close")
    },
    saveNotification() {
      if (!this.isLoading) {
        this.isLoading = true
        axios
          .post("api/v1/users/settings", {
            namespace: "notifications",
            attach: {
              trendsGrouppedMessages: this.trendsGrouppedMessagesState,
              trendsSingleGrouppedMessages: this.trendsSingleGrouppedMessagesState,
              perpTrendsGrouppedMessages: this.perpTrendsGrouppedMessagesState,
              perpTrendsSingleGrouppedMessages: this.perpTrendsSingleGrouppedMessagesState,
            },
          })
          .then(() => {
            this.$emit("saveNotification")
            this.$store.dispatch("user/getCurrent")
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    getTooltipContent(type) {
      if (type === "telegram") {
        return !this.telegramVerify
          ? this.$t("lk.notifications.errorMessages.tg")
          : ""
      } else if (type === "browser") {
        return !this.isSubscribed
          ? this.$t("lk.notifications.errorMessages.web")
          : ""
      } else return ""
    },
    getSendTypeDisabled(type) {
      if (type === "telegram") {
        return !this.telegramVerify
      } else if (type === "browser") {
        return !this.isSubscribed
      } else return false
    },
    handleSendType(event, type) {
      if (event) {
        if (this.getSendTypeDisabled(type)) return
        const typeIndex = this.getSendType(event, type)
        if (typeIndex >= 0) {
          this.trendsNotify[event].send_type.splice(typeIndex, 1)
        } else {
          this.trendsNotify[event].send_type.push(type)
        }
        if (event === "tfTrend")
          Object.values(this.trendsNotify).forEach((item) => {
            if (
              ["m30", "m60", "m120", "m240", "m720"].includes(item.event_name) &&
              item.id !== undefined
            ) {
              item.send_type = this.trendsNotify.tfTrend.send_type
            }
          })
      }
      // this.$emit('update:notification', this.notification)
    },
    getSendType(event, type) {
      if (event) {
        if (Array.isArray(this.trendsNotify[event]?.send_type)) {
          return this.trendsNotify[event].send_type.indexOf(type)
        }
      }
      return -1
    },
    getTfEvent() {
      const tfs = ["m30", "m60", "m120", "m240", "m720"]
      for (let i = 0; i < tfs.length; i++) {
        if (this.trendsNotify?.[tfs[i]]) {
          return this.trendsNotify[tfs[i]]
        }
      }
    },
    handleTfClick(tf) {
      if (this.trendsNotify[tf].id === undefined) {
        this.trendsNotify[tf].id = 0
        this.trendsNotify[tf].send_type = this.trendsNotify?.tfTrend.send_type
        this.trendsNotify[tf].coins = this.trendsNotify?.tfTrend.coins
      } else {
        this.trendsNotify[tf].id = undefined
        this.trendsNotify[tf].send_type = []
        this.trendsNotify[tf].coins = []
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.trends-notification-modal {
  /deep/ .modal-dialog-scrollable .modal-body {
    overflow-y: auto !important;
  }
}

.divider {
  width: 100%;
  height: 1px;
}

.badge-group {
  display: flex;
  gap: 8px;
}

.v-select-trends {
  /deep/ .vs__dropdown-toggle {
    padding: 10px 6px;
    border: 1px solid #5a6570;
    border-radius: 4px;
  }

  /deep/ .vs__selected {
    padding: 3px 14px;
    background: var(--primary) !important;
    color: white;
    font-size: 14px;
    line-height: 17px;
    border-radius: 13px;
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    gap: 5px;

    .badge {
    }
  }

  /deep/ .vs__deselect {
    fill: white;
  }

  /deep/ .vs__search {
    color: var(--white);
  }

  /deep/ .vs__dropdown-menu {
    display: flex;
    flex-wrap: wrap;
    background: var(--v-select-bg);
    gap: 8px;
    padding: 20px 13px;
  }

  /deep/ .vs__dropdown-option--selected {
    background: var(--primary) !important;
  }

  /deep/ .vs__dropdown-option {
    padding: 3px 14px;
    background: transparent;
    border: 1px solid var(--primary);
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: white;
    border-radius: 13px;

    &--selected {
      .badge {
        color: white !important;
      }
    }

    .badge {
      font-size: 14px !important;
      line-height: 17px !important;
      color: var(--primary);
      padding: 0;
    }
  }

  /deep/ .vs__deselect {
    position: relative;

    &:before,
    &:after {
      content: "";
      width: 9px;
      height: 1px;
      background: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    }

    &:before {
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }
  }
}

.top-notification {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 0fr 1.5fr;
  gap: 16px 30px;
  grid-template-areas:
    "title title"
    "left right"
    "middle middle"
    "bottom bottom";

  .title {
    grid-area: title;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
  }
  .subTitle {
    font-size: 14px;
  }

  .left {
    grid-area: left;
  }

  .right {
    grid-area: right;
  }

  .bottom {
    grid-area: bottom;

    &__title {
      align-items: center;
      gap: 10px;
    }
  }
}
.list-header {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid var(--second-card-bg);
  border-bottom: 1px solid var(--second-card-bg);
}
</style>
