var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "w-100 tables-view position-relative",
      class: { "_no-select": _vm.selectShiftActive },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "move-to-top",
              rawName: "v-move-to-top",
              value: _vm.vMoveToTopOptions,
              expression: "vMoveToTopOptions",
            },
          ],
          staticClass: "table-wrap watch-selector-parent-scroll pr-2",
          class: { empty: !_vm.rows.length },
          attrs: { id: "emtyListScrollWrapper" },
        },
        [
          _c("q-virtual-scroll", {
            staticClass: "table",
            attrs: {
              type: "table",
              "virtual-scroll-item-size": _vm.virtualScrollItemSize,
              "virtual-scroll-sticky-size-start":
                _vm.virtualScrollStickySizeStart,
              "virtual-scroll-slice-size": _vm.virtualScrollSliceSize,
              items: _vm.rows,
              "scroll-target": ".table-wrap",
            },
            scopedSlots: _vm._u([
              {
                key: "before",
                fn: function () {
                  return [
                    _c("thead", { staticClass: "thead-sticky text-left" }, [
                      _c("tr", { staticClass: "header-top" }, [
                        _c(
                          "th",
                          {
                            staticClass: "switch-custom-wrap",
                            attrs: { colspan: _vm.isDesktop ? 3 : 2 },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "mb-0 col-form-label-sm",
                                        on: {
                                          click: function ($event) {
                                            _vm.isRsi = false
                                          },
                                        },
                                      },
                                      [_vm._v("Volume")]
                                    ),
                                    _c("CSwitch", {
                                      staticClass: "mx-3 switch-custom",
                                      attrs: {
                                        color: "primary",
                                        shape: "pill",
                                        checked: _vm.isRsi,
                                        id: "switch",
                                      },
                                      on: {
                                        "update:checked": function ($event) {
                                          _vm.isRsi = $event
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "mb-0 col-form-label-sm",
                                        on: {
                                          click: function ($event) {
                                            _vm.isRsi = true
                                          },
                                        },
                                      },
                                      [_vm._v("RSI")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "portal",
                                  {
                                    attrs: {
                                      disabled: _vm.isDesktop,
                                      to: "screener-header",
                                    },
                                  },
                                  [
                                    _c(
                                      "CButtonGroup",
                                      {
                                        class: [
                                          "d-flex btn-group-sm",
                                          !_vm.isDesktop ? "mr-2" : "",
                                          "sort-by-hint-wrap",
                                        ],
                                      },
                                      _vm._l(
                                        _vm.filterTabList,
                                        function (item) {
                                          return _c(
                                            "CButton",
                                            {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value: {
                                                    content: _vm.$t(
                                                      `screener.tablesView.sortByHint${item.label}`
                                                    ),
                                                  },
                                                  expression:
                                                    "{\n                      content: $t(`screener.tablesView.sortByHint${item.label}`),\n                    }",
                                                },
                                              ],
                                              key: item.id,
                                              class: ["cbutton"],
                                              attrs: {
                                                color: "dark",
                                                variant:
                                                  _vm.adapter.sortTypeBase ===
                                                  item.value
                                                    ? ""
                                                    : "outline",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.setFilterTab(item)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.label) + " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticClass: "px-0",
                            class: { "position-relative": !_vm.rows.length },
                            attrs: {
                              id: "emtyListScrollToElelemt",
                              colspan:
                                (_vm.isDesktop
                                  ? _vm.filteredTfList.length
                                  : 1) +
                                _vm.filteredDataList.length -
                                2,
                            },
                          },
                          [
                            _vm.isShowMA
                              ? [
                                  _c("color-filter-header"),
                                  _c(
                                    "transition",
                                    { attrs: { name: "fade", appear: "" } },
                                    [
                                      !_vm.rows.length
                                        ? _c("CIcon", {
                                            staticClass:
                                              "emptyListPlaceholder__pointerIcon",
                                            attrs: { name: "pointer" },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _c(
                          "th",
                          {
                            staticClass: "table-description text-right",
                            attrs: { colspan: _vm.isDesktop ? 1 : 2 },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.adapter.activeCol
                                    ? `${_vm.adapter.activeCol.toUpperCase()} ` +
                                        _vm.$t("screener.stats")
                                    : ""
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "tr",
                        { staticClass: "header-bottom" },
                        [
                          _c(
                            "th",
                            { staticClass: "base-cell _first" },
                            [
                              _vm.adapter.filterMA200Trend ||
                              _vm.activeRows.length > 1
                                ? _c("watch-selector", {
                                    attrs: {
                                      "showed-rows":
                                        _vm.activeRows.length > 1
                                          ? _vm.activeRows
                                          : _vm.rows,
                                      "popover-options": {
                                        popoverClass:
                                          "v-tooltip popover--header",
                                      },
                                      "is-futures": _vm.isActiveFutures,
                                      "is-table-header": "",
                                    },
                                    on: { getData: _vm.getData },
                                  })
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass: "pl-2 d-flex align-items-center",
                                },
                                [
                                  _vm.settings.selectedPairs.length &&
                                  _vm.activeRows.length > 1
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "vs__deselect",
                                          attrs: {
                                            type: "button",
                                            title: `${_vm.$t(
                                              "general.deselect"
                                            )}`,
                                            "aria-label": `${_vm.$t(
                                              "general.deselect"
                                            )}`,
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.removeSelectedPairs(
                                                _vm.activeRows
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                width: "10",
                                                height: "10",
                                              },
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  d: "M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("table-cell-header", {
                                    attrs: {
                                      "key-col": "coin.label",
                                      label: "Coin",
                                      "allow-select": false,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.isDesktop
                            ? _vm._l(_vm.filteredTfList, function (tf, i) {
                                return _c(
                                  "th",
                                  {
                                    key: i,
                                    staticClass: "base-cell _cursor",
                                    class: {
                                      _last:
                                        i === _vm.filteredTfList.length - 1,
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.adapter.activeCol = tf
                                      },
                                    },
                                  },
                                  [
                                    _c("table-cell-header", {
                                      attrs: {
                                        "key-col": tf,
                                        label: tf.toUpperCase(),
                                      },
                                    }),
                                  ],
                                  1
                                )
                              })
                            : _c(
                                "th",
                                { staticClass: "base-cell _last pl-0" },
                                [
                                  _c(
                                    "table-cell-header",
                                    {
                                      attrs: {
                                        "key-col": _vm.adapter.activeCol,
                                        "show-active": false,
                                      },
                                    },
                                    [
                                      _c("CSelect", {
                                        staticClass: "select-active-col mb-0",
                                        attrs: {
                                          value: _vm.adapter.activeCol,
                                          options: _vm.tfSelectOptions,
                                          placeholder: "Выберите",
                                          size: "sm",
                                        },
                                        on: {
                                          "update:value": function ($event) {
                                            return _vm.$set(
                                              _vm.adapter,
                                              "activeCol",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                          _c("th", { staticClass: "empty" }),
                          _vm._l(_vm.filteredDataList, function (item, i) {
                            return [
                              !_vm.dataTableHeaderBlocks[item].hideTh
                                ? _c(
                                    "th",
                                    {
                                      key: _vm.dataTableHeaderBlocks[item].id,
                                      class: [
                                        _vm.dataTableHeaderBlocks[
                                          item
                                        ].getThClass(),
                                        {
                                          _first: !i,
                                          _last:
                                            i ===
                                            _vm.filteredDataList.length - 1,
                                          "pr-0":
                                            i ===
                                            _vm.filteredDataList.length - 1,
                                        },
                                      ],
                                    },
                                    [
                                      _c("selected-cell-header", {
                                        attrs: {
                                          enableWhite:
                                            _vm.dataTableHeaderBlocks[item]
                                              ?.enableWhite,
                                          "key-col":
                                            _vm.dataTableHeaderBlocks[
                                              item
                                            ]?.keyCol(),
                                          "key-col-arrow":
                                            _vm.dataTableHeaderBlocks[
                                              item
                                            ]?.keyColArrow(),
                                          label:
                                            _vm.dataTableHeaderBlocks[
                                              item
                                            ]?.getLabel(),
                                          "hint-content":
                                            _vm.dataTableHeaderBlocks[
                                              item
                                            ]?.hintContent(),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function ({ item: row, index: rowIndex }) {
                  return [
                    _c(
                      "tr",
                      {
                        key: rowIndex,
                        class: row._classes,
                        on: {
                          click: function ($event) {
                            return _vm.selectRow(row, $event)
                          },
                        },
                      },
                      [
                        _c(
                          "td",
                          {
                            staticClass:
                              "position-relative align-middle label-custom-wrap",
                          },
                          [
                            _c("watch-selector", {
                              attrs: {
                                ticker: row.coin.label,
                                "is-futures": _vm.isActiveFutures,
                              },
                            }),
                            row.coin.label
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pl-2 d-flex align-items-center",
                                  },
                                  [
                                    _vm.settings.selectedPairs.length
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "vs__deselect",
                                            attrs: {
                                              type: "button",
                                              title: `${_vm.$t(
                                                "general.deselect"
                                              )} ${row.coin.label}`,
                                              "aria-label": `${_vm.$t(
                                                "general.deselect"
                                              )} ${row.coin.label}`,
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.removeSelectedPairs(
                                                  row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                attrs: {
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  width: "10",
                                                  height: "10",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    d: "M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    row.coin.icon
                                      ? _c("div", { staticClass: "mr-2" }, [
                                          _c("img", {
                                            staticStyle: {
                                              width: "1.37rem",
                                              "flex-shrink": "0",
                                            },
                                            attrs: { src: row.coin.icon },
                                          }),
                                        ])
                                      : _vm._e(),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "mb-0 font-weight-bold overflow-hidden label-custom",
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(row.coin.label) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._l(_vm.filteredTfList, function (tf, tfIndex) {
                          return [
                            _vm.adapter.activeCol === tf || _vm.isDesktop
                              ? _c(
                                  "td",
                                  {
                                    key: tfIndex,
                                    staticClass: "badge-wrap",
                                    style: {
                                      background: _vm.isRsi
                                        ? row[tf].colorRsi
                                        : "",
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.isRsi
                                              ? row[tf].rsi
                                              : row[tf].getVolumeStr
                                          ) +
                                          " "
                                      ),
                                      _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value: {
                                                content:
                                                  _vm.$t("screener.openGraph"),
                                                container: ".c-app",
                                              },
                                              expression:
                                                "{\n                    content: $t('screener.openGraph'),\n                    container: '.c-app',\n                  }",
                                            },
                                          ],
                                          staticClass: "cil-bar-chart-btn",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              _vm.graphClickHandler(
                                                row.coin.label,
                                                tfIndex,
                                                _vm.user.getAccess(1),
                                                row.coin
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("CIcon", {
                                            staticClass: "cursor-pointer",
                                            attrs: {
                                              width: "0.75rem",
                                              name: "cil-bar-chart",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "span-badge",
                                        style: {
                                          background: _vm.isRsi
                                            ? row[tf].colorRsiPct
                                            : row[tf].getColorVolumePct,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.isRsi
                                                ? row[tf].rsiPctStr
                                                : row[tf].getVolumePctStr
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        }),
                        _c("td", { staticClass: "empty" }),
                        _vm._l(_vm.filteredDataList, function (item) {
                          return [
                            !_vm.dataTableBlocks[item].hideTd
                              ? _c(
                                  "td",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value:
                                          _vm.dataTableBlocks[
                                            item
                                          ].getTooltipContent(),
                                        expression:
                                          "dataTableBlocks[item].getTooltipContent()",
                                      },
                                    ],
                                    key: _vm.dataTableBlocks[item].id,
                                    class:
                                      _vm.dataTableBlocks[item].getTdClass(row),
                                    style:
                                      _vm.dataTableBlocks[item].getTdStyle(row),
                                    on: {
                                      click: function ($event) {
                                        return _vm.dataTableBlocks[
                                          item
                                        ].tdClickHandler()
                                      },
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dataTableBlocks[
                                            item
                                          ].getData1Text(row)
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "span-badge",
                                        class:
                                          _vm.dataTableBlocks[
                                            item
                                          ].getBadgeClass(row),
                                        style:
                                          _vm.dataTableBlocks[
                                            item
                                          ].getBadgeStyle(row),
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.dataTableBlocks[
                                                item
                                              ].getBadgeText(row)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dataTableBlocks[
                                            item
                                          ].getData2Text(row)
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("transition", { attrs: { name: "fade", appear: "" } }, [
        !_vm.rows.length
          ? _c("div", { staticClass: "emptyListPlaceholder" }, [
              _c(
                "div",
                { staticClass: "emptyListPlaceholder__wrapper" },
                [
                  _c("CIcon", {
                    staticClass: "emptyListPlaceholder__emptyListIcon",
                    attrs: { name: "emptyList" },
                  }),
                  _c("span", { staticClass: "emptyListPlaceholder__title" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("screener.tablesView.emptyListTitle")) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c("CandlesGraphModal", {
        ref: "candles",
        attrs: {
          rows: _vm.rows,
          settings: _vm.settings,
          "client-id": _vm.clientId,
          "hide-notification-modal": _vm.isActiveFutures,
          "tables-intervals-for-use": _vm.filteredTfList,
          "is-futures": _vm.isActiveFutures,
        },
        on: {
          getData: _vm.getData,
          close: function ($event) {
            return _vm.$emit("closeGraph")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }