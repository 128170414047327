import store from '@/store'
export default function (PineJS) {
  return {
    name: 'FullAccumDelta',
    metainfo: {
      _metainfoVersion: 1,
      id: 'FullAccumDelta@tv-basicstudies-1',
      isTVScript: false,
      isTVScriptStub: false,
      is_hidden_study: false,
      transparency: 65,
      defaults: {
        styles: {
          full_accum_delta: {
            linestyle: 0,
            linewidth: 1,
            plottype: 5,
            trackPrice: false,
            visible: true,
            color: '#000080',
            transparency: 65
          }
        },
        precision: 0,
        palettes: {
          fullAccumDeltaPalette: {
            colors: {
              0: {color: '#eb4d5c', width: 1, style: 0},
              1: {color: '#53b987', width: 1, style: 0}
            }
          }
        },
        inputs: {
          symbol: "",
        }
      },
      plots           : [
        {id: 'full_accum_delta', type: 'line'},
        {id: 'fullAccumDeltaPalette', palette: 'fullAccumDeltaPalette', target: 'full_accum_delta', type: 'colorer'}
      ],
      styles          : {
        full_accum_delta: {
          title: 'Full Accum Delta',
          histogramBase: 0
        }
      },
      description: 'Full Accum Delta',
      shortDescription: 'Full Accum Delta',
      is_price_study: false,
      palettes: {
        fullAccumDeltaPalette: {
          colors: {
            0: {name: '< 0'},
            1: {name: '> 0'}
          }
        }
      },
      inputs: [
        {id: "symbol", name: "Other Symbol", defval: "", type: "symbol", optional: true, isHidden: false},
      ]
    },
    constructor: function () {
      this.init = function (e, t) {
        this._context = e
        if("" !== t(0)){
          this._context.new_sym(t(0), PineJS.Std.period(this._context))
          this._context.select_sym(1)
        }
      }
      this.main = function (e, t) {
        this._context = e
        this._input = t
        const adelta = ((store.state.deltaMap[this._context.symbol.ticker + '_' + this._context.symbol.period] || {})[this._context.symbol.time] || [0, 0])[1] || 0
        const isNegative = adelta < 0
        const value = this._input(0) && isNegative ? -adelta : adelta
        return [
          value,
          isNegative ? 0 : 1,
          PineJS.Std.sma(this._context.new_var(value), this._input(1), this._context)
        ]
      }
    }
  }
}
