var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "notifications d-flex flex-column" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("h1", { staticClass: "title mb-0" }, [
            _vm._v(_vm._s(_vm.$t("lk.notifications.title"))),
          ]),
          _vm.loading
            ? _c("CSpinner", {
                staticClass: "mx-2",
                attrs: { color: "primary", size: "sm" },
              })
            : _vm._e(),
          _c(
            "CButton",
            {
              staticClass: "cbtn px-4",
              attrs: { color: "primary" },
              on: { click: _vm.handleAddNotification },
            },
            [
              _c("CIcon", { attrs: { name: "cil-plus" } }),
              _vm._v(" " + _vm._s(_vm.$t("lk.notifications.addBtn")) + " "),
            ],
            1
          ),
        ],
        1
      ),
      _vm.tabList.length > 1
        ? _c(
            "div",
            { staticClass: "notifications__controls" },
            [
              _c("time-range-selector", {
                staticClass: "notifications__tabSelector",
                attrs: { hideTitle: true, options: _vm.tabList },
                model: {
                  value: _vm.selectedTab,
                  callback: function ($$v) {
                    _vm.selectedTab = $$v
                  },
                  expression: "selectedTab",
                },
              }),
              _vm.selectedTab === 1 && _vm.loading
                ? _c("CSpinner", {
                    staticClass: "mx-2",
                    attrs: { color: "primary", size: "sm" },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _vm.selectedTab === 1 && !_vm.loading
                    ? [
                        _vm.showPlayButton
                          ? _c(
                              "CButton",
                              {
                                staticClass: "shadow-none mr-2",
                                attrs: {
                                  color: _vm.isPaused ? "warning" : "primary",
                                  disabled: _vm.loading,
                                },
                                on: { click: _vm.togglePause },
                              },
                              [
                                _c("CIcon", {
                                  attrs: {
                                    name: _vm.isPaused
                                      ? "cil-media-play"
                                      : "cil-media-pause",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("TokensBlock", {
                          attrs: { tokens: _vm.tokens, inLkList: "" },
                        }),
                      ]
                    : _vm._e(),
                  [0, 3].includes(_vm.selectedTab)
                    ? _c(
                        "div",
                        [
                          _vm.selectedTab === 0
                            ? _c("span", { staticClass: "mr-3" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("lk.notifications.notifications")
                                    ) +
                                    " : " +
                                    _vm._s(_vm.listCount) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "CButton",
                            {
                              attrs: {
                                color: "danger",
                                disabled: _vm.loading || !_vm.listCount,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.showConfirmClearLogsModal = true
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.selectedTab === 0
                                      ? _vm.$t("general.deleteAll")
                                      : _vm.$t("general.clearLogs")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "confirmModal",
        {
          attrs: {
            show: _vm.showConfirmClearLogsModal,
            title: (_vm.selectedTab === 0
              ? _vm.$t("general.deleteAll")
              : _vm.$t("general.clearLogs")
            ).toString(),
          },
          on: {
            confirm: _vm.clearLogs,
            close: function ($event) {
              _vm.showConfirmClearLogsModal = false
            },
          },
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.$t("lk.notifications.deleteNotificationText")) +
              " "
          ),
        ]
      ),
      _c("hook-modal", {
        attrs: {
          currentHook: _vm.currentHook,
          show: _vm.showCreateHookModal,
          "is-futures":
            _vm.$store.getters.getLastScreen === _vm.SCREENER_PAGE_FUTURES,
        },
        on: { saveHook: _vm.saveHook, close: _vm.closeHookModal },
      }),
      _vm.showModal
        ? _c("notification-modal", {
            attrs: {
              notification: _vm.notification,
              showModal: _vm.showModal,
              isFuturesForRerender: _vm.isFuturesForRerender,
            },
            on: {
              "update:isFuturesForRerender": function ($event) {
                _vm.isFuturesForRerender = $event
              },
              "update:is-futures-for-rerender": function ($event) {
                _vm.isFuturesForRerender = $event
              },
              close: _vm.closeEvent,
              getNotifications: _vm.getNotifications,
            },
          })
        : _vm._e(),
      _c("hook-res-modal", {
        attrs: { show: _vm.showHookResModal, currentRes: _vm.currentRes },
        on: { close: _vm.closeHookResModal },
      }),
      _vm.selectedTab === 0
        ? _c("notifications-table", {
            ref: "table",
            attrs: { loading: _vm.loading },
            on: {
              "update:loading": function ($event) {
                _vm.loading = $event
              },
              setListCount: _vm.setListCount,
              rowClicked: _vm.openModal,
            },
          })
        : _vm._e(),
      _vm.selectedTab === 1
        ? _c("GroupNotifications", {
            ref: "groupNotifications",
            attrs: { loading: _vm.loading },
            on: {
              "update:loading": function ($event) {
                _vm.loading = $event
              },
              updatePaused: _vm.setIsPaused,
              setTokens: _vm.setTokens,
              setIsPaused: _vm.setIsPaused,
              recreateNotification: _vm.openModal,
            },
          })
        : _vm._e(),
      _vm.selectedTab === 2
        ? _c("AdminGroupNotifications", {
            ref: "groupNotificationsAdmin",
            attrs: { loading: _vm.loading },
            on: {
              "update:loading": function ($event) {
                _vm.loading = $event
              },
            },
          })
        : _vm._e(),
      _vm.selectedTab === 3
        ? _c("NotificationLogs", {
            ref: "notificationLogs",
            attrs: { loading: _vm.loading },
            on: {
              "update:loading": function ($event) {
                _vm.loading = $event
              },
              recreateNotification: _vm.openModal,
              setListCount: _vm.setListCount,
            },
          })
        : _vm._e(),
      _vm.selectedTab === 4
        ? _c("NotificationDrafts", {
            ref: "notificationDrafts",
            attrs: { loading: _vm.loading },
            on: {
              "update:loading": function ($event) {
                _vm.loading = $event
              },
              rowClicked: _vm.openModal,
            },
          })
        : _vm._e(),
      _vm.selectedTab === 5
        ? _c("hooks-table", {
            ref: "hooksTable",
            attrs: { loading: _vm.loading },
            on: {
              "update:loading": function ($event) {
                _vm.loading = $event
              },
              recreateNotification: _vm.openModal,
              setListCount: _vm.setListCount,
              rowClicked: _vm.openModal,
              toHowItWorks: _vm.toHowItWorks,
              createHook: _vm.createHook,
            },
          })
        : _vm._e(),
      _vm.selectedTab === 6
        ? _c("hooks-logs-table", {
            ref: "hooksLogsTable",
            attrs: { loading: _vm.loading },
            on: {
              "update:loading": function ($event) {
                _vm.loading = $event
              },
              setListCount: _vm.setListCount,
              rowClicked: _vm.openModal,
              showHookRes: _vm.showHookRes,
              toHowItWorks: _vm.toHowItWorks,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }