import store from "@/store";

export default function (PineJS) {
  return {
    name: 'Open Interest USDT',
    metainfo: {
      _metainfoVersion: 52,
      id: 'open_interest@tv-basicstudies-1',
      description: 'Open Interest USDT',
      shortDescription: 'Open Interest USDT',
      isTVScript: false,
      isTVScriptStub: false,
      is_hidden_study: false,
      is_price_study: false,
      defaults: {
        styles: {
          openInterest: {
            linestyle: 0,
            linewidth: 1,
            plottype: 5,
            trackPrice: !1,
            transparency: 65,
            visible: !0,
            color: "#5a6ea2"
          },
          openInterest_ma: {
            linestyle: 0,
            linewidth: 1,
            plottype: 0,
            trackPrice: !1,
            transparency: 0,
            visible: !1,
            color: "#2196F3"
          },
          smoothedMA: {
            linestyle: 0,
            linewidth: 1,
            plottype: 0,
            trackPrice: !1,
            transparency: 0,
            visible: !1,
            color: "#2196F3"
          }
        },
        inputs: {showMA: false, length: 20, smoothingLine: "SMA", smoothingLength: 9}
      },
      plots: [
        {id: 'openInterest', type: 'line'},
        {id: 'openInterest_ma', type: 'line'},
        {id: "smoothedMA", type: "line"}
      ],
      styles: {
        openInterest: {title: "Open Interest", histogramBase: 0},
        openInterest_ma: {title: "Open Interest MA", histogramBase: 0},
        smoothedMA: {title: "Smoothed MA", histogramBase: 0}
      },
      inputs: [
        {id: "length", name: "MA Length", defval: 20, type: "integer", min: 1, max: 2e3},
        {id: "smoothingLine", name: "Smoothing Line", defval: "SMA", type: "text", options: ["SMA", "EMA", "WMA"]},
        {id: "smoothingLength", name: "Smoothing Length", defval: 9, type: "integer", min: 1, max: 1e4}
      ],
      format: {type: "volume"},
    },
    constructor: function () {
      this.init = function (e) {
        this._context = e
        this._context.new_sym(this._context.symbol.ticker + '@OI', PineJS.Std.period(this._context))
        this._context.select_sym(1);
      }
      this.getValue = function () {
        return (
          (
            store.state.deltaMap[this._context.symbol.ticker + '_' + this._context.symbol.period] || {}
          )[this._context.symbol.time] || [0]
        )[0] || 0;
      }
      this.main = function (e, t) {
        this._context = e
        this._input = t
        const smoothingLine = this._input(1),
          smoothingLength = this._input(2),
          value = this.getValue()
        this._context.setMinimumAdditionalDepth(this._input(0) + smoothingLength)

        const sma = PineJS.Std.sma(this._context.new_var(value), this._input(0), this._context),
          vSma = this._context.new_var(sma)

        return [
          value, sma,
          'EMA' === smoothingLine ?
            PineJS.Std.ema(vSma, smoothingLength, this._context)
            : ('WMA' === smoothingLine ?
              PineJS.Std.wma(vSma, smoothingLength, this._context)
              : ('SMA' === smoothingLine ?
                PineJS.Std.sma(vSma, smoothingLength, this._context) : value))
        ]
      }
    }
  }
}
