import store from "@/store";

export default function (PineJS) {
  return {
    name: 'Trades',
    metainfo: {
      _metainfoVersion: 52,
      id: 'Trades@tv-basicstudies-1',
      description: 'Trades',
      shortDescription: 'Trades',
      isTVScript: false,
      isTVScriptStub: false,
      is_hidden_study: false,
      is_price_study: false,
      defaults: {
        styles: {
          trades: {
            linestyle: 0,
            linewidth: 1,
            plottype: 5,
            trackPrice: !1,
            transparency: 65,
            visible: !0,
            color: "#000080"
          },
          trades_ma: {
            linestyle: 0,
            linewidth: 1,
            plottype: 0,
            trackPrice: !1,
            transparency: 0,
            visible: !1,
            color: "#2196F3"
          },
          smoothedMA: {
            linestyle: 0,
            linewidth: 1,
            plottype: 0,
            trackPrice: !1,
            transparency: 0,
            visible: !1,
            color: "#2196F3"
          }
        },
        palettes: {tradesPalette: {colors: {0: {color: '#5a6ea2', width: 1, style: 0}, 1: {color: '#5a6ea2', width: 1, style: 0}}}},
        inputs: {showMA: false, length: 20, col_prev_close: false, symbol: "", smoothingLine: "SMA", smoothingLength: 9}
      },
      plots: [
        {id: 'trades', type: 'line'},
        {id: "tradesPalette", palette: "tradesPalette", target: "trades", type: "colorer"},
        {id: 'trades_ma', type: 'line'},
        {id: "smoothedMA", type: "line"}
      ],
      styles: {
        trades: {title: "Trades", histogramBase: 0},
        trades_ma: {title: "Trades MA", histogramBase: 0},
        smoothedMA: {title: "Smoothed MA", histogramBase: 0}
      },
      palettes: {tradesPalette: {colors: {0: {name: "Falling"}, 1: {name: "Growing"}}}},
      inputs: [
        {id: "symbol", name: "Other Symbol", defval: "", type: "symbol", optional: true, isHidden: false},
        {id: "showMA", name: "show MA", defval: false, type: "bool", isHidden: true},
        {id: "length", name: "MA Length", defval: 20, type: "integer", min: 1, max: 2e3},
        {defval: false, id: "col_prev_close", name: "Color based on previous close", type: "bool"},
        {id: "smoothingLine", name: "Smoothing Line", defval: "SMA", type: "text", options: ["SMA", "EMA", "WMA"]},
        {id: "smoothingLength", name: "Smoothing Length", defval: 9, type: "integer", min: 1, max: 1e4}
      ],
      format: {type: "volume"}
    },
    constructor: function () {
      this.init = function (e, t) {
        this._context = e
        if("" !== t(0)){
          this._context.new_sym(t(0), PineJS.Std.period(this._context))
        }
      }
      this.getTrades = function () {
        return (
          (
            store.state.deltaMap[((this._context.symbol.info && this._context.symbol.info.ticker) || this._context.symbol.ticker)
            + '_' + this._context.symbol.period] || {}
          )[this._context.symbol.time] || [0,0,0]
        )[2] || 0;
      }
      this.main = function (e, t) {
        this._context = e
        this._input = t
        let trades, open, close, time = this._context.new_var(this._context.symbol.time),
          smoothingLine = this._input(4),
          smoothingLength = this._input(5);
        this._context.setMinimumAdditionalDepth(this._input(2) + smoothingLength)
        if ("" !== this._input(0)) {
          this._context.select_sym(1);
          const time1 = this._context.new_var(this._context.symbol.time),
            trades1 = this._context.new_var(this.getTrades()),
            open1 = this._context.new_var(PineJS.Std.open(this._context)),
            close1 = this._context.new_var(PineJS.Std.close(this._context));
          trades = trades1.adopt(time1, time, 1)
          open = open1.adopt(time1, time, 1)
          close = close1.adopt(time1, time, 1)
          this._context.select_sym(0)
        } else {
          trades = this.getTrades()
          open = PineJS.Std.open(this._context)
          close = PineJS.Std.close(this._context)
        }

        var smoothSma, vTrades = this._context.new_var(trades), sma = PineJS.Std.sma(vTrades, this._input(2), this._context),
          vSma = this._context.new_var(sma), vClose = this._context.new_var(close)

        if('EMA' === smoothingLine) {
          smoothSma = PineJS.Std.ema(vSma, smoothingLength, this._context)
        }else if ('WMA' === smoothingLine){
          smoothSma = PineJS.Std.wma(vSma, smoothingLength, this._context)
        }else if('SMA' === smoothingLine){
          smoothSma = PineJS.Std.sma(vSma, smoothingLength, this._context)
        }
        return [
          trades,
          PineJS.Std.gt(vClose.get(1) && this._input(3) ? vClose.get(1) : open, close) ? 0 : 1,
          sma,
          smoothSma
        ]
      }
    }
  }
}
