<template>
  <div
    class="d-flex search"
    :class="{
      _show: showSearch,
      '_dropdown-is-open': dropdownIsOpen
    }"
  >
    <CButton
      :variant="searchBtnVariant"
      color="primary"
      class="shadow-none search__btn flex-shrink-0 align-items-center"
      @click="isMobile ? showSearch = !showSearch : focusSearch(true)"
    >
      <CIcon name="cil-search" class="my-0 mr-1"/>
      <span>{{ $t('screener.search') }}</span>
    </CButton>

    <v-select
      ref="vselect"
      v-model="selectValue"
      :options="coinList"
      :clearSearchOnBlur="() => false"
      :closeOnSelect="false"
      :clearSearchOnSelect="true"
      :getOptionLabel="option => option[optionName]"
      :filter="filterSearch"
      class="v-select-screener flex-grow-1 flex-shrink-1"
      multiple
      :placeholder="placeholder || $t('screener.searchPlaceholder')"
      @search="search"
      @open="openDropDown"
      @close="dropdownIsOpen = false"
    >
      <template v-slot:no-options="{ searching }">
        <template v-if="searching">
          {{ $t('general.emptySearch') }}
        </template>
        <em style="opacity: 0.5;" v-else>{{ $t('screener.typing') }}</em>
      </template>

      <template v-slot:option="option">
        <div
          v-if="showDropdown"
          class="v-select-option-list rounded-pill"
          :class="{ _disabled: disabledOptionKey && option[disabledOptionKey] }"
        >
          <span>{{ option[optionName] }}</span>
        </div>
        <span v-else></span>
      </template>

      <template
        #selected-option-container="{ option, deselect, multiple, disabled }"
      >
          <span
            class="vs__selected"
            :class="{ _disabled: disabledOptionKey && option[disabledOptionKey] }"
          >
            {{ option[optionName] }}

            <button
              v-if="multiple"
              :disabled="disabled"
              @click="deselect(option)"
              type="button"
              class="vs__deselect"
              :title="`${$t('general.deselect')} ${option[optionName]}`"
              :aria-label="`${$t('general.deselect')} ${option[optionName]}`"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                <path d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"/>
              </svg>
            </button>
          </span>
      </template>

      <template #list-header v-if="showListHeader">
        <div class="watch-lists">
          <div
            v-if="futuresList.length"
            class="v-select-option-list rounded-pill bg-base-card-bg"
            @click="setList(futuresList)"
          >
            <span>Futures {{ `Coins:${futuresList.length}` }}</span>
          </div>

          <template v-for="(item, i) in watchLists">
            <div
              v-if="item.name && item.pairs && item.pairs.length"
              class="v-select-option-list rounded-pill _watchlist"
              :key="i"
              :class=" item.color ? `bg-${item.color}` : ''"
              :style="{ background: item.customColor }"
              @click="setList(item.pairs, item.id)"
            >
              <span>
                {{ `${item.name} Coins:${item.pairs.length}` }}
              </span>
              <button
                type="button"
                class="vs__deselect"
                @click.stop="resetWatchList(i)"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                  <path d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"></path>
                </svg>
              </button>
            </div>
          </template>
        </div>
      </template>

      <template v-if="!isMobile" #open-indicator>
        <button
          v-if="selectValue && selectValue.length"
          class="v-select-option-reset rounded-pill"
          @click="selectValue = []"
        >
          {{ $t('screener.searchClear') }}
        </button>
      </template>
    </v-select>
    <transition appear name="fade">
      <button
        v-if="isMobile && showSearch"
        class="clear-on-mobile"
        @click="selectValue = []"
      >
        <CIcon
          name="cil-x"
        />
      </button>
    </transition>

    <transition appear name="fade">
      <div
        v-if="isMobile && showSearch"
        class="search__bg"
        @click="showSearch = false"
      />
    </transition>
  </div>
</template>

<script>
import VSelect from "vue-select";
import { mapGetters } from "vuex";
import { get, isNumber, isObject, isString } from "lodash";
import { convertCyrillicToLatin } from "@/assets/js/helpers";

export default {
  name: "VSelectWrap",
  components: {
    VSelect
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: Array,
      default: () => ([])
    },
    coinList: {
      type: Array,
      default: () => ([])
    },
    futuresList: {
      type: Array,
      default: () => ([])
    },
    watchLists: {
      type: Array,
      default: () => ([])
    },
    optionName: {
      type: String,
      default: 'label'
    },
    resetWatchList: {
      type: Function,
      default: () => {}
    },
    searchKeys: {
      type: Array,
      default: () => []
    },
    disabledOptionKey: {
      type: String,
      default: null
    },
    isFutures: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showDropdown: false,
      showSearch: false,
      dropdownIsOpen: false,
      selectedWatchListId: localStorage.getItem(this.localStorageKey)
    }
  },
  computed: {
    selectedWatchList(){
      return this.watchLists.find(item => item.id == this.selectedWatchListId)
    },
    ...mapGetters({
      isMobile: 'mediaQuery/isMobile',
      isTablet: 'mediaQuery/isTablet',
      isDesktop: 'mediaQuery/isDesktop',
      isLaptop: 'mediaQuery/isLaptop',
    }),
    searchBtnVariant() {
      return this.isDesktop || this.isLaptop ? '' : 'ghost'
    },
    showListHeader() {
      return this.futuresList.length || this.watchLists.length
    },
    selectValue: {
      get() {
        return this.value
      },
      set(val) {
        if(!val || !val.length) {
          this.selectedWatchListId = null
          localStorage.removeItem(this.localStorageKey)
        }
        this.$emit('input', val)
      }
    },
    localStorageKey() {
      return this.isFutures ? 'selectedWatchListBinanceFuturesId' : 'selectedWatchListId'
    },
  },
  watch: {
    showSearch(val) {
      this.focusSearch(val)
    },
    selectedWatchList: {
      deep: true,
      immediate: true,
      handler: function (newVal, oldVal) {
        if(newVal && oldVal){
          const removedItems = oldVal.pairs.filter(oldItem => !newVal.pairs.some(newItem => newItem.id === oldItem.id));
          if(removedItems.length){
            this.selectValue = this.selectValue.filter(item => !removedItems.some(removedItem => removedItem.id == item.id))
          }
        }
      }
    },
  },
  methods: {
    openDropDown() {
      this.$emit('dropdownIsOpened')
      this.dropdownIsOpen = true
    },
    search (value) {
      this.showDropdown = !!(value && value.length)
    },
    setList(arr, watchlistId) {
      localStorage.setItem(this.localStorageKey, watchlistId)
      this.selectedWatchListId = watchlistId
      this.selectValue = arr
    },
    filterSearch(options, search) {
      const searchKeys = this.searchKeys.length ? this.searchKeys : [this.optionName]

      return search.length
        ? options.filter(option => {
            let found = false

            if (isObject(option) && !Array.isArray(option)) {
              searchKeys.forEach(key => {
                if (!found) {
                  const val = get(option, key)
                  const formatedSearch = convertCyrillicToLatin(search.toLowerCase())

                  if (isString(val) || isNumber(val)) {
                    found = `${val}`.toLowerCase().includes(formatedSearch)
                  }
                }
              })
            }

            return found
        })
        : []
    },
    focusSearch(val) {
      const input = this.$refs.vselect?.$el?.querySelector('input')

      if (input) {
        if (val) {
          input.focus()
        } else {
          input.blur()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  width: calc(100% - .875rem * 2);
  right: .875rem;
  position: absolute;
  left: calc(100% - .875rem - 1.75rem);
  transition: all .5s;
  background: var(--bg);

  &::after {
    content: '';
    position: absolute;
    left: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    background: inherit;
  }

  @include media-breakpoint-up(md) {
    position: static;
    width: auto;

    .v-select-screener {
      width: 100% !important;
    }
  }

  @include media-breakpoint-down(md) {
    margin: 0 !important;
  }

  &._show {
    @include media-breakpoint-down(sm) {
      left: .875rem;

      .v-select-screener {
        width: calc(100% - 1.75rem);
        margin-right: .5rem;
      }

      .search__btn {
        background: var(--v-select-bg) !important;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  &._dropdown-is-open {
    .search__btn {
      @include media-breakpoint-down(md) {
        border-bottom-left-radius: 0;
      }
    }
  }

  &:not(._show) {
    @include media-breakpoint-down(sm) {
      .v-select-screener {
        /deep/ * {
          overflow: hidden;
        }
      }
    }
  }

  &__btn {
    border: 1px solid transparent !important;
    padding: 0 .3125rem;
    height: 1.75rem;
    color: white;
    //margin-left: auto;

    @include media-breakpoint-down(sm) {
      &:hover,
      &:focus {
        background: transparent;
      }
    }

    @include media-breakpoint-down(md) {
      span {
        display: none;
      }

      svg {
        margin: 0 !important;
      }
    }

    @include media-breakpoint-only(md) {
      &:not(:hover),
      &:not(:focus) {
        background: var(--v-select-bg);
      }
    }

    @include media-breakpoint-up(md) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      height: 2rem;
      padding: 0 0.44rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 0 0.75rem;
    }
  }
}

//.c-dark-theme {
//  .search {
//    background: red;
//  }
//}
.deselect-btn {
  border: none;
  padding: 0;
  background: none;
  color: #ffffff;
}

.watch-lists {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid var(--second-card-bg);
  border-bottom: 1px solid var(--second-card-bg);
}

.v-select-screener {
  max-height: 1.75rem;
  width: 0;
  flex-grow: 0 !important;
  transition: all .5s;

  /deep/ .vs__dropdown-menu {
    width: calc(100% + 1.75rem);
    left: -1.75rem;

    @include media-breakpoint-up(md) {
      width: calc(100% + 2rem);
      left: -2rem;
    }

    @include media-breakpoint-up(lg) {
      width: 100%;
      left: 0;
    }
  }

  /deep/ input {
    // min-width: 100%;

    // @include media-breakpoint-up(xl) {
      min-width: 30%;
    // }
  }

  @include media-breakpoint-up(md) {
    max-height: 2rem;
  }
}

.clear-on-mobile {
  width: 1.75rem;
  overflow: hidden;
  padding: 0;
  border: none;
  height: 1.75rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  opacity: 1;
  background: var(--tab-active-bg);
  color: var(--v-select-contrast);
  outline: none;
}

.search__bg {
  position: absolute;
  top: calc(100% + .375rem);
  left: -100%;
  width: 200vw;
  height: 100vh;
  height: 100dvh;
  background: var(--base-card-bg);
  z-index: 2;
  opacity: .85;
}

.fade {
  &-enter-active {
    transition: opacity .2s;
    transition-delay: .5s;
  }

  &-leave-active {
    transition: opacity .2s;
  }

  &-enter, &-leave-to {
    opacity: 0;
  }
}

.v-select-screener {
  .vs__selected,
  .v-select-option-list {
    &._watchlist {
      color: white;
    }

    &._disabled {
      opacity: .5;
    }
  }
}
</style>
