<template>
  <div>
    <CCard class="shadow-none" color="second-card-bg">
      <color-picker
        :open.sync="openColorPicker"
        :color.sync="customColor"
        @selectColor="hasChanges = true"
      >
        <button
          v-tooltip="{
          content: openColorPicker ? null : $t('lk.settings.changeColorWatchlist'),
        }"
          class="flag _btn"
          @click="openColorPicker = true"
        >
          <CIcon
            width="1rem"
            name="flag"
            :style="{ color: customColor }"
          />
        </button>
      </color-picker>

      <CCardBody>
        <CRow>
          <CCol col="6">
            <CInput
              v-model="name"
              :placeholder="$t('lk.account.formNameList')"
              @input="hasChanges = true"
              :disabled="isGuest"
            />
          </CCol>
          <CCol col="6">
            <CLoadingButtonCustom
              block
              color="primary"
              class="shadow-none"
              :loading="loadingFile"
              :disabled="loadingFile || isGuest"
              @click.native="$refs.fileInput.click()"
            >
              {{ $t('lk.account.download') }}
            </CLoadingButtonCustom>
            <input class="d-none" ref="fileInput" type="file" accept=".txt" @change="loadFile">
          </CCol>
        </CRow>
        <CRow>
          <CCol col="10">
            <CTextarea
              v-model="pairsString"
              :placeholder="$t('lk.account.formTickers')"
              rows="5"
              class="mb-0"
              :disabled="isGuest"
              @input="hasChanges = true"
            />
          </CCol>
          <CCol col="2" class="d-flex flex-column align-items-end">
            <CButton
              class="shadow-none mb-1"
              :class="{'cursor-not': disabledSaveBtn}"
              color="success"
              @click="saveList()"
              v-tooltip="{
              content: $t('general.saveChanges'),
            }"
              :disabled="isGuest || !hasChanges"
            >
              <CSpinner
                v-if="loadingList"
                color="primary"
                size="sm"
              />
              <CIcon v-else name="cil-save"/>
            </CButton>

            <CButton
              v-if="!isFutures"
              class="shadow-none mb-1"
              color="info"
              :class="{'cursor-not': disabledSaveBtn}"
              @click="showToLineRunnerModal"
              v-tooltip="{
              content: $t('general.saveChangesAndToLineRunner'),
            }"
              :disabled="disabledSaveBtn"
            >
              <CSpinner
                v-if="loadingList"
                color="primary"
                size="sm"
              />
              <CIcon v-else name="cilVerticalAlignTop"/>
            </CButton>

            <CButton
              class="shadow-none mb-1"
              :class="{'cursor-not': disabledDownloadBtn}"
              color="warning"
              @click="downloadList"
              v-tooltip="{
              content: $t('lk.settings.downloadWatchlist'),
            }"
              :disabled="disabledDownloadBtn"
            >
              <CIcon name="cil-cloud-download"/>
            </CButton>

            <CButton
              class="shadow-none"
              color="danger"
              :class="{'cursor-not': disabledResetBtn}"
              @click="resetList"
              :disabled="disabledResetBtn"
              v-tooltip="{ content: $t('lk.settings.resetWatchlist') }"
            >
              <CIcon name="cil-x"/>
            </CButton>
          </CCol>
        </CRow>
        <CButton
          v-if="isCustom"
          v-tooltip="{
          content: $t('lk.settings.deleteWatchlist'),
        }"
          class="shadow-none delete-btn"
          color="danger"
          @click="$emit('delete')"
        >
          <CIcon name="cil-x"/>
        </CButton>

      </CCardBody>
      <CModal
        :title="$t('lk.settings.uploadFile')"
        centered
        scrollable
        :show="showChangeNameModal"
        @update:show="changeNameHandler(false)"
      >
        <p>
          {{ $t('lk.settings.changeNameNote') }}
        </p>
        <template v-slot:footer>
          <div class="w-100 d-flex justify-content-between">
            <CButton
              color="primary"
              class="mr-2"
              @click="changeNameHandler(true)"
            >
              {{ $t('general.yes') }}
            </CButton>
            <CButton
              color="dark"
              @click="changeNameHandler(false)"
            >
              {{ $t('general.no') }}
            </CButton>
          </div>
        </template>
      </CModal>
    </CCard>
    <CModal
      :title="$t('lk.settings.editLineRunnerModal.title')"
      centered
      scrollable
      :show="toLineRunnerRequest"
      @update:show="toLineRunnerRequest = false"
    >
      <p>{{ $t('lk.settings.editLineRunnerModal.subtitle') }}</p>

      <template v-slot:footer>
        <div class="w-100">
          <CButton
            color="primary"
            class="mr-2"
            @click="toLineRunner"
          >
            {{ $t('general.yes') }}
          </CButton>
          <CButton
            color="dark"
            @click="toLineRunnerRequest = false"
          >
            {{ $t('general.no') }}
          </CButton>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import axios from "axios";
import PairItem from "@/assets/js/screenerSettings/PairItem.class";
import FileSaver from 'file-saver'
import ColorPicker from "@/components/common/ColorPicker.vue";
import screenerSettings from "@/assets/js/screenerSettings";
import {mapGetters} from "vuex";

export default {
  name: "WatchItem",
  components: {
    ColorPicker,
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    data: {
      type: Object,
      default: () => ({})
    },
    changeHandler: {
      type: Function,
      default: () => {
      }
    },
    saveHandler: {
      type: Function,
      default: () => {
      }
    },
    isCustom: {
      type: Boolean,
      required: true
    },
    isFutures: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      settings: screenerSettings,
      isLoading: false,
      loadingFile: false,
      loadingList: false,
      file: null,
      hasChanges: false,
      openColorPicker: false,
      uploadedFileText: null,
      showChangeNameModal: false,
      isTakeFileName: false,
      toLineRunnerRequest: false
    }
  },
  computed: {
    ...mapGetters({
      isGuest: 'user/isGuest'
    }),
    watchLists() {
      return this.settings.watchLists
    },
    name: {
      get() {
        return this.data.name
      },
      set(val) {
        this.changeHandler(this.index, {
          ...this.data,
          name: val
        })
      }
    },
    pairsString: {
      get() {
        return this.data.pairsString
      },
      set(val) {
        this.changeHandler(this.index, {
          ...this.data,
          pairsString: val
        })
      }
    },
    pairs: {
      get() {
        return this.data.pairs
      },
      set(val) {
        this.changeHandler(this.index, {
          ...this.data,
          pairs: val
        })
      }
    },
    customColor: {
      get() {
        return this.getColor(this.data.customColor || this.data.color || 'white')
      },
      set(val) {
        this.hasChanges = true
        this.changeHandler(this.index, {
          ...this.data,
          customColor: val
        })
      }
    },
    getGeneralParams() {
      return this.$store.state.lineRunner.generalParams
    },
    disabledSaveBtn() {
      return this.loadingList || !this.data.name || !this.data.pairsString || !this.hasChanges
    },
    disabledDownloadBtn() {
      return !this.data.name || !this.data.pairsString || this.hasChanges
    },
    disabledResetBtn() {
      return this.loadingList || !this.data.name || !this.data.pairsString
    },
  },
  beforeDestroy() {
    this.$toastr.clear()
  },
  methods: {
    getColor (color) {
      const map = {
        danger: '#e55353',
        success: '#4DBD74',
        'watch-list-primary': '#4A90E2',
        warning: '#f9b115'
      }
      return map[color] || color
    },
    async toLineRunner() {
      if (!this.isLoading) {
        this.toLineRunnerRequest = false
        this.isLoading = true
        this.saveList()
          .then(() => {
            let selectedCoin = []
            this.pairs.forEach(el => {
              selectedCoin.push({
                customParams: false,
                coin: {
                  id: el.id,
                  label: el.label
                },
                params: this.getGeneralParams
              })
            })
            axios.post('/api/v1/users/settings', {
              namespace: "lineRunner",
              attach: {
                generalParams: this.getGeneralParams,
                select: selectedCoin
              }
            }).then(async () => {
              this.$toastr.success(this.$t("general.lineRunnerAdded"));
              this.$store.commit('lineRunner/SET_RELOAD_LINE_RUNNER', true)
              await this.$nextTick()
              this.$store.commit('lineRunner/SET_RELOAD_LINE_RUNNER', false)
            }).finally(() => {
              this.isLoading = false
            })
          }).finally(() => {
          this.isLoading = false
        })
      }
    },

    changeNameHandler(value) {
      this.isTakeFileName = value
      this.hasChanges = true
      this.showChangeNameModal = false

      this.saveList(this.uploadedFileText)
        .finally(() => {
          this.loadingFile = false
          this.isTakeFileName = false
          this.uploadedFileText = null
        })
    },
    async filterBinanceTickers(text) {
      const getUrl = this.isFutures ? '/api/v1/users/settings/filter-binance-perp-tickers' : '/api/v1/users/settings/filter-binance-tickers'

      return axios.post(getUrl, {
        string: text
      })
        .then(res => {
          const {status, data} = res
          let pairs = data?.data?.pairs
          const pairsString = data?.data?.pairs_string

          if (status < 300 && data?.data && data?.status && pairsString && Array.isArray(pairs)) {
            pairs = data.data.pairs.map(a => new PairItem({
              ...a,
              label: a.symbol || a.label
            }))

            this.changeHandler(this.index, {
              ...this.data,
              name: this.isTakeFileName ? this.file.name.replace('_cryptovizor', '').replace('.txt', '').substring(0, 24) : this.data.name,
              pairsString: pairsString,
              pairs: pairs
            })

            return res
          } else {
            throw res
          }
        })
    },
    loadFile(e) {
      const file = e.target.files[0]
      if (!file) return
      this.file = file
      this.loadingFile = true
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onload = () => {
        this.uploadedFileText = reader.result
        this.showChangeNameModal = true
        this.$refs.fileInput.value = ''
      }
    },
    saveList(pairsString) {
      const isValid = this.watchLists.every(item => item.name.length <= 24)
      if (!isValid) {
        this.$toastr.warning(this.$t('lk.settings.watchListNameWarning'))
        return
      }
      return new Promise((resolve, reject) => {
        if (this.disabledSaveBtn && !pairsString) return reject("Saved not possible");

        this.loadingList = true
        this.$toastr.clear()
        this.filterBinanceTickers(pairsString || this.pairsString)
          .then((res) => {
            const error = res?.data?.error

            if (res?.data?.status) {
              this.saveHandler()
              this.hasChanges = false
              this.$toastr.success(this.$t('general.dataSaved'))
              this.showError(error)
              resolve("true");
            } else if (error) {
              this.showError(error)
              reject("err");
            } else {
              this.$toastr.error(this.$t('general.notValidate'))
            }
          })
          .catch((e) => {
            const error = e?.data?.error

            if (error) {
              this.showError(error)
            } else {
              this.$toastr.error(this.$t('general.notValidate'))
            }
            reject(error || 'Saved not possible');
          })
          .finally(() => {
            this.loadingList = false
          })

      });

    },
    downloadList() {
      if (this.disabledDownloadBtn) return

      if (!this.pairsString || !this.name) return
      const blob = new Blob([this.pairsString], {type: 'text/plain;charset=utf-8'})
      FileSaver.saveAs(blob, this.name + '_cryptovizor.txt')
      this.$toastr.success(this.$t('lk.settings.watchlistUploadedSuccessfully'))
    },
    resetList() {
      if (this.disabledResetBtn) return

      this.changeHandler(this.index, {
        ...this.data,
        pairsString: '',
        pairs: []
      })

      try {
        this.saveHandler()
        this.$toastr.success(this.$t('lk.settings.resetWatchlistSuccessfully', {name: this.data.name}))
      } catch (e) {
        console.log(e)
      }
    },
    showError(str) {
      if (!str) return

      this.$toastr.error(str, this.$t('general.notValidate'),
        {
          timeOut: 10000,
          extendedTimeOut: 10000,
          closeButton: true,
          tapToDismiss: false
        }
      )
    },
    showToLineRunnerModal() {
      if (this.disabledSaveBtn) return

      this.toLineRunnerRequest = true
    },
  }
}
</script>

<style scoped lang="scss">
.flag {
  position: absolute;
  top: 0.5rem;
  left: 0;

  &._btn {
    background: none;
    outline: none;
    border: none;
    padding: 0;
    line-height: 0;

    /deep/ {
      svg {
        stroke-width: 2px;
        stroke: transparent;
        transition: all ease-in-out .2s;
      }
    }

    &:focus {
      /deep/ {
        svg {
          stroke: var(--white);
        }
      }
    }
  }
}

.delete-btn {
  border-radius: 50%;
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: -.45rem;
  top: -.45rem;
}
</style>
