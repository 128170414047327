import moment from "moment";

const privateProperties = [
  '_classes',
  '_cellClasses'
]

function formatPrice (val) {
  if (typeof val === 'number') {
    return (Math.round(val * 100)) / 100
  } else {
    return null
  }
}

function smartRound(number, ifnull = '—', settings = [
  [1, null],
  [10, 2],
  [500, 1],
  [1000, 0]
]) {
  if (number !== 0 && !number) {
    return ifnull
  }

  const n = number < 0 ? -number : number
  let precession = null

  for(let i = 0; i < settings.length; i++) {
    const v = settings[i]
    precession = v[1]
    if (n < v[0]) {
      break
    }
  }

  if (precession === null) {
    const regArr = String(number).match(/0\.(0+)?/)
    if (regArr) {
      precession = regArr[0].length
    }
  }

  if (precession === null) {
    return number
  } else {
    let k = 1
    // eslint-disable-next-line
    for (const x of Array(precession).keys()) { // increment k to 100000..
      k *= 10
    }
    return Math.round(number * k) / k
  }
}

function numberShort(number, ifnull = '—', smartRoundSettings = [
  [1, null],
  [10, 2]
]) {
  if (number !== 0 && !number) {
    return ifnull
  }

  const isNegative = number < 0
  let n = isNegative ? -number : number

  let suffix = ''
  if (n < 500) {
    suffix = ''
  } else if (n < 900000) {
    // thousand
    n /= 1000
    suffix = ' K'
  } else if (n < 900000000) {
    // million
    n /= 1000000
    suffix = ' M'
  } else if (n < 900000000000) {
    // billion
    n /= 1000000000
    suffix = ' B'
  } else {
    // trillion
    n /= 1000000000000
    suffix = ' T'
  }

  return smartRound(isNegative ? -n : n, ifnull, smartRoundSettings) + suffix
}

export default class OverallVolume {
  constructor(init = {}, structure, symbol) {
    this.setData(init, structure, symbol)

    this._classes = ''
    this._cellClasses = {
      timeStr: 'text-nowrap',
      delta: 'text-nowrap',
      volume: 'text-nowrap'
    }
  }

  setData (init = [], structure, symbol) {
    if (!init) return

    this.open = init[structure.open]
    this.close = init[structure.close]
    // this.volume = numberShort(init[structure.volume])
    // this.delta = numberShort(init[structure.delta])

    this.timeTv = init[structure.time]
    this.time = this.timeTv * 1000
    this.timeStr = moment(this.time).utc().format('HH:mm | DD.MM')
    this.volume = numberShort(OverallVolume.volumeDelta[symbol]?.[this.time]?.volume)
    this.delta = numberShort(OverallVolume.volumeDelta[symbol]?.[this.time]?.delta)
    this.deltaNumber = OverallVolume.volumeDelta[symbol]?.[this.time]?.delta
  }

  get selectedParams() {
    const start = Math.min(this.open, this.close)
    const end = Math.max(this.open, this.close)
    if (start !== end) {
      return {
        start,
        end,
        time: this.timeTv
      }
    } else {
      return {
        start,
        end: start + 1,
        time: this.timeTv
      }
    }
  }

  setSelectedClass(val) {
    this._classes = val
  }

  highlight() {
    if (this.deltaNumber >= 0) {
      this.setSelectedClass('active-row-success')
    } else if (this.deltaNumber < 0) {
      this.setSelectedClass('active-row-danger')
    }
  }

  removeHighlight() {
    this.setSelectedClass('')

  }

  static formatAll(arr = [], structure, symbol) {
    if (!Array.isArray(arr) || !arr.length) return []

    const responseStructure = {
      open: structure.findIndex(a => a.plotTitle === 'open'),
      close: structure.findIndex(a => a.plotTitle === 'close'),
      time: structure.findIndex(a => a.type === 'time'),
      volume: structure.findIndex(a => a.plotTitle === 'Volume'),
      delta: structure.findIndex(a => a.plotTitle === 'Delta')
    }

    return arr.map(v => new OverallVolume(v, responseStructure, symbol))
  }

  static volumeDelta = {}
  static setVolumeDelta(symbol, arr) {
    if (!arr || !symbol) return

    if (!this.volumeDelta[symbol]) this.volumeDelta[symbol] = {}

    arr.forEach(v => {
      this.volumeDelta[symbol][v.time] = {
        delta: v.delta,
        volume: v.volume
      }
    })
  }
  static resetVolumeDelta () {
    this.volumeDelta = {}
  }
}
