<template>
  <div
    class="c-app"
    :class="{ 'c-dark-theme': $store.getters.darkMode }"
  >
    <promo-modal
      v-if="showPromoModal"
      :show="showPromoModal"
    />

    <portal-target name="login" />

    <CWrapper class="wrapper">
      <Header />

      <LineRunner v-if="$store.getters['user/getPermissions']('view_run_line') &&showLineRunner"/>

      <div class="c-body">
        <main class="c-main">
          <CContainer
            fluid
            class="container-custom d-flex flex-column"
          >
            <link-group />

            <CCard class="custom-card overflow-hidden shadow-none flex-grow-1 mb-0">
              <CCardBody class="ccard-body wrapper">
                <transition
                  name="fade"
                  mode="out-in"
                >
                  <router-view></router-view>
                </transition>

                <portal-target
                  v-if="isBrave"
                  name="brave-notify-modal"
                />
              </CCardBody>
            </CCard>
          </CContainer>
        </main>
      </div>
    </CWrapper>

    <portal-target name="tiny-loader" />
  </div>
</template>

<script>
import PromoModal from '@/components/PromoModal'
import LinkGroup from '@/pages/lk/LinkGroup'
import Header from '@/components/Header'
import LineRunner from '@/components/LineRunner'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Lk',
  components: {
    LinkGroup,
    Header,
    LineRunner,
    PromoModal,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      showPromoModal: 'promo/showPromoModal',
    }),
    ...mapState({
      isBrave: (state) => state.browserNotify.isBrave,
      showLineRunner: (state) => state.lineRunner.showLineRunner,
    }),
  },
  mounted() {
    if (window.localStorage.getItem('toTraining')) {
      this.$router.push({ name: 'lk-subscribe', query: { study: 1 } })
      window.localStorage.removeItem('toTraining')
    }
  },
}
</script>

<style lang="scss" scoped>
//@import "@/assets/scss/style";
.wrapper {
  height: 50vh;
  height: 50dvh;
}
.c-body,
.c-main,
.container-custom {
  overflow-y: hidden;
  height: 100%;
}

.c-main {
  padding-top: 0.75rem;
  padding-bottom: 0;

  @include media-breakpoint-up(xl) {
    padding-top: 2rem;
    padding-bottom: 3.25rem;
  }
}
.container-custom {
  padding: 0;

  @include media-breakpoint-up(xl) {
    padding: 0 2.5rem;
    max-width: 87.5rem;
  }
}
.custom-card {
  border-top-right-radius: 0;
  border-top-left-radius: 0;

  @include media-breakpoint-down(xl) {
    border-radius: 0;
  }
}
.ccard-body {
  overflow-x: hidden;
  overflow-y: auto;

  @include media-breakpoint-down(xs) {
    padding: 1.25rem;
  }
  @include media-breakpoint-up(sm) {
    padding: 1.75rem 2rem;
  }
  @include media-breakpoint-up(xl) {
    padding: 2.25rem 2.5rem;
  }
}
.wrapper {
  overflow-y: overlay;
}
</style>
