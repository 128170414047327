// Приватные методы:
function cutItem (state, sideOrIndex) {
  if (typeof side === 'number') {
    return state.splice(sideOrIndex, 1);
  }
  else {
    const cutMethod = sideOrIndex === 'start' ? 'shift' : 'pop';
    const cuttedItem = state[cutMethod]();
    return cuttedItem;
  }
}
function putItem (state, sideOrIndex, item) {
  if (typeof side === 'number') {
    return state.splice(sideOrIndex, 0, item);
  }
  else {
    const putMethod = sideOrIndex === 'start' ? 'unshift' : 'push';
    state[putMethod](item);
  }
}

// Модуль хранилища:
export default {
  namespaced: true,

  state: () => ({
    mainNavItems: [],
    mainSocialsItems: [],
    burgerNavItems: [],
    burgerSocialsItems: [],
  }),
  getters: {
    mainNavItems: state => state.mainNavItems,
    mainSocialsItems: state => state.mainSocialsItems,

    burgerNavItems: state => state.burgerNavItems,
    burgerSocialsItems: state => state.burgerSocialsItems,
  },
  actions: {
    initNavItems: ({ state }, payload) => {
      state.mainNavItems = payload.target === 'burger' ? [] : payload.items
      state.burgerNavItems = payload.target === 'burger' ? payload.items : []
    },
    initSocialsItems: ({ state }, payload) => {
      state.mainSocialsItems = payload.target === 'burger' ? [] : payload.items
      state.burgerSocialsItems = payload.target === 'burger' ? payload.items : []
    },

    setMainNavItems: ({ state }, payload) => state.mainNavItems = payload,
    setMainSocialsItems: ({ state }, payload) => state.mainSocialsItems = payload,

    setBurgerNavItems: ({ state }, payload) => state.burgerNavItems = payload,
    setBurgerSocialsItems: ({ state }, payload) => state.burgerSocialsItems = payload,

    cutMainNavItem: ({ state }, payload) => cutItem(state.mainNavItems, payload.sideOrIndex),
    putMainNavItem: ({ state }, payload) => payload.item && putItem(state.mainNavItems, payload.sideOrIndex, payload.item),
    cutMainSocialsItem: ({ state }, payload) => cutItem(state.mainSocialsItems, payload.sideOrIndex),
    putMainSocialsItem: ({ state }, payload) => payload.item && putItem(state.mainSocialsItems, payload.sideOrIndex, payload.item),

    cutBurgerNavItem: ({ state }, payload) => cutItem(state.burgerNavItems, payload.sideOrIndex),
    putBurgerNavItem: ({ state }, payload) => payload.item && putItem(state.burgerNavItems, payload.sideOrIndex, payload.item),
    cutBurgerSocialsItem: ({ state }, payload) => cutItem(state.burgerSocialsItems, payload.sideOrIndex),
    putBurgerSocialsItem: ({ state }, payload) => payload.item && putItem(state.burgerSocialsItems, payload.sideOrIndex, payload.item),
  },
};


