<template>
  <div>
    <DeleteGroupNotification
      :show="showDeleteNotifyModal"
      :currentNotification="currentNotification"
      v-if="showDeleteNotifyModal"
      @close="showDeleteNotifyModal = false"
      @deleteNotification="deleteNotification"
    />
      <NotificationInfoModal
      :show="showInfoModal"
      @editSignal="editSignal"
      @delete="showDeleteNotificationModal"
      @recreateNotification="recreateNotification"
      inLkList
      :currentNotification="currentNotification"
      v-if="showInfoModal"
      @close="showInfoModal = false"
      @update="getHooks"
    />
    <ConfirmModal
      :show="showConfirmModal"
      :info="confirmModalInfo"
      @close="closeConfirmModal"
      @confirm="confirmHandler"
    />
    <hook-modal
      :currentHook="new HookClass({...(currentHook?.hookObject || currentHook),editMode: true})"
      :show="showCreateHookModal"
      :isFutures="currentNotification?.isFutures ?? $store.getters.getLastScreen === SCREENER_PAGE_FUTURES"
      @saveHook="saveHook"
      @close="closeHookModal"
    />
    <div class="hooksTable__controls">
      <div class="hooksTable__filter">
        <span>
          {{ $t('general.show') }}:
        </span>
        <CSelect
          class="hooksTable__filter-select"
          :value.sync="filterActive"
          :placeholder="$t('screener.treemap.parameter')"
          :options="filterActiveOptions"
        />
      </div>
    </div>
    <CCard color="second-card-bg" class="ccard shadow-none overflow-hidden">
      <CCardBody class="ccard-body d-flex flex-column overflow-hidden">
          <div class="d-flex justify-content-between mb-4">
            <h5>
              {{ $t('lk.notifications.hooks') }}
            </h5>
            <div class="d-flex align-items-center">
              <CButton
                v-if="filterActive"
                class="cbtn px-4 d-flex mr-2"
                color="primary"
                @click="$emit('createHook')"
              >
                <CIcon name="cil-plus"/>
                {{ $t('lk.notifications.template') }}
              </CButton>
              <span
                @click="$emit('toHowItWorks')"
                class="popover-wrap d-inline-flex justify-content-center ml-2">
                  {{$t('general.howItWorks')}}?
              </span>
            </div>
          </div>
        <template v-if="tableData.length && !loading">
          <CDataTable
          sorter
          :fields="fields"
          :items="tableData"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @filtered-items-change="tableSortHandler"
          class="c-data-table h-100 overflow-hidden"
          >
            <template #name="{ item }">
              <td>
                <div class="d-flex">
                  <span class="mr-2">
                    {{ item?.hookObject?.name || item.name }}
                  </span>
                </div>
              </td>
            </template>
            <template #signalName="{ item }">
              <td>
                <span
                  class="table-link table-link-hover"
                  @click="showSignal(item)"
                >
                  {{ !item.is_single ? item.name : '-' }}
                </span>
              </td>
            </template>
            <template #url="{ item }">
              <td>
                <span>
                  {{item?.hookObject?.url || item.url}}
                </span>
              </td>
            </template>
            <template #json="{ item }">
              <td>
                <pre class="hook-json">{{item?.hookObject?.json || item.json}}</pre>
              </td>
            </template>
            <template #actions="{ item, index }">
              <td class="text-right text-nowrap">
                <div class="d-flex align-items-center">
                  <CSwitch
                    v-if="!filterActive"
                    color="primary"
                    shape="pill"
                    :checked.sync="item.hookObject.enable"
                    @update:checked="toggleHookEnable(item, index)"
                    class="switch-custom mr-3"
                    id="switch"
                  />
                  <CButton
                    class="mr-2"
                    color="success"
                    @click="editHook(item)"
                  >
                    <CIcon name="cil-pencil" />
                  </CButton>
                  <CLoadingButtonCustom
                    color="danger"
                    :loading="checkLoadingId(item.is_single ? item.marketplace_signal_id : item.id)"
                    :disabled="checkLoadingId(item.is_single ? item.marketplace_signal_id : item.id)"
                    @click.stop.native="deleteHook(item)"
                  >
                    <CIcon name="cil-trash" />
                  </CLoadingButtonCustom>
                </div>
              </td>
            </template>
          </CDataTable>
        </template>
        <p v-else class="m-0">{{ $t('lk.notifications.emptyActiveTable') }}</p>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import DeleteGroupNotification from '@/components/notifications/DeleteGroupNotification'
import NotificationInfoModal from '@/components/marketplace/NotificationInfoModal'
import HookModal from '@/components/notifications/HookModal.vue';
import ConfirmModal from '@/components/marketplace/ConfirmModal'
import Hook from "@/assets/js/notifications/Hook.class";
import axios from "axios";
import Notification from "@/assets/js/notifications/Notification.class";
import { SCREENER_PAGE_FUTURES } from '@/shared/constants';
export default {
  name: "HooksTable",
  props: {
    loading: {
      type: Boolean,
      required: true,
    }
  },
  components: {
    ConfirmModal,
    HookModal,
    DeleteGroupNotification,
    NotificationInfoModal
  },
  data() {
    return {
      SCREENER_PAGE_FUTURES,
      filterActiveOptions: [
        {
          label: this.$t('lk.notifications.hooks'),
          value: null,
        },
        {
          label: this.$t('lk.notifications.templates'),
          value: 'templates'
        }
      ],
      filterActive: null,
      showInfoModal: false,
      showDeleteNotifyModal: false,
      currentNotification: null,
      notifications: [],
      templates: [],
      showCreateHookModal: false,
      confirmAction: null,
      currentHook: null,
      currentHookIndex: null,
      showConfirmModal: false,
      confirmModalInfo: '',
      Notification,
      items: [],
      sortBy: '',
      sortDesc: false,
      loadingDel: [],
      HookClass: Hook,
    }
  },
  computed: {
    tableData(){
      return !this.filterActive ? this.items : this.templates
    },
    fields() {
      let data = [
        { key: 'name', label: this.$t('lk.training.name'), sorter: false },
        { key: 'signalName', label: this.$t('lk.notifications.groupNotificationName'), sorter: false },
        { key: 'url', label: 'URL', sorter: false },
        { key: 'json', label: 'JSON', sorter: false },
        { key: 'actions', label: '' }
      ]
      if(this.filterActive === 'templates'){
        data.splice(1, 1);
      }
      if(this.filterActive === 'templates'){
        data.unshift({ key: 'updated_at', label: this.$t('lk.notifications.create') ,sorter: false},)
      }
      return data
    }
  },
  created() {
    this.getHooks()
  },
  methods: {
    editSignal(signal){
      this.$emit('recreateNotification',signal)
      this.showInfoModal = false
    },
    recreateNotification(notification){
      this.$emit('recreateNotification',notification)
      this.showInfoModal = false
    },
    deleteNotification(){
      axios.delete(`api/v1/signals/marketplace/${this.currentNotification.id}`)
      .then(({status}) => {
        if (status < 300) {
          this.$toastr.success(this.$t('lk.notifications.deleteNotificationNote'))
          this.getNotifications()
        }
      })
      .finally(() => {
        this.showDeleteNotifyModal = false
      })
    },
    showDeleteNotificationModal(val){
      this.currentNotification = val
      this.showDeleteNotifyModal = true
    },
    showSignal(item){
      const signal = this.notifications.find((notify)=> {
        if(notify.is_single){
          return item.marketplace_signal_id === notify.marketplace_signal_id
        }
        return notify.id === item.id
      })
      const signalInstance = new Notification(signal)
      if(item.is_single){
        this.$emit('rowClicked', signalInstance)
      }else{
        this.currentNotification = signalInstance
        this.showInfoModal = true
      }
    },
    openHookModal(){
      this.showCreateHookModal = true
    },
    editHook(item){
      this.currentHook = item
      this.openHookModal()
    },
    async saveHook(item){
      const signalId = this.currentHook.is_single ? this.currentHook.marketplace_signal_id : this.currentHook.id
      this.loadingDel.push(signalId)
      try{
        const url = this.filterActive ? `api/v2/hook_templates/${item.id}` : `/api/v1/signals/with_hooks/${signalId}/${this.currentHook.hookObject.id}`
        const {status} =  await axios.post(url,{
          ...new Hook(item || this.currentHook.hookObject)
        })
        if (status < 300 && this.confirmAction !== 'enableHook') {
          this.getHooks()
        }
      }
      finally{
        this.loadingDel = this.loadingDel.filter(id => id !== signalId)
        this.closeHookModal()
      }
    },
    closeHookModal(){
      this.currentHook = null
      this.showCreateHookModal = false
    },
    toggleHookEnable(item, index){
      this.currentHook = item
      this.confirmModalInfo = `${item.hookObject.enable ? this.$t('lk.notifications.confirmEnableHook') : this.$t('lk.notifications.confirmDisableHook')} ${item.hookObject.name}`
      this.confirmAction = 'enableHook'
      this.currentHookIndex = index
      this.showConfirmModal = true
    },
    deleteHook(item, index){
      this.currentHook = item
      this.confirmModalInfo = `${this.$t('lk.notifications.confirmDeleteHook')} ${item?.hookObject?.name || item.name}`
      this.confirmAction = 'deleteHook'
      this.currentHookIndex = index
      this.showConfirmModal = true
    },
    async confirmHandler(){
      if(this.confirmAction === 'deleteHook'){
        await this.confirmDeleteHook()
      }
      if(this.confirmAction === 'enableHook'){
        await this.saveHook()
      }
      this.closeConfirmModal()
    },
    async confirmDeleteHook(){
      const signalId = this.currentHook.is_single ? this.currentHook.marketplace_signal_id : this.currentHook.id
      const url = this.filterActive ? `api/v2/hook_templates/${this.currentHook.id}`: `api/v1/signals/with_hooks/${signalId}/${this.currentHook.hookObject.id}`
      this.loadingDel.push(signalId)
      try{
        const {status} =  await axios.delete(url)
        if (status < 300) {
          if(!this.filterActive){
            this.items = this.items.filter(el => el.hookObject.id !== this.currentHook.hookObject.id)
          }else{
            this.templates = this.templates.filter(el => el.id !== this.currentHook.id)
          }
        }
      }
      finally{
        this.loadingDel = this.loadingDel.filter(id => id !== signalId)
      }
    },
    closeConfirmModal(){
      this.currentHookIndex = null
      this.currentHook = null
      this.showConfirmModal = false
      this.confirmAction = null
      this.getHooks()
    },
    tableSortHandler(sortedList){
      this.activeItems = sortedList
    },
    getHooks(){
      this.$emit('update:loading', true)
      try{
        this.getHooksList()
        this.getHooksTemplates()
      }finally{
        this.$emit('update:loading', false)
      }
    },
    getHooksList() {
      this.notifications = []
      this.items = []
      axios.get('api/v1/signals/with_hooks')
        .then(({ status, data }) => {
          if (status < 300) {
            this.notifications = data
            this.items = data.flatMap(item => item.hooks.map(hookElement => ({...item, hookObject: new Hook(hookElement)})));
          }
        })
    },
    getHooksTemplates() {
      this.templates = []
      axios.get('api/v2/hook_templates')
        .then(({ status, data }) => {
          if (status < 300) {
            this.templates = data.data.map(item => new Hook(item))
          }
        })
    },
    rowClicked(notify) {
      this.$emit('rowClicked', notify)
    },
    checkLoadingId(item) {
      return this.loadingDel.some(id => id === item)
    }
  }
}
</script>

<style lang="scss" scoped>
.ccard {

  @include media-breakpoint-down(xs) {
    .ccard-body {
      padding: 1rem 1.75rem;
    }
  }

  @include media-breakpoint-down(sm) {
    margin: 0 -1.25rem;
  }

  @include media-breakpoint-up(sm) {
    margin: 0 -2rem;

    .ccard-body {
      padding: 1.25rem 1.75rem;
    }
  }

  @include media-breakpoint-up(xl) {
    margin: 0;
  }
}

.c-data-table {
  /deep/ {
    tbody {
      tr {
        // cursor: pointer;
      }
    }

    thead {
      th {
        background: var(--second-card-bg);
        position: sticky;
        top: -1px;
        z-index: 1;
      }
    }

    .table-responsive {
      height: 100%;
    }
  }
}

.cil-bar-chart-btn {
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  display: inline-flex;
  margin-left: auto;
}

.table-link {
  color: var(--white);
}

.table-link-hover {
  color: var(--primary);
  cursor: pointer;

  &:hover {
    color: var(--white);
    text-decoration: underline;
  }
}
.hooksTable{
  &__controls{
    display: flex;
    column-gap: 10px;
    flex-wrap: wrap;
  }
  &__filter {
    display: flex;
    width: fit-content;
    align-items: center;
    margin-bottom: 20px;

    & span {
      margin-right: 8px;
    }
  }

  &__filter-select {
    margin-bottom: 0;
  }
}
.popover-wrap{
  padding: 5px 10px;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  flex-shrink: 0;
  background-color: var(--base-card-bg);
  border-radius: 6px;
}
.hook-json{
  display: -webkit-box;
  display: -moz-box;
  max-height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -moz-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
}
</style>
