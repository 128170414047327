import { sortBy, debounce, cloneDeep} from "lodash";
import { PairMap } from "./PairMap.class";

import store from "@/store";
import PairItem from "@/assets/js/screenerSettings/PairItem.class";
import WatchListsItem from "@/assets/js/screenerSettings/WatchListsItem.class";
import { SCREENER_PAGE_FUTURES } from "@/shared/constants";

const defaultGraphsPeriods = ['15m', '30m', '45m', '1h', '2h', '3h', '4h', '6h', '12h', '1d']
const defaultTablesIntervals = ['5m', '15m', '30m', '1h', '2h', '4h', '12h', '1d']
const defaultTrendsPeriods = ['15m', '30m', '45m', '1h', '2h', '3h', '4h', '6h', '12h', '1d']

export default {
  pairs: [],
  tPairs: [],
  futuresList: [],


  pairsMap: new PairMap([]),
  tPairsMap: new PairMap([]),
  futuresListMap: new PairMap([]),

  tablesIntervals: defaultTablesIntervals,
  graphsPeriods: defaultGraphsPeriods,
  graphsActivePeriod: '',
  trendsPeriods: defaultTrendsPeriods,
  _watchLists: [],
  _watchListsBinancePerp: [],
  saveWatchProcess: false,

  _selectedPairs: [],
  _selectedPairsPerp: [],

  setTablesIntervals (val) {
    if (val && Array.isArray(val) && val.length) {
      this.tablesIntervals = val
    } else {
      this.tablesIntervals = defaultTablesIntervals
    }
  },
  setTrendsPeriods (val) {
    if (val && Array.isArray(val) && val.length) {
      this.trendsPeriods = val
    } else {
      this.trendsPeriods = defaultTrendsPeriods
    }
  },
  setGraphsPeriods (val) {
    if (val && Array.isArray(val) && val.length) {
      this.graphsPeriods = val
    } else {
      this.graphsPeriods = defaultGraphsPeriods
    }
  },
  setGraphsActivePeriod (val) {
    this.graphsActivePeriod = val
    store.dispatch("setKey", {
      namespace: 'graphsView',
      key: 'activePeriod',
      value: val
    })
  },

  setPairs (val) {
    if (val && Array.isArray(val)) {
      this.pairs = val.map(a => new PairItem({
        ...a,
        label: a.symbol || a.label
      }))

      this.pairsMap = new PairMap(this.pairs)

      this.setFuturesList()
    }
  },
  setTPairs (val) {
    if (val && Array.isArray(val)) {
      this.tPairs = val.map(a => new PairItem({
        ...a,
        label: a.symbol || a.label
      }))

      this.tPairsMap = new PairMap(this.tPairs)
    }
  },
  get selectedPairs () {
    const isFutures = store.getters.getLastScreen === SCREENER_PAGE_FUTURES;
    return sortBy(isFutures ? this._selectedPairsPerp : this._selectedPairs, 'label')
  },
  set selectedPairs (val) {
    const isFutures = store.getters.getLastScreen === SCREENER_PAGE_FUTURES;
    if (isFutures) this._selectedPairsPerp = val
    else this._selectedPairs = val

    store.dispatch("setKey", {
      namespace: 'screener',
      key: isFutures ? 'selectedPairsPerp' : 'selectedPairs',
      value: val
    })
  },
  removeSelectedPairs (val) {
    const isFutures = store.getters.getLastScreen === SCREENER_PAGE_FUTURES;
    const selectedPairs = isFutures ? this._selectedPairsPerp : this._selectedPairs

    const remove = (item) => {
      const index = selectedPairs.findIndex(el => el.id === item.id || el.ticker === item.ticker)

      if (index > -1) {
        selectedPairs.splice(index, 1)
      }
    }

    if (Array.isArray(val)) {
      val.forEach(item => {
        if (item?.id) {
          remove(item)
        }
      })
    } else if (val?.id) {
      remove(val)
    }

    store.dispatch("setKey", {
      namespace: 'screener',
      key: isFutures ? 'selectedPairsPerp' : 'selectedPairs',
      value: selectedPairs
    })
  },
  get selectedPairsStr () {
    const isFutures = store.getters.getLastScreen === SCREENER_PAGE_FUTURES;
    const selectedPairs = isFutures ? this._selectedPairsPerp : this._selectedPairs
    return selectedPairs.map(a => a.label)
  },

  setFuturesList () {
    if (!Array.isArray(this.pairs)) {
      this.futuresList = []
      this.futuresListMap = new PairMap([])
    } else {
      this.futuresList = this.pairs.filter(a => a.is_futures)
      this.futuresListMap = new PairMap(this.futuresList)
    }
  },

  get watchLists () {
    return cloneDeep(this._watchLists)
  },
  get watchListsBinancePerp () {
    return cloneDeep(this._watchListsBinancePerp)
  },
  set watchLists (val) {
    this._watchLists = val
  },
  set watchListsBinancePerp (val) {
    this._watchListsBinancePerp = val
  },
  addWatchList (val) {
    this._watchLists.push(new WatchListsItem(val))
    this.saveWatchLists()
  },
  addWatchListBinancePerp (val) {
    this._watchListsBinancePerp.push(new WatchListsItem(val))
    this.saveWatchListsBinancePerp()
  },
  deleteWatchList (index) {
    this._watchLists.splice(index, 1)
    this.saveWatchLists()
  },
  deleteWatchListBinancePerp (index) {
    this._watchListsBinancePerp.splice(index, 1)
    this.saveWatchListsBinancePerp()
  },
  setWatchListsItem (index, val) {
    if (!val) return
    const arr = [].concat(this._watchLists)
    arr[index] = val
    this._watchLists = arr
  },
  setWatchListsBinancePerpItem (index, val) {
    if (!val) return
    const arr = [].concat(this._watchListsBinancePerp)
    arr[index] = val
    this._watchListsBinancePerp = arr
  },
  saveWatchLists () {
    this.promiseSaveWatch()
  },
  saveWatchListsBinancePerp () {
    this.promiseSaveWatchBinancePerp()
  },
  debounceSaveWatch: debounce(function () {
    return this.promiseSaveWatch()
  }, 1000),
  debounceSaveWatchBinancePerp: debounce(function () {
    return this.promiseSaveWatchBinancePerp()
  }, 1000),
  promiseSaveWatch () {
    return store.dispatch("user/setWatchLists", this.watchLists)
  },
  promiseSaveWatchBinancePerp () {
    return store.dispatch("user/setWatchListsBinancePerp", this.watchListsBinancePerp)
  },
  promiseSaveWatchImmediately () {
    return store.dispatch("user/setWatchListsImmediately", this.watchLists)
  },
  promiseSaveWatchBinancePerpImmediately () {
    return store.dispatch("user/setWatchListsBinancePerpImmediately", this.watchListsBinancePerp)
  },
}
