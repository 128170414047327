<template>
  <td>
    <v-popover>
      <div
        class="ticker-name"
        @click.self="setShow"
      >
        <slot></slot>
      </div>

      <template slot="popover">
        <template v-if="loadingRequest">
          <div style="text-align: left; padding: 100px" class="tooltip-inner">
            <div v-html="loadingContent"></div>
          </div>
        </template>
        <template v-else>
          <div style="text-align: left" class="tooltip-inner">
            <div v-html="hintContent" class="ticker-hint"></div>
            <CButton class="cp_btn" color="primary" @click="copy()"
            >
              <CIcon name="cilCopy"
              />
            </CButton>
          </div>
        </template>
      </template>
    </v-popover>
  </td>
</template>

<script>
import axios from "axios";
import {mapGetters, mapState} from 'vuex';
import TickerHistory from "@/assets/js/trendsDataClasses/TickerHistory.class";
import { SCREENER_PAGE_FUTURES } from "@/shared/constants";

export default {
  name: "TableCellTickerContent",
  props: {
    isFutures: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loadingRequest: false,
      show: false,
      hintContent: null,
      loadingContent: `<div role="status" aria-hidden="false" aria-label="Loading" class="spinner spinner-border spinner-border-sm text-primary"></div>`,
      copyContent: null,
    };
  },
  computed: {
    ...mapGetters("trendsPage", {
      percentsKeys: "percentsKeys",
    }),
    ...mapState('trendsPage', {
      time: 'time'
    }),
  },
  watch: {
    show(val) {
      if (val) {
        setTimeout(() => {
          document.addEventListener("click", this.closeTooltip);
          document.addEventListener("scroll", this.closeTooltip, {
            once: true,
          });

          const scrollEl =
            this.$parent?.$el?.querySelectorAll(".overflow-auto") || [];

          if (scrollEl.length) {
            scrollEl.forEach((el) => {
              el.addEventListener("scroll", this.closeTooltip, {once: true});
            });
          }
        }, 0);
      } else {
        this.removeListeners();
      }
    },
  },
  beforeDestroy() {
    this.removeListeners();
  },
  methods: {
    copy() {
      let area = document.createElement("textarea");
      document.body.appendChild(area);
      area.value = this.copyContent
      area.select();
      document.execCommand("copy");
      document.body.removeChild(area);
      this.$toastr.success("Успешно скопированно!");
    },
    setShow() {
      if(!this.$store.getters['user/getPermissions']('view_trends')){
        return
      }
      this.show = !this.show;

      if (this.show) {
        this.getTickerHistory();
      }
    },
    closeTooltip($event) {
      const clickPath =
        $event.path || ($event.composedPath && $event.composedPath());

      if (!clickPath) {
        return;
      }

      const tickerHistoryTooltips = [
        ...document.querySelectorAll(".v-tooltip-ticker-history"),
      ];

      if (!clickPath.some((el) => tickerHistoryTooltips.includes(el))) {
        this.show = false;

        this.removeListeners();
      }
    },
    removeListeners() {
      document.removeEventListener("click", this.closeTooltip);
      document.removeEventListener("scroll", this.closeTooltip);

      const scrollEl =
        this.$parent?.$el?.querySelectorAll(".overflow-auto") || [];

      if (scrollEl.length) {
        scrollEl.forEach((el) => {
          el.removeEventListener("scroll", this.closeTooltip);
        });
      }
    },

    /**
     * Формирование параметров запроса.
     */
    getfetchParams() {
      const params = {
        id: this.item.id || null,
        symbol: this.item.symbol || (this.$store.getters.getLastScreen === SCREENER_PAGE_FUTURES ? this.item.ticker : null),
        ts: this.item.dateTimeUnix
          ? this.$store.getters.getLastScreen === SCREENER_PAGE_FUTURES ? this.item.dateTimeUnix / 1000 : null
          : null
      }

      const url = this.isFutures ?? this.$store.getters.getLastScreen === SCREENER_PAGE_FUTURES
        ? `/api/v3/${this.$store.getters.getLastExchange}_perp/trends/trend-history`
        : '/api/v1/tbot/trends/ticker-history'

      return { url, params }
    },
    getTickerHistory() {
      const fetchParams = this.getfetchParams()
      axios
        .get(fetchParams.url, {
          params: fetchParams.params,
        })
        .then(({status, data}) => {
          const tickerHistoryTrend = new TickerHistory(data?.data);

          if (status < 300 && tickerHistoryTrend && data?.status) {
            this.hintContent = data?.text || ''
            this.copyContent = data?.copyText || ''
          } else {
            this.closeTooltip();
          }
        })
        .catch(() => {
          this.closeTooltip();
        })
        .finally(() => {
          this.loadingRequest = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.ticker-hint {
  ::v-deep td {
    white-space: nowrap;
  }
}
.ticker-name {
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.cp_btn {
  position: absolute;
  right: 0;
  top: 0;
  margin: 4px;
}
</style>
