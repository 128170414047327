<template>
  <CRow
    class="custom-row no-gutters"
    :class="{ '_no-select': selectShiftActive }"
  >
    <CCol
      col="12"
      class="custom-col"
    >
      <CCard
        class="overflow-auto mb-0 h-100"
        color="base-card-bg"
      >
        <CCardBody class="overflow-auto">
          <div
            class="overflow-auto h-100 table-wrap"
            v-if="items.length"
          >
            <q-virtual-scroll
              type="table"
              ref="virtualListRef"
              :virtual-scroll-item-size="virtualScrollItemSize"
              :items="items"
              scroll-target=".table-wrap"
              class="table"
            >
              <template v-slot:before>
                <thead>
                  <tr>
                    <th class="text-center">
                      <button
                        class="reset"
                        @click="resetSorting"
                      >
                        <CIcon
                          width="11px"
                          height="11px"
                          name="cil-x"
                        />
                      </button>
                      <table-cell-ticker
                        label="Ticker"
                        key-col="ticker"
                      >
                        <watch-selector
                          :showed-rows="activeRows.length ? activeRows : items"
                          :isFutures="
                            $store.getters.getLastScreen === 'futures'
                          "
                          :popover-options="{
                            popoverClass: 'v-tooltip popover--header',
                          }"
                        />
                        <button
                          v-if="filterIsActive && activeRows.length > 1"
                          type="button"
                          class="vs__deselect"
                          :title="`${$t('general.deselect')}`"
                          :aria-label="`${$t('general.deselect')}`"
                          @click.stop="
                            removeSelectedPairs(activeRows, activeRows)
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                          >
                            <path
                              d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"
                            />
                          </svg>
                        </button>
                      </table-cell-ticker>
                    </th>
                    <th>
                      <table-cell-ticker
                        label="Last Update"
                        key-col="dateTimeUnix"
                        :icon="activeRows.length > 1 ? 'bell' : ''"
                        @iconClick="
                          handleClickNotificationBell(reactiveActiveRows)
                        "
                        no-flag
                      >
                      </table-cell-ticker>
                    </th>
                    <th
                      v-for="(key, i) in percentsKeys"
                      :key="i"
                    >
                      <table-cell-header
                        :key="key"
                        :key-col="key"
                        :label="reassignLabel[key]"
                        :percents="percents[key]"
                      />
                    </th>
                  </tr>
                </thead>
              </template>

              <template v-slot:default="{ item, index: rowIndex }">
                <tr
                  :key="rowIndex"
                  :class="item._classes"
                  :data-is-mobile="isMobile"
                  ref="row-table"
                  @animationstart="startTable(rowIndex)"
                  @animationend="animationEnd(item)"
                  @click="selectRow(item, $event, items, activeRows)"
                >
                  <table-cell-ticker-content
                    :item="item"
                    class="position-relative pl-4 tickerCell"
                  >
                    <watch-selector
                      :ticker="item.ticker"
                      :isFutures="$store.getters.getLastScreen === 'futures'"
                      @removePair="removeSelectedPairs(item, activeRows)"
                    />
                    <button
                      v-if="filterIsActive"
                      type="button"
                      class="vs__deselect"
                      :title="`${$t('general.deselect')}`"
                      :aria-label="`${$t('general.deselect')}`"
                      @click.stop="removeSelectedPairs(item, activeRows, true)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                      >
                        <path
                          d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"
                        />
                      </svg>
                    </button>
                    {{ item.ticker }}
                    <button
                      v-tooltip="{
                        content: $t('screener.openGraph'),
                        container: '.c-app',
                      }"
                      type="button"
                      class="cil-bar-chart-btn"
                      @click.stop="
                        $refs.candles.openCandlesGraph(
                          `${item.ticker}USDT`.replace(
                            /(\w*)(\.P)?(\w*)/,
                            '$1$3$2',
                          ),
                          2,
                          null,
                          item.dateTimeUnix,
                        )
                      "
                    >
                      <div>
                        <CIcon
                          width="0.75rem"
                          class="cursor-pointer"
                          name="cil-bar-chart"
                        />
                      </div>
                    </button>
                  </table-cell-ticker-content>
                  <td>
                    <div class="update-time">
                      <span class="">
                        {{ item.dateTimeStrDate }}
                      </span>
                      <span class="update-time__time">
                        {{ item.dateTimeStrTime }}
                      </span>
                      <div class="bell">
                        <CIcon
                          v-if="showBellButtons"
                          name="bell"
                          class="cil-bell-icon ml-1 flex-shrink-0"
                          :class="item.notify ? '_active' : '_blocked'"
                          @click.native.stop="
                            handleClickNotificationBell([item])
                          "
                        />
                      </div>
                    </div>
                  </td>
                  <table-cell
                    v-for="(key, i) in percentsKeys"
                    :key="i"
                    :class="item._cellClasses[key]"
                    :value="item[key]"
                  />
                </tr>
              </template>
            </q-virtual-scroll>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
    <CandlesGraphModal
      ref="candles"
      :rows="rows"
      :settings="settings"
      :client-id="clientId"
      :is-futures="isActiveFutures"
    />
  </CRow>
</template>

<script>
import CandlesGraphModal from '@/components/screener/tables/CandlesGraphModal.vue'
import TableCellTicker from '@/components/trends/tablesGroup/TableCellTicker'
import TableCellHeader from '@/components/trends/tablesGroup/TableCellHeader'
import TableCell from '@/components/table/TableCell'
import { mapGetters, mapMutations, mapState } from 'vuex'
import TableCellTickerContent from '@/components/trends/tablesGroup/TableCellTickerContent'
import WatchSelector from '@/components/screener/WatchSelector.vue'
import {
  CLIENT_ID_CANDLES_BINANCE_FUTURES,
  CLIENT_ID_DEFAULT,
  SCREENER_PAGE_FUTURES,
} from '@/shared/constants'

const ACTIVE_ROW_CLASS = 'active-row'
const ROW_HEIGHT_MOBILE = 35
const ROW_HEIGHT_TABLET = 35
const ROW_HEIGHT_DESKTOP = 40

export default {
  name: 'TablesGroupItem',
  components: {
    TableCell,
    TableCellHeader,
    TableCellTicker,
    TableCellTickerContent,
    WatchSelector,
    CandlesGraphModal,
  },
  props: {
    settings: Object,
    showBellButtons: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      ticketIdIsShow: null,
      activeRows: [],
      selectShiftActive: false,
      reassignLabel: {
        m30: '30M',
        m60: '1H',
        m120: '2H',
        m240: '4H',
        m720: '12H',
      },
      CLIENT_ID_DEFAULT,
    }
  },
  computed: {
    clientId() {
      return this.isActiveFutures
        ? CLIENT_ID_CANDLES_BINANCE_FUTURES
        : CLIENT_ID_DEFAULT
    },
    isActiveFutures() {
      return this.$store.getters.getLastScreen === SCREENER_PAGE_FUTURES
    },
    virtualScrollItemSize() {
      if (this.isMobile) {
        return ROW_HEIGHT_MOBILE
      } else if (this.isTablet) {
        return ROW_HEIGHT_TABLET
      } else return ROW_HEIGHT_DESKTOP
    },
    reactiveActiveRows() {
      return this.activeRows.map((activeItem) => {
        return this.items.find((item) => item.id === activeItem.id)
      })
    },
    ...mapState('trendsPage', {
      percents: 'itemsAllPercents',
    }),
    ...mapGetters('trendsPage', {
      items: 'itemsAllResult',
      percentsKeys: 'percentsKeys',
    }),
    ...mapGetters({
      isMobile: 'mediaQuery/isMobile',
      isTablet: 'mediaQuery/isTablet',
      isLaptop: 'mediaQuery/isLaptop',
      isDesktop: 'mediaQuery/isDesktop',
    }),
    fields() {
      const cols = this.percentsKeys.map((v) => ({ key: v, label: v }))
      return [{ key: 'ticker', label: 'Ticker' }, ...cols]
    },
    filterIsActive() {
      return this.settings.selectedPairs.filter((el) => !el.disabledOnTrends)
        .length
    },
    rows() {
      return this.items.map((item) => {
        return {
          id: item.id,
          coin: {
            label: `${item.ticker}USDT`,
          },
        }
      })
    },
  },
  watch: {
    items(val) {
      val.forEach((item) => {
        const row = this.activeRows.find((el) => el.id === item.id)

        if (row) {
          item._classes = ACTIVE_ROW_CLASS
        } else {
          item._classes = ''
        }
      })
    },
  },
  methods: {
    ...mapMutations('trendsPage', ['setActiveCol']),
    handleClickNotificationBell(items) {
      this.$emit('addCoinInNotification', items)
      // const query = { ...this.$route.query }
      // query['openNotification'] = 1
      // this.$router.replace({
      //   name: this.$route.name,
      //   query: query,
      // })
    },
    resetSorting() {
      this.setActiveCol(null)
    },
    startTable(i) {
      // const item = this.$refs['row-table'][i]
      // if (item) {
      //   item.scrollIntoView({behavior: this.isMobile ? 'auto' : 'smooth', block: 'center'})
      // }
    },
    scrollToElement(item) {
      const i = this.items.findIndex((obj) => {
        if (obj.id && item.id) return obj.id === item.id
        else return obj.ticker === item.ticker
      })
      this.$refs.virtualListRef.scrollTo(i, 'center')
    },
    animationEnd(item) {
      item._classes = ''
    },
    selectRow(row, e, rows, activeRows) {
      const idIndex = activeRows.findIndex((el) => {
        if (el?.id && row.id) return el.id === row.id
        else return el.ticker === row.ticker
      })

      if (!e.shiftKey && !e.ctrlKey && !e.metaKey) {
        this.selectRowSingle(row, idIndex, rows, activeRows)
      } else if ((e.ctrlKey || e.metaKey) && !e.shiftKey) {
        this.selectRowSingleCtrl(row, idIndex, rows, activeRows)
      } else if (!e.ctrlKey && !e.metaKey && e.shiftKey) {
        this.selectRowMultipleShift(row, rows, activeRows)
      }
    },
    selectRowSingle(row, idIndex, rows, activeRows) {
      const currentRowIndex = rows.findIndex(({ id, ticker }) => {
        if (id && row.id) return id === row.id
        else return row.ticker === ticker
      })
      if (currentRowIndex > -1) {
        if (idIndex > -1 && activeRows.length < 2) {
          activeRows.splice(0, 1)
          rows.forEach((el) => {
            el._classes = ''
          })
        } else {
          rows.forEach((el, index) => {
            if (index === currentRowIndex) {
              el._classes = ACTIVE_ROW_CLASS
            } else {
              el._classes = ''
            }
          })
          activeRows.splice(0, activeRows.length)
          activeRows.push(row)
        }
      }
    },
    selectRowSingleCtrl(row, idIndex, rows, activeRows) {
      if (idIndex > -1) {
        const prevIndex = rows.findIndex(({ id, ticker }) => {
          const item = activeRows[idIndex] || {}
          if (id && item.id) return id === item.id
          else return item.ticker === ticker
        })
        if (prevIndex > -1) {
          rows[prevIndex]._classes = ''
          activeRows.splice(idIndex, 1)
        }
      } else {
        const currentRowIndex = rows.findIndex(({ id, ticker }) => {
          if (id && row.id) return id === row.id
          else return row.ticker === ticker
        })
        if (currentRowIndex > -1) {
          rows[currentRowIndex]._classes = ACTIVE_ROW_CLASS
          activeRows.push(row)
        }
      }
    },
    selectRowMultipleShift(row, rows, activeRows) {
      const sel = window.getSelection()
      sel.removeAllRanges()
      let firstIndex = rows.findIndex(({ id, ticker }) => {
        return activeRows.some((el) => {
          if (el?.id && id) return el.id === id
          else return el.ticker === ticker
        })
      })
      const currentRowIndex = rows.findIndex(({ id, ticker }) => {
        if (id && row.id) return id === row.id
        else return row.ticker === ticker
      })

      firstIndex = firstIndex > -1 ? firstIndex : 0

      if (currentRowIndex > -1) {
        activeRows.splice(0, activeRows.length)
        rows.forEach((el, index) => {
          if (firstIndex <= currentRowIndex) {
            if (index >= firstIndex && index <= currentRowIndex) {
              el._classes = ACTIVE_ROW_CLASS
              activeRows.push(el)
            } else {
              el._classes = ''
            }
          } else {
            if (index >= currentRowIndex && index <= firstIndex) {
              el._classes = ACTIVE_ROW_CLASS
              activeRows.push(el)
            } else {
              el._classes = ''
            }
          }
        })
      }
    },
    keydown(e) {
      if (e.shiftKey) {
        this.selectShiftActive = true
      }
    },
    keyup(e) {
      if (e.key === 'Shift') {
        this.selectShiftActive = false
      }
    },
    removeSelectedPairs(val, activeRows, removeElement) {
      if (Array.isArray(val)) {
        if (removeElement) {
          this.settings.removeSelectedPairs(val)
        }
        val.forEach((item) => {
          const index = activeRows.findIndex((el) => {
            if (el?.id && item.id) return el.id === item.id
            else return el.ticker === item.ticker
          })

          if (index > -1) {
            activeRows.splice(index, 1)
          }
        })
        this.$emit('setSelectedTickersInput', this.settings.selectedPairs)
      } else if (val?.id) {
        const index = activeRows.findIndex((el) => {
          if (el?.id && val.id) return el.id === val.id
          else return el.ticker === val.ticker
        })
        if (removeElement) {
          this.settings.removeSelectedPairs(val)
        }
        if (index > -1) {
          activeRows.splice(index, 1)
        }
        this.$emit('setSelectedTickersInput', this.settings.selectedPairs)
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.keydown, false)
    window.addEventListener('keyup', this.keyup, false)
  },
  destroyed() {
    window.removeEventListener('keydown', this.keydown, false)
    window.removeEventListener('keyup', this.keyup, false)
  },
}
</script>

<style lang="scss" scoped>
.cil-bell-icon {
  cursor: pointer;
  &._blocked {
    color: #ffffff;
    opacity: 0.5;
  }

  &._active {
    color: var(--primary);
  }
}
.table {
  thead {
    tr th {
      background: var(--base-card-bg);
      position: sticky;
      top: -1px;
      z-index: 1;
    }
  }
  /deep/.q-virtual-scroll__padding {
    td {
      padding: 0;
    }
  }
}

.table {
  @include media-breakpoint-up(lg) {
    // flex-grow: 1;
    // overflow: auto;
  }
  position: relative;

  /deep/ .q-table {
    width: 100%;
  }
  /deep/ thead {
    th {
      border-top: none;
      padding: 0;
      vertical-align: bottom !important;
    }
  }
  /deep/ tbody {
    background: var(--second-card-bg);
  }

  /deep/ .table {
    margin-bottom: 0;
  }

  /deep/ {
    th,
    td {
      font-size: 0.75rem;
      letter-spacing: 0.03em;
    }

    th {
      padding-top: 0.5rem;
    }

    th {
      .top {
        padding-top: 0.55rem;
        margin-bottom: 0.55rem;

        @include media-breakpoint-up(md) {
          padding-top: 0;
        }

        @include media-breakpoint-up(xl) {
          padding-top: 0.55rem;
          margin-bottom: 0.25rem;
        }
      }

      .bottom {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        background: var(--dark);
        position: relative;

        @include media-breakpoint-up(xl) {
          padding: 0.56rem 0.875rem;
        }
      }
    }

    td {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      vertical-align: middle;

      @include media-breakpoint-up(lg) {
        font-size: 0.875rem;
      }

      @include media-breakpoint-up(xl) {
        padding: 0.56rem 0.875rem;
      }

      .icon-wrap {
        width: 1.1rem;
        height: 1.1rem;

        @include media-breakpoint-up(lg) {
          width: 1.2rem;
          height: 1.2rem;
        }

        svg:not(.top) {
          width: 100%;
          height: auto;
        }
      }
    }

    tr {
      &.active-row {
        background: var(--table-active-row);
      }
    }
  }
}
.custom-row,
.custom-col {
  @include media-breakpoint-up(lg) {
    height: 100%;
  }
}

.custom-row {
  margin: 0 -0.5rem;
  height: 100%;

  &._no-select {
    user-select: none !important;
  }
}

.custom-col {
  padding: 0 0.5rem;
  height: 100%;

  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: column;
  }
}

.reset {
  background: none;
  padding: 0;
  border: 1px solid #adadad;
  color: #adadad;
  line-height: 0;
  border-radius: 50%;
  outline: none !important;
  width: 21px;
  height: 21px;

  &:hover {
    color: var(--white);
    border-color: var(--white);
  }
}

.animation {
  animation-name: radial-pulse-fake;

  &,
  td {
    animation-duration: 3s;
    animation-timing-function: ease-in;
  }

  td {
    animation-name: radial-pulse;
  }

  td:first-child {
    animation-name: radial-pulse-first;
  }

  td:last-child {
    animation-name: radial-pulse-last;
  }

  &[data-is-mobile] {
    &,
    td {
      animation-iteration-count: 3;
    }
  }
}

@keyframes radial-pulse-fake {
  0%,
  32%,
  64%,
  100% {
    box-shadow: inset 0 0 0 0 var(--primary);
  }
  16%,
  48%,
  81% {
    box-shadow: inset 0 0 0 0 var(--primary);
  }
}

@keyframes radial-pulse {
  0%,
  32%,
  64%,
  100% {
    box-shadow:
      inset 0 2rem 0 -2rem var(--primary),
      inset 0 -2rem 0 -2rem var(--primary);
  }
  16%,
  48%,
  81% {
    box-shadow:
      inset 0 2rem 1.5rem -2rem var(--primary),
      inset 0 -2rem 1.5rem -2rem var(--primary);
  }
}

@keyframes radial-pulse-first {
  0%,
  32%,
  64%,
  100% {
    box-shadow:
      inset 0 2rem 0 -2rem var(--primary),
      inset 0 -2rem 0 -2rem var(--primary),
      inset 2rem 0 0 -2rem var(--primary);
  }
  16%,
  48%,
  81% {
    box-shadow:
      inset 0 2rem 1.5rem -2rem var(--primary),
      inset 0 -2rem 1.5rem -2rem var(--primary),
      inset 2rem 0 1.5rem -2rem var(--primary);
  }
}

@keyframes radial-pulse-last {
  0%,
  32%,
  64%,
  100% {
    box-shadow:
      inset 0 2rem 0 -2rem var(--primary),
      inset 0 -2rem 0 -2rem var(--primary),
      inset -2rem 0 0 -2rem var(--primary);
  }
  16%,
  48%,
  81% {
    box-shadow:
      inset 0 2rem 1.5rem -2rem var(--primary),
      inset 0 -2rem 1.5rem -2rem var(--primary),
      inset -2rem 0 1.5rem -2rem var(--primary);
  }
}

.vs__deselect {
  fill: var(--white);
  opacity: 0.2;
  flex-shrink: 0;
  margin-right: 0.5rem;
  outline: none !important;
  transition: opacity ease-in-out 0.2s;

  &:hover,
  &:focus-visible {
    opacity: 0.3;
  }
}
.update-time {
  display: flex;
  color: var(--success);
  font-size: 0.75rem;
  &__time {
    opacity: 0.5;
    margin-left: 6px;
  }
}
.cil-bar-chart-btn {
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  display: flex;
  margin-left: 5px;
}
.tickerCell {
  min-width: 126px;
}
</style>
