<template>
<CCard class="h-100">
  <CCard
    style="marginBottom: 10px"
    color="second-card-bg"
    class="ccard shadow-none h-100 overflow-hidden"
  >
    <CCardBody class="ccard-body d-flex flex-column h-100 overflow-hidden">
      <template v-if="draftList.length">
          <!-- <h5 class="mb-4">
            {{ $t('lk.notifications.activeListTitle') }}
          </h5> -->
          <CDataTable
            sorter
            :fields="fields"
            :items="draftList"
            class="c-data-table h-100 overflow-hidden"
            @row-clicked="rowClicked"
          >
            <template #type="{item}">
              <td>
                {{ !item?.group_data.is_single ? $t('general.group') : $t('general.single') }}
              </td>
            </template>
            <template #signal_message="{item}">
              <td>
                <template v-if="item.group_data.conditions.length">
                  <p v-for="(v, i) in item.group_data.conditions" :key="i" class="m-0">
                    <span v-if="v.tf !== 'price'" class="mr-1">{{ v.tf }}</span>
                    <span class="mr-1">{{ item.group_data.conditionName(v.param) }}</span>
                    <span v-if="v.hasValue">{{v.isNegative ? v.sign : ''}}{{ v.value }}</span>
                  </p>
                </template>
                <template v-else>
                  <p>
                    -
                  </p>
                </template>
              </td>
            </template>
            <template #actions="{item}">
              <td class="text-right text-nowrap">
                <CLoadingButtonCustom
                  color="danger"
                  :loading="loading && deleteId === item.id"
                  :disabled="loading"
                  @click.stop.native="deleteItem(item.id, $event)"
                >
                  {{ $t('general.delete') }}
                </CLoadingButtonCustom>
              </td>
            </template>
          </CDataTable>
        </template>
      <p
      v-if="!loading && !draftList.length"
      class="m-0">
        {{ $t('lk.notifications.emptyActiveTable') }}
      </p>
    </CCardBody>
  </CCard>
  </CCard>
</template>

<script>
import axios from "axios";
import Condition from "@/assets/js/notifications/Condition.class";
import Notification from "@/assets/js/notifications/Notification.class";
export default {
  name: "NotificationDrafts",
  props: {
    loading: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      Notification,
      draftList: [],
      deleteId: null,
      fields: [
        { key: 'created_at', label: this.$t('lk.notifications.create') ,sorter: false},
        { key: 'type', label: this.$t('lk.notifications.typeOfNotification'), sorter: false },
        {
          key: 'signal_message',
          label: this.$t('lk.notifications.triggerConditions'),
          sorter: false
        },
        { key: 'actions', label: '', sorter: false },
      ],
    }
  },
   computed: {

  },
  created(){
    this.getDrafts()
  },
  methods: {
    getDrafts() {
      this.$emit('update:loading', true)
      axios.get('api/v2/marketplace-draft')
        .then(({ status, data }) => {
          if (status < 300) {
            this.draftList = data.data.map(item => {
              return {
                ...item,
                group_data: new Notification({
                  ...item.group_data,
                  conditions: item.group_data.conditions ? item.group_data.conditions : []})
              }
            })
          }
        })
      .finally(() => {
        this.$emit('update:loading', false)
      })
    },
    deleteItem (id, e) {
      this.deleteId = id
      this.$emit('update:loading', true)
      e.target.blur()
      axios.delete('api/v2/marketplace-draft/' + id)
        .then(({ status, data }) => {
          if (status < 300) {
            this.$toastr.success(this.$t('lk.notifications.draftDeleted'))
            this.draftList = data.data
          }
        })
        .finally(() => {
          this.deleteId = null
          this.$emit('update:loading', false)
        })
    },
    rowClicked(notify) {
      if(this.loading){
        return
      }
      const notifyInstance = new Notification({
        ...notify.group_data,
        draft_id: notify.id,
        sync_watchlist: false,
        conditions: notify.group_data.conditions ? notify.group_data.conditions : [new Condition()]
      })
      this.$emit('rowClicked', notifyInstance)
    },
  }
}
</script>

<style lang="scss" scoped>
.ccard {

  @include media-breakpoint-down(xs) {
    .ccard-body {
      padding: 1rem 1.75rem;
    }
  }
  @include media-breakpoint-down(sm) {
    margin: 0 -1.25rem;
  }
  @include media-breakpoint-up(sm) {
    margin: 0 -2rem;

    .ccard-body {
      padding: 1.25rem 1.75rem;
    }
  }
  @include media-breakpoint-up(xl) {
    margin: 0;
  }
}

.c-data-table {
  /deep/ {
    tbody {
      tr {
        cursor: pointer;
      }
    }

    thead {
      th {
        background: var(--second-card-bg);
        position: sticky;
        top: -1px;
        z-index: 1;
      }
    }

    .table-responsive {
      height: 100%;
    }
  }
}
</style>
