<template>
  <CHeaderNav :class="['header-main-nav', { isMobile }]">
    <router-link
      v-for="(page, pageIndex) in items"
      v-slot="{ isActive, href, navigate }"
      :key="`pageIndex-${pageIndex}`"
      :to="page.route"
    >
      <CHeaderNavItem :class="['header-main-nav__item', page.class, { isMobile}]">
        <a
          :class="['header-main-nav__link', { isActive, isMobile }]"
          :href="href"
          @click="setRoute($event, navigate, page)"
        >
          <span class="header-main-nav__link-inner">
            {{ page.title }}
          </span>
        </a>
      </CHeaderNavItem>
    </router-link>
  </CHeaderNav>
</template>

<script>
import { mapActions } from 'vuex';

// Компоненты:
import { CHeaderNav, CHeaderNavItem } from '@coreui/vue-pro';

export default {
  props: {
    items: Array,
    isMobile: Boolean,
    showDropdown: Boolean,
  },

  emits: [
    'update:showDropdown'
  ],

  watch: {
    showedItems (value) {
      this.initNavItems(value)
    }
  },

  methods: {
    ...mapActions({
      toggleShowPromoModal: 'promo/toggleShowPromoModal',
    }),
    /**
     * Показать промо модалку
     */
    showTrendsPromo () {
      this.toggleShowPromoModal({
        show: true,
        content: {
          id: 'trends',
          sliderData: [{src:'promo6'}, {src:'promo4'}, {src:'promo5'}]
        }
      })
    },
    /**
     * Обработчик навигации.
     * @param {Event} event - События клика.
     * @param {import('vue-router').NavigationGuard} navigate - Метод навигации.
     * @param {import('vue-router').RawLocation} route - Роут.
     */
    setRoute (event, navigate, route) {
      if (!route.hasAccess){
        event.preventDefault();
        this.showTrendsPromo()
      }
      else {
        navigate(event)
        this.$emit('update:showDropdown', false)
      }
    },
  },

  components: {
    CHeaderNav,
    CHeaderNavItem,
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/includes/dark-schema';

.header-main-nav {
  $this: &;

  display: flex;
  align-items: stretch !important;
  min-height: 0 !important;


  // Модификаторы:
  &:not(.isMobile) {
    height: 100%;
  }
  &.isMobile {
    display: flex;
    flex-direction: column;
  }

  // Элементы:
  &__item {
    order: 2;
  }

  &__item._black-friday {
    // Дочерние:
    #{$this}__link {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      opacity: 1;
      background-color: transparent !important;
    }

    #{$this}__link-inner {
      align-self: center;
      text-shadow: 0 0 9px #D60000, 0 0 10px #F10000;
      font-weight: 700;
      text-transform: uppercase;
      border-radius: 32px;
      border: 1px solid;
      box-shadow: 0 0 3px 0 #D60000, 0 0 6px 0 #FFF, 0 0 3px 0 #F10000, 0 0 6px 0 #F10000;
      display: inline-block;
      padding: 3px 8px;
      text-align: center;
      font-size: 12px;
      text-decoration: none !important;
    }

    // Модификаторы:
    &.isMobile {
      padding: 0px 0 8px;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    height: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    opacity: 0.7;
    text-align: center;
    color: inherit !important;
    cursor: pointer;

    &:hover {
      opacity: 1;
      text-decoration: none;
    }

    @include media-breakpoint-down(md) {
      font-size: .75rem;
    }

    // Модификаторы:
    &.isActive {
      opacity: 1;
      color: var(--primary) !important;
      background-color: var(--tab-active-bg);
    }

    &.isMobile {
      min-height: 36px !important;
      padding: 8px 20px;
      font-size: 13px;
      text-align: left;

      &:hover {
        background-color: #ebedef;

        @include dark-schema {
          background-color: #3b3c45;
        }
      }

      // Модификаторы:
      &.isActive {
        color: #fff !important;
        background-color: var(--primary);
      }
    }
  }
}
</style>
