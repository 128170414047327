export default {
  "layout": "s",
  "symbol": "OVERALL:BTCUSD",
  "interval": "60",
  "charts": [
    {
      "panes": [
        {
          "sources": [
            {
              "type": "MainSeries",
              "id": "iyHOwS",
              "state": {
                "style": 1,
                "esdShowDividends": true,
                "esdShowSplits": true,
                "esdShowEarnings": true,
                "esdShowBreaks": false,
                "esdFlagSize": 2,
                "showCountdown": false,
                "bidAsk": {
                  "visible": false,
                  "lineStyle": 1,
                  "lineWidth": 1,
                  "bidLineColor": "#2962FF",
                  "askLineColor": "#F7525F"
                },
                "prePostMarket": {
                  "visible": true,
                  "lineStyle": 1,
                  "lineWidth": 1,
                  "preMarketColor": "#fb8c00",
                  "postMarketColor": "#2962FF"
                },
                "highLowAvgPrice": {
                  "highLowPriceLinesVisible": false,
                  "highLowPriceLabelsVisible": false,
                  "averageClosePriceLineVisible": false,
                  "averageClosePriceLabelVisible": false
                },
                "showInDataWindow": true,
                "visible": true,
                "showPriceLine": true,
                "priceLineWidth": 1,
                "priceLineColor": "",
                "baseLineColor": "#5d606b",
                "showPrevClosePriceLine": false,
                "prevClosePriceLineWidth": 1,
                "prevClosePriceLineColor": "rgba( 85, 85, 85, 1)",
                "minTick": "default",
                "dividendsAdjustment": {},
                "backAdjustment": false,
                "settlementAsClose": true,
                "sessionId": "regular",
                "sessVis": false,
                "statusViewStyle": {
                  "fontSize": 16,
                  "showExchange": true,
                  "showInterval": true,
                  "symbolTextSource": "description"
                },
                "candleStyle": {
                  "upColor": "#26a69a",
                  "downColor": "#ef5350",
                  "drawWick": true,
                  "drawBorder": true,
                  "borderColor": "#378658",
                  "borderUpColor": "#26a69a",
                  "borderDownColor": "#ef5350",
                  "wickColor": "#B5B5B8",
                  "wickUpColor": "#26a69a",
                  "wickDownColor": "#ef5350",
                  "barColorsOnPrevClose": false,
                  "drawBody": true
                },
                "hollowCandleStyle": {
                  "upColor": "#26a69a",
                  "downColor": "#ef5350",
                  "drawWick": true,
                  "drawBorder": true,
                  "borderColor": "#378658",
                  "borderUpColor": "#26a69a",
                  "borderDownColor": "#ef5350",
                  "wickColor": "#B5B5B8",
                  "wickUpColor": "#26a69a",
                  "wickDownColor": "#ef5350",
                  "drawBody": true
                },
                "haStyle": {
                  "upColor": "#26a69a",
                  "downColor": "#ef5350",
                  "drawWick": true,
                  "drawBorder": true,
                  "borderColor": "#378658",
                  "borderUpColor": "#26a69a",
                  "borderDownColor": "#ef5350",
                  "wickColor": "#B5B5B8",
                  "wickUpColor": "#26a69a",
                  "wickDownColor": "#ef5350",
                  "showRealLastPrice": false,
                  "barColorsOnPrevClose": false,
                  "inputs": {},
                  "inputInfo": {},
                  "drawBody": true
                },
                "barStyle": {
                  "upColor": "#26a69a",
                  "downColor": "#ef5350",
                  "barColorsOnPrevClose": false,
                  "dontDrawOpen": false,
                  "thinBars": true
                },
                "hiloStyle": {
                  "color": "#2962FF",
                  "showBorders": true,
                  "borderColor": "#2962FF",
                  "showLabels": true,
                  "labelColor": "#2962FF",
                  "fontSize": 7,
                  "drawBody": true
                },
                "lineStyle": {
                  "color": "#2962FF",
                  "linestyle": 0,
                  "linewidth": 2,
                  "priceSource": "close",
                  "styleType": 2
                },
                "areaStyle": {
                  "color1": "rgba(41, 98, 255, 0.28)",
                  "color2": "#2962FF",
                  "linecolor": "#2962FF",
                  "linestyle": 0,
                  "linewidth": 2,
                  "priceSource": "close",
                  "transparency": 100
                },
                "renkoStyle": {
                  "upColor": "#26a69a",
                  "downColor": "#ef5350",
                  "borderUpColor": "#26a69a",
                  "borderDownColor": "#ef5350",
                  "upColorProjection": "#336854",
                  "downColorProjection": "#7f323f",
                  "borderUpColorProjection": "#336854",
                  "borderDownColorProjection": "#7f323f",
                  "wickUpColor": "#26a69a",
                  "wickDownColor": "#ef5350",
                  "inputs": {
                    "source": "close",
                    "sources": "Close",
                    "boxSize": 3,
                    "style": "ATR",
                    "atrLength": 14,
                    "wicks": true
                  },
                  "inputInfo": {
                    "source": {
                      "name": "Source"
                    },
                    "sources": {
                      "name": "Source"
                    },
                    "boxSize": {
                      "name": "Box size"
                    },
                    "style": {
                      "name": "Style"
                    },
                    "atrLength": {
                      "name": "ATR length"
                    },
                    "wicks": {
                      "name": "Wicks"
                    }
                  }
                },
                "pbStyle": {
                  "upColor": "#26a69a",
                  "downColor": "#ef5350",
                  "borderUpColor": "#26a69a",
                  "borderDownColor": "#ef5350",
                  "upColorProjection": "#336854",
                  "downColorProjection": "#7f323f",
                  "borderUpColorProjection": "#336854",
                  "borderDownColorProjection": "#7f323f",
                  "inputs": {
                    "source": "close",
                    "lb": 3
                  },
                  "inputInfo": {
                    "source": {
                      "name": "Source"
                    },
                    "lb": {
                      "name": "Number of line"
                    }
                  }
                },
                "kagiStyle": {
                  "upColor": "#26a69a",
                  "downColor": "#ef5350",
                  "upColorProjection": "#336854",
                  "downColorProjection": "#7f323f",
                  "inputs": {
                    "source": "close",
                    "style": "ATR",
                    "atrLength": 14,
                    "reversalAmount": 1
                  },
                  "inputInfo": {
                    "source": {
                      "name": "Source"
                    },
                    "style": {
                      "name": "Style"
                    },
                    "atrLength": {
                      "name": "ATR length"
                    },
                    "reversalAmount": {
                      "name": "Reversal amount"
                    }
                  }
                },
                "pnfStyle": {
                  "upColor": "#26a69a",
                  "downColor": "#ef5350",
                  "upColorProjection": "#336854",
                  "downColorProjection": "#7f323f",
                  "inputs": {
                    "sources": "Close",
                    "reversalAmount": 3,
                    "boxSize": 1,
                    "style": "ATR",
                    "atrLength": 14,
                    "oneStepBackBuilding": false
                  },
                  "inputInfo": {
                    "sources": {
                      "name": "Source"
                    },
                    "boxSize": {
                      "name": "Box size"
                    },
                    "reversalAmount": {
                      "name": "Reversal amount"
                    },
                    "style": {
                      "name": "Style"
                    },
                    "atrLength": {
                      "name": "ATR length"
                    },
                    "oneStepBackBuilding": {
                      "name": "One step back building"
                    }
                  }
                },
                "baselineStyle": {
                  "baselineColor": "rgba( 117, 134, 150, 1)",
                  "topFillColor1": "rgba( 38, 166, 154, 0.28)",
                  "topFillColor2": "rgba( 38, 166, 154, 0.05)",
                  "bottomFillColor1": "rgba( 239, 83, 80, 0.05)",
                  "bottomFillColor2": "rgba( 239, 83, 80, 0.28)",
                  "topLineColor": "rgba( 38, 166, 154, 1)",
                  "bottomLineColor": "rgba( 239, 83, 80, 1)",
                  "topLineWidth": 2,
                  "bottomLineWidth": 2,
                  "priceSource": "close",
                  "transparency": 50,
                  "baseLevelPercentage": 50
                },
                "rangeStyle": {
                  "upColor": "#26a69a",
                  "downColor": "#ef5350",
                  "thinBars": true,
                  "upColorProjection": "#336854",
                  "downColorProjection": "#7f323f",
                  "inputs": {
                    "range": 10,
                    "phantomBars": false
                  },
                  "inputInfo": {
                    "range": {
                      "name": "Range"
                    },
                    "phantomBars": {
                      "name": "Phantom bars"
                    }
                  }
                },
                "symbol": "OVERALL:BTCUSD",
                "shortName": "OVERALL:BTCUSD",
                "timeframe": "",
                "onWidget": false,
                "interval": "60",
                "unitId": null,
                "showSessions": false,
                "currencyId": "USD",
                "priceAxisProperties": {
                  "autoScale": true,
                  "autoScaleDisabled": false,
                  "lockScale": false,
                  "percentage": false,
                  "percentageDisabled": false,
                  "log": false,
                  "logDisabled": false,
                  "alignLabels": true,
                  "isInverted": false,
                  "indexedTo100": false
                }
              },
              "zorder": 2,
              "haStyle": {
                "studyId": "BarSetHeikenAshi@tv-basicstudies-60"
              },
              "renkoStyle": {
                "studyId": "BarSetRenko@tv-prostudies-40"
              },
              "pbStyle": {
                "studyId": "BarSetPriceBreak@tv-prostudies-34"
              },
              "kagiStyle": {
                "studyId": "BarSetKagi@tv-prostudies-34"
              },
              "pnfStyle": {
                "studyId": "BarSetPnF@tv-prostudies-34"
              },
              "rangeStyle": {
                "studyId": "BarSetRange@tv-basicstudies-72"
              }
            },
            {
              "type": "Study",
              "id": "GrxzuS",
              "state": {
                "styles": {
                  "ma50": {
                    "linestyle": 0,
                    "linewidth": 1,
                    "plottype": 0,
                    "trackPrice": false,
                    "visible": true,
                    "color": "rgba(0, 0, 255, 1)",
                    "transparency": 65,
                    "histogramBase": 0,
                    "joinPoints": false,
                    "title": "MA50"
                  },
                  "ma100": {
                    "linestyle": 0,
                    "linewidth": 1,
                    "plottype": 0,
                    "trackPrice": false,
                    "visible": true,
                    "color": "rgba(255, 255, 255, 1)",
                    "transparency": 65,
                    "histogramBase": 0,
                    "joinPoints": false,
                    "title": "MA100"
                  },
                  "ma200": {
                    "linestyle": 0,
                    "linewidth": 1,
                    "plottype": 0,
                    "trackPrice": false,
                    "visible": true,
                    "color": "rgba(255, 255, 0, 1)",
                    "transparency": 65,
                    "histogramBase": 0,
                    "joinPoints": false,
                    "title": "MA200"
                  }
                },
                "palettes": {},
                "inputs": {
                  "resolution": "",
                  "length50": 50,
                  "source50": "close",
                  "offset50": 0,
                  "length100": 100,
                  "source100": "close",
                  "offset100": 0,
                  "length200": 200,
                  "source200": "close",
                  "offset200": 0
                },
                "precision": "default",
                "bands": {},
                "area": {},
                "graphics": {},
                "showInDataWindow": true,
                "visible": true,
                "showLegendValues": true,
                "showLabelsOnPriceScale": true,
                "plots": {
                  "0": {
                    "id": "ma50",
                    "type": "line"
                  },
                  "1": {
                    "id": "ma100",
                    "type": "line"
                  },
                  "2": {
                    "id": "ma200",
                    "type": "line"
                  }
                },
                "_metainfoVersion": 50,
                "id": "MA50_100_200@tv-basicstudies",
                "isTVScript": false,
                "isTVScriptStub": false,
                "is_hidden_study": false,
                "isCustomIndicator": true,
                "transparency": 65,
                "description": "MA 50+100+200",
                "shortDescription": "MA 50+100+200",
                "is_price_study": true,
                "description_localized": "MA 50+100+200",
                "shortId": "MA50_100_200",
                "packageId": "tv-basicstudies",
                "version": "1",
                "fullId": "MA50_100_200@tv-basicstudies-1",
                "productId": "tv-basicstudies",
                "_serverMetaInfoVersion": 1,
                "format": {
                  "type": "volume"
                },
                "intervalsVisibilities": {
                  "ticks": true,
                  "seconds": true,
                  "secondsFrom": 1,
                  "secondsTo": 59,
                  "minutes": true,
                  "minutesFrom": 1,
                  "minutesTo": 59,
                  "hours": true,
                  "hoursFrom": 1,
                  "hoursTo": 24,
                  "days": true,
                  "daysFrom": 1,
                  "daysTo": 366,
                  "weeks": true,
                  "weeksFrom": 1,
                  "weeksTo": 52,
                  "months": true,
                  "monthsFrom": 1,
                  "monthsTo": 12,
                  "ranges": true
                }
              },
              "zorder": -10000,
              "ownFirstValue": null,
              "metaInfo": {
                "palettes": {},
                "inputs": [
                  {
                    "id": "resolution",
                    "name": "resolution",
                    "type": "resolution",
                    "defval": "",
                    "options": [
                      "",
                      "1",
                      "5",
                      "15",
                      "30",
                      "60",
                      "120",
                      "240",
                      "720",
                      "1D",
                      "1W"
                    ],
                    "optionsTitles": {
                      "1": "1m",
                      "5": "5m",
                      "15": "15m",
                      "30": "30m",
                      "60": "1h",
                      "120": "2h",
                      "240": "4h",
                      "720": "12h",
                      "": "График",
                      "1D": "1d",
                      "1W": "1w"
                    }
                  },
                  {
                    "id": "length50",
                    "name": "Length",
                    "defval": 50,
                    "min": 1,
                    "max": 2000,
                    "type": "integer"
                  },
                  {
                    "id": "source50",
                    "name": "Source",
                    "type": "source",
                    "defval": "close"
                  },
                  {
                    "id": "offset50",
                    "name": "Offset",
                    "defval": 0,
                    "min": -500,
                    "max": 500,
                    "type": "integer"
                  },
                  {
                    "id": "length100",
                    "name": "Length",
                    "defval": 100,
                    "min": 1,
                    "max": 2000,
                    "type": "integer"
                  },
                  {
                    "id": "source100",
                    "name": "Source",
                    "type": "source",
                    "defval": "close"
                  },
                  {
                    "id": "offset100",
                    "name": "Offset",
                    "defval": 0,
                    "min": -500,
                    "max": 500,
                    "type": "integer"
                  },
                  {
                    "id": "length200",
                    "name": "Length",
                    "defval": 200,
                    "min": 1,
                    "max": 2000,
                    "type": "integer"
                  },
                  {
                    "id": "source200",
                    "name": "Source",
                    "type": "source",
                    "defval": "close"
                  },
                  {
                    "id": "offset200",
                    "name": "Offset",
                    "defval": 0,
                    "min": -500,
                    "max": 500,
                    "type": "integer"
                  }
                ],
                "plots": [
                  {
                    "id": "ma50",
                    "type": "line"
                  },
                  {
                    "id": "ma100",
                    "type": "line"
                  },
                  {
                    "id": "ma200",
                    "type": "line"
                  }
                ],
                "graphics": {},
                "defaults": {
                  "styles": {
                    "ma50": {
                      "linestyle": 0,
                      "linewidth": 1,
                      "plottype": 0,
                      "trackPrice": false,
                      "visible": true,
                      "color": "blue",
                      "transparency": 65
                    },
                    "ma100": {
                      "linestyle": 0,
                      "linewidth": 1,
                      "plottype": 0,
                      "trackPrice": false,
                      "visible": true,
                      "color": "white",
                      "transparency": 65
                    },
                    "ma200": {
                      "linestyle": 0,
                      "linewidth": 1,
                      "plottype": 0,
                      "trackPrice": false,
                      "visible": true,
                      "color": "yellow",
                      "transparency": 65
                    }
                  },
                  "palettes": {},
                  "inputs": {
                    "resolution": "",
                    "length50": 50,
                    "source50": "close",
                    "offset50": 0,
                    "length100": 100,
                    "source100": "close",
                    "offset100": 0,
                    "length200": 200,
                    "source200": "close",
                    "offset200": 0
                  }
                },
                "_metainfoVersion": 50,
                "id": "MA50_100_200@tv-basicstudies-1",
                "isTVScript": false,
                "isTVScriptStub": false,
                "is_hidden_study": false,
                "isCustomIndicator": true,
                "transparency": 65,
                "styles": {
                  "ma50": {
                    "title": "MA50",
                    "histogramBase": 0
                  },
                  "ma100": {
                    "title": "MA100",
                    "histogramBase": 0
                  },
                  "ma200": {
                    "title": "MA200",
                    "histogramBase": 0
                  }
                },
                "description": "MA 50+100+200",
                "shortDescription": "MA 50+100+200",
                "is_price_study": true,
                "description_localized": "MA 50+100+200",
                "shortId": "MA50_100_200",
                "packageId": "tv-basicstudies",
                "version": "1",
                "fullId": "MA50_100_200@tv-basicstudies-1",
                "productId": "tv-basicstudies",
                "_serverMetaInfoVersion": 1,
                "format": {
                  "type": "volume"
                }
              }
            },
            {
              "type": "study_Volume",
              "id": "Zjsmd2",
              "state": {
                "styles": {
                  "vol": {
                    "linestyle": 0,
                    "linewidth": 1,
                    "plottype": 5,
                    "trackPrice": false,
                    "transparency": 65,
                    "visible": true,
                    "color": "#000080",
                    "histogramBase": 0,
                    "joinPoints": false,
                    "title": "Volume"
                  },
                  "vol_ma": {
                    "linestyle": 0,
                    "linewidth": 1,
                    "plottype": 0,
                    "trackPrice": false,
                    "transparency": 0,
                    "visible": false,
                    "color": "#2196f3",
                    "histogramBase": 0,
                    "joinPoints": false,
                    "title": "Volume MA"
                  },
                  "smoothedMA": {
                    "linestyle": 0,
                    "linewidth": 1,
                    "plottype": 0,
                    "trackPrice": false,
                    "transparency": 0,
                    "visible": false,
                    "color": "#2196f3",
                    "histogramBase": 0,
                    "joinPoints": false,
                    "title": "Smoothed MA"
                  }
                },
                "palettes": {
                  "volumePalette": {
                    "colors": {
                      "0": {
                        "color": "#5a6ea2",
                        "width": 1,
                        "style": 0,
                        "name": "Falling"
                      },
                      "1": {
                        "color": "#5a6ea2",
                        "width": 1,
                        "style": 0,
                        "name": "Growing"
                      }
                    }
                  }
                },
                "inputs": {
                  "showMA": false,
                  "length": 20,
                  "col_prev_close": false,
                  "symbol": "",
                  "smoothingLine": "SMA",
                  "smoothingLength": 9
                },
                "precision": "default",
                "bands": {},
                "area": {},
                "graphics": {},
                "showInDataWindow": true,
                "visible": true,
                "showLegendValues": true,
                "showLabelsOnPriceScale": true,
                "plots": {
                  "0": {
                    "id": "vol",
                    "type": "line"
                  },
                  "1": {
                    "id": "volumePalette",
                    "palette": "volumePalette",
                    "target": "vol",
                    "type": "colorer"
                  },
                  "2": {
                    "id": "vol_ma",
                    "type": "line"
                  },
                  "3": {
                    "id": "smoothedMA",
                    "type": "line"
                  }
                },
                "_metainfoVersion": 52,
                "isTVScript": false,
                "isTVScriptStub": false,
                "is_hidden_study": false,
                "description": "Volume",
                "shortDescription": "Volume",
                "is_price_study": false,
                "id": "Volume@tv-basicstudies",
                "format": {
                  "type": "volume"
                },
                "description_localized": "Volume",
                "shortId": "Volume",
                "packageId": "tv-basicstudies",
                "version": "1",
                "fullId": "Volume@tv-basicstudies-1",
                "productId": "tv-basicstudies",
                "_serverMetaInfoVersion": 52,
                "intervalsVisibilities": {
                  "ticks": true,
                  "seconds": true,
                  "secondsFrom": 1,
                  "secondsTo": 59,
                  "minutes": true,
                  "minutesFrom": 1,
                  "minutesTo": 59,
                  "hours": true,
                  "hoursFrom": 1,
                  "hoursTo": 24,
                  "days": true,
                  "daysFrom": 1,
                  "daysTo": 366,
                  "weeks": true,
                  "weeksFrom": 1,
                  "weeksTo": 52,
                  "months": true,
                  "monthsFrom": 1,
                  "monthsTo": 12,
                  "ranges": true
                }
              },
              "zorder": -20000,
              "ownFirstValue": null,
              "metaInfo": {
                "palettes": {
                  "volumePalette": {
                    "colors": {
                      "0": {
                        "name": "Falling"
                      },
                      "1": {
                        "name": "Growing"
                      }
                    }
                  }
                },
                "inputs": [
                  {
                    "id": "symbol",
                    "name": "Other Symbol",
                    "defval": "",
                    "type": "symbol",
                    "optional": true,
                    "isHidden": false
                  },
                  {
                    "id": "showMA",
                    "name": "show MA",
                    "defval": false,
                    "type": "bool",
                    "isHidden": true
                  },
                  {
                    "id": "length",
                    "name": "MA Length",
                    "defval": 20,
                    "type": "integer",
                    "min": 1,
                    "max": 2000
                  },
                  {
                    "defval": false,
                    "id": "col_prev_close",
                    "name": "Color based on previous close",
                    "type": "bool"
                  },
                  {
                    "id": "smoothingLine",
                    "name": "Smoothing Line",
                    "defval": "SMA",
                    "type": "text",
                    "options": [
                      "SMA",
                      "EMA",
                      "WMA"
                    ]
                  },
                  {
                    "id": "smoothingLength",
                    "name": "Smoothing Length",
                    "defval": 9,
                    "type": "integer",
                    "min": 1,
                    "max": 10000
                  }
                ],
                "plots": [
                  {
                    "id": "vol",
                    "type": "line"
                  },
                  {
                    "id": "volumePalette",
                    "palette": "volumePalette",
                    "target": "vol",
                    "type": "colorer"
                  },
                  {
                    "id": "vol_ma",
                    "type": "line"
                  },
                  {
                    "id": "smoothedMA",
                    "type": "line"
                  }
                ],
                "graphics": {},
                "defaults": {
                  "styles": {
                    "vol": {
                      "linestyle": 0,
                      "linewidth": 1,
                      "plottype": 5,
                      "trackPrice": false,
                      "transparency": 50,
                      "visible": true,
                      "color": "#000080"
                    },
                    "vol_ma": {
                      "linestyle": 0,
                      "linewidth": 1,
                      "plottype": 0,
                      "trackPrice": false,
                      "transparency": 0,
                      "visible": false,
                      "color": "#2196F3"
                    },
                    "smoothedMA": {
                      "linestyle": 0,
                      "linewidth": 1,
                      "plottype": 0,
                      "trackPrice": false,
                      "transparency": 0,
                      "visible": false,
                      "color": "#2196F3"
                    }
                  },
                  "palettes": {
                    "volumePalette": {
                      "colors": {
                        "0": {
                          "color": "#F7525F",
                          "width": 1,
                          "style": 0
                        },
                        "1": {
                          "color": "#22AB94",
                          "width": 1,
                          "style": 0
                        }
                      }
                    }
                  },
                  "inputs": {
                    "showMA": false,
                    "length": 20,
                    "col_prev_close": false,
                    "symbol": "",
                    "smoothingLine": "SMA",
                    "smoothingLength": 9
                  }
                },
                "_metainfoVersion": 52,
                "isTVScript": false,
                "isTVScriptStub": false,
                "is_hidden_study": false,
                "styles": {
                  "vol": {
                    "title": "Volume",
                    "histogramBase": 0
                  },
                  "vol_ma": {
                    "title": "Volume MA",
                    "histogramBase": 0
                  },
                  "smoothedMA": {
                    "title": "Smoothed MA",
                    "histogramBase": 0
                  }
                },
                "description": "Volume",
                "shortDescription": "Volume",
                "is_price_study": false,
                "id": "Volume@tv-basicstudies-1",
                "format": {
                  "type": "volume"
                },
                "description_localized": "Volume",
                "shortId": "Volume",
                "packageId": "tv-basicstudies",
                "version": "1",
                "fullId": "Volume@tv-basicstudies-1",
                "productId": "tv-basicstudies",
                "_serverMetaInfoVersion": 52
              }
            }
          ],
          "leftAxisesState": [],
          "rightAxisesState": [
            {
              "state": {
                "id": "cSTDKJOH3R4R",
                "m_priceRange": {
                  "m_maxValue": 29063.37540000002,
                  "m_minValue": 27577.01
                },
                "m_isAutoScale": true,
                "m_isPercentage": false,
                "m_isIndexedTo100": false,
                "m_isLog": false,
                "m_isLockScale": false,
                "m_isInverted": false,
                "m_height": 210.4,
                "m_topMargin": 0.1,
                "m_bottomMargin": 0.08,
                "alignLabels": true,
                "logFormula": {
                  "logicalOffset": 4,
                  "coordOffset": 0.0001
                }
              },
              "sources": [
                "iyHOwS",
                "GrxzuS"
              ]
            }
          ],
          "overlayPriceScales": {
            "Zjsmd2": {
              "id": "wZbCCOqjA22x",
              "m_priceRange": {
                "m_maxValue": 172156287.30914,
                "m_minValue": 0
              },
              "m_isAutoScale": true,
              "m_isPercentage": false,
              "m_isIndexedTo100": false,
              "m_isLog": false,
              "m_isLockScale": false,
              "m_isInverted": false,
              "m_height": 210.4,
              "m_topMargin": 0.1,
              "m_bottomMargin": 0.08,
              "alignLabels": true,
              "logFormula": {
                "logicalOffset": 4,
                "coordOffset": 0.0001
              }
            }
          },
          "stretchFactor": 2000,
          "mainSourceId": "iyHOwS",
          "priceScaleRatio": null
        },
        {
          "sources": [
            {
              "type": "Study",
              "id": "MY5po3",
              "state": {
                "styles": {
                  "delta": {
                    "linestyle": 0,
                    "linewidth": 1,
                    "plottype": 5,
                    "trackPrice": false,
                    "visible": true,
                    "color": "#000080",
                    "transparency": 65,
                    "histogramBase": 0,
                    "joinPoints": false,
                    "title": "Delta"
                  },
                  "delta_ma": {
                    "linestyle": 0,
                    "linewidth": 2,
                    "plottype": 0,
                    "trackPrice": false,
                    "visible": false,
                    "color": "#ffeb3b",
                    "transparency": 20,
                    "histogramBase": 0,
                    "joinPoints": false,
                    "title": "Delta MA"
                  }
                },
                "palettes": {
                  "deltaPalette": {
                    "colors": {
                      "0": {
                        "color": "#eb4d5c",
                        "width": 1,
                        "style": 0,
                        "name": "< 0"
                      },
                      "1": {
                        "color": "#53b987",
                        "width": 1,
                        "style": 0,
                        "name": "> 0"
                      }
                    }
                  }
                },
                "inputs": {
                  "symbol": "",
                  "numberAbs": false,
                  "deltaMaLength": 21
                },
                "precision": "default",
                "bands": {},
                "area": {},
                "graphics": {},
                "showInDataWindow": true,
                "visible": true,
                "showLegendValues": true,
                "showLabelsOnPriceScale": true,
                "plots": {
                  "0": {
                    "id": "delta",
                    "type": "line"
                  },
                  "1": {
                    "id": "deltaPalette",
                    "palette": "deltaPalette",
                    "target": "delta",
                    "type": "colorer"
                  },
                  "2": {
                    "id": "delta_ma",
                    "type": "line"
                  }
                },
                "_metainfoVersion": 50,
                "id": "Delta@tv-basicstudies",
                "isTVScript": false,
                "isTVScriptStub": false,
                "is_hidden_study": false,
                "transparency": 65,
                "description": "Delta",
                "shortDescription": "Delta",
                "is_price_study": false,
                "description_localized": "Delta",
                "shortId": "Delta",
                "packageId": "tv-basicstudies",
                "version": "1",
                "fullId": "Delta@tv-basicstudies-1",
                "productId": "tv-basicstudies",
                "_serverMetaInfoVersion": 1,
                "format": {
                  "type": "volume"
                },
                "intervalsVisibilities": {
                  "ticks": true,
                  "seconds": true,
                  "secondsFrom": 1,
                  "secondsTo": 59,
                  "minutes": true,
                  "minutesFrom": 1,
                  "minutesTo": 59,
                  "hours": true,
                  "hoursFrom": 1,
                  "hoursTo": 24,
                  "days": true,
                  "daysFrom": 1,
                  "daysTo": 366,
                  "weeks": true,
                  "weeksFrom": 1,
                  "weeksTo": 52,
                  "months": true,
                  "monthsFrom": 1,
                  "monthsTo": 12,
                  "ranges": true
                }
              },
              "zorder": -10000,
              "ownFirstValue": null,
              "metaInfo": {
                "palettes": {
                  "deltaPalette": {
                    "colors": {
                      "0": {
                        "name": "< 0"
                      },
                      "1": {
                        "name": "> 0"
                      }
                    }
                  }
                },
                "inputs": [
                  {
                    "id": "symbol",
                    "name": "Other Symbol",
                    "defval": "",
                    "type": "symbol",
                    "optional": true,
                    "isHidden": false
                  },
                  {
                    "id": "numberAbs",
                    "name": "По модулю",
                    "defval": true,
                    "type": "bool"
                  },
                  {
                    "id": "deltaMaLength",
                    "name": "MA Length",
                    "defval": 21,
                    "type": "integer",
                    "min": 1,
                    "max": 2000
                  }
                ],
                "plots": [
                  {
                    "id": "delta",
                    "type": "line"
                  },
                  {
                    "id": "deltaPalette",
                    "palette": "deltaPalette",
                    "target": "delta",
                    "type": "colorer"
                  },
                  {
                    "id": "delta_ma",
                    "type": "line"
                  }
                ],
                "graphics": {},
                "defaults": {
                  "styles": {
                    "delta": {
                      "linestyle": 0,
                      "linewidth": 1,
                      "plottype": 5,
                      "trackPrice": false,
                      "visible": true,
                      "color": "#000080",
                      "transparency": 65
                    },
                    "delta_ma": {
                      "linestyle": 0,
                      "linewidth": 2,
                      "plottype": 0,
                      "trackPrice": false,
                      "visible": false,
                      "color": "#ffeb3b",
                      "transparency": 20
                    }
                  },
                  "palettes": {
                    "deltaPalette": {
                      "colors": {
                        "0": {
                          "color": "#eb4d5c",
                          "width": 1,
                          "style": 0
                        },
                        "1": {
                          "color": "#53b987",
                          "width": 1,
                          "style": 0
                        }
                      }
                    }
                  },
                  "inputs": {
                    "symbol": "",
                    "numberAbs": false,
                    "deltaMaLength": 21
                  }
                },
                "_metainfoVersion": 50,
                "id": "Delta@tv-basicstudies-1",
                "isTVScript": false,
                "isTVScriptStub": false,
                "is_hidden_study": false,
                "transparency": 65,
                "styles": {
                  "delta": {
                    "title": "Delta",
                    "histogramBase": 0
                  },
                  "delta_ma": {
                    "title": "Delta MA",
                    "histogramBase": 0
                  }
                },
                "description": "Delta",
                "shortDescription": "Delta",
                "is_price_study": false,
                "description_localized": "Delta",
                "shortId": "Delta",
                "packageId": "tv-basicstudies",
                "version": "1",
                "fullId": "Delta@tv-basicstudies-1",
                "productId": "tv-basicstudies",
                "_serverMetaInfoVersion": 1,
                "format": {
                  "type": "volume"
                }
              }
            }
          ],
          "leftAxisesState": [],
          "rightAxisesState": [
            {
              "state": {
                "id": "5bRhnYzRovjX",
                "m_priceRange": {
                  "m_maxValue": 13088595.715332,
                  "m_minValue": -20870857.241241
                },
                "m_isAutoScale": true,
                "m_isPercentage": false,
                "m_isIndexedTo100": false,
                "m_isLog": false,
                "m_isLockScale": false,
                "m_isInverted": false,
                "m_height": 104.8,
                "m_topMargin": 0.1,
                "m_bottomMargin": 0.08,
                "alignLabels": true,
                "logFormula": {
                  "logicalOffset": 4,
                  "coordOffset": 0.0001
                }
              },
              "sources": [
                "MY5po3"
              ]
            }
          ],
          "overlayPriceScales": {},
          "stretchFactor": 1000,
          "mainSourceId": "MY5po3",
          "priceScaleRatio": null
        },
        {
          "sources": [
            {
              "type": "Study",
              "id": "5r8HBF",
              "state": {
                "styles": {
                  "accum_delta": {
                    "linestyle": 0,
                    "linewidth": 1,
                    "plottype": 5,
                    "trackPrice": false,
                    "visible": true,
                    "color": "#000080",
                    "transparency": 65,
                    "histogramBase": 0,
                    "joinPoints": false,
                    "title": "Accum Delta"
                  }
                },
                "palettes": {
                  "accumDeltaPalette": {
                    "colors": {
                      "0": {
                        "color": "#eb4d5c",
                        "width": 1,
                        "style": 0,
                        "name": "< 0"
                      },
                      "1": {
                        "color": "#53b987",
                        "width": 1,
                        "style": 0,
                        "name": "> 0"
                      }
                    }
                  }
                },
                "inputs": {
                  "symbol": "",
                  "fromDate": "",
                  "endDate": ""
                },
                "precision": "default",
                "bands": {},
                "area": {},
                "graphics": {},
                "showInDataWindow": true,
                "visible": true,
                "showLegendValues": true,
                "showLabelsOnPriceScale": true,
                "plots": {
                  "0": {
                    "id": "accum_delta",
                    "type": "line"
                  },
                  "1": {
                    "id": "accumDeltaPalette",
                    "palette": "accumDeltaPalette",
                    "target": "accum_delta",
                    "type": "colorer"
                  }
                },
                "_metainfoVersion": 50,
                "id": "AccumDelta@tv-basicstudies",
                "isTVScript": false,
                "isTVScriptStub": false,
                "is_hidden_study": false,
                "transparency": 65,
                "description": "Accum Delta",
                "shortDescription": "Accum Delta",
                "is_price_study": false,
                "description_localized": "Accum Delta",
                "shortId": "AccumDelta",
                "packageId": "tv-basicstudies",
                "version": "1",
                "fullId": "AccumDelta@tv-basicstudies-1",
                "productId": "tv-basicstudies",
                "_serverMetaInfoVersion": 1,
                "format": {
                  "type": "volume"
                },
                "intervalsVisibilities": {
                  "ticks": true,
                  "seconds": true,
                  "secondsFrom": 1,
                  "secondsTo": 59,
                  "minutes": true,
                  "minutesFrom": 1,
                  "minutesTo": 59,
                  "hours": true,
                  "hoursFrom": 1,
                  "hoursTo": 24,
                  "days": true,
                  "daysFrom": 1,
                  "daysTo": 366,
                  "weeks": true,
                  "weeksFrom": 1,
                  "weeksTo": 52,
                  "months": true,
                  "monthsFrom": 1,
                  "monthsTo": 12,
                  "ranges": true
                }
              },
              "zorder": -10000,
              "ownFirstValue": null,
              "metaInfo": {
                "palettes": {
                  "accumDeltaPalette": {
                    "colors": {
                      "0": {
                        "name": "< 0"
                      },
                      "1": {
                        "name": "> 0"
                      }
                    }
                  }
                },
                "inputs": [
                  {
                    "id": "symbol",
                    "name": "Other Symbol",
                    "defval": "",
                    "type": "symbol",
                    "optional": true,
                    "isHidden": false
                  },
                  {
                    "id": "fromDate",
                    "name": "From date(DD.MM.YYYY)",
                    "defval": "",
                    "type": "text"
                  },
                  {
                    "id": "endDate",
                    "name": "End date(DD.MM.YYYY)",
                    "defval": "",
                    "type": "text"
                  }
                ],
                "plots": [
                  {
                    "id": "accum_delta",
                    "type": "line"
                  },
                  {
                    "id": "accumDeltaPalette",
                    "palette": "accumDeltaPalette",
                    "target": "accum_delta",
                    "type": "colorer"
                  }
                ],
                "graphics": {},
                "defaults": {
                  "styles": {
                    "accum_delta": {
                      "linestyle": 0,
                      "linewidth": 1,
                      "plottype": 5,
                      "trackPrice": false,
                      "visible": true,
                      "color": "#000080",
                      "transparency": 65
                    }
                  },
                  "palettes": {
                    "accumDeltaPalette": {
                      "colors": {
                        "0": {
                          "color": "#eb4d5c",
                          "width": 1,
                          "style": 0
                        },
                        "1": {
                          "color": "#53b987",
                          "width": 1,
                          "style": 0
                        }
                      }
                    }
                  },
                  "inputs": {
                    "symbol": "",
                    "fromDate": "",
                    "endDate": ""
                  }
                },
                "_metainfoVersion": 50,
                "id": "AccumDelta@tv-basicstudies-1",
                "isTVScript": false,
                "isTVScriptStub": false,
                "is_hidden_study": false,
                "transparency": 65,
                "styles": {
                  "accum_delta": {
                    "title": "Accum Delta",
                    "histogramBase": 0
                  }
                },
                "description": "Accum Delta",
                "shortDescription": "Accum Delta",
                "is_price_study": false,
                "description_localized": "Accum Delta",
                "shortId": "AccumDelta",
                "packageId": "tv-basicstudies",
                "version": "1",
                "fullId": "AccumDelta@tv-basicstudies-1",
                "productId": "tv-basicstudies",
                "_serverMetaInfoVersion": 1,
                "format": {
                  "type": "volume"
                }
              }
            }
          ],
          "leftAxisesState": [],
          "rightAxisesState": [
            {
              "state": {
                "id": "KCOA59Fluhvn",
                "m_priceRange": {
                  "m_maxValue": 0,
                  "m_minValue": -309277373.9782775
                },
                "m_isAutoScale": true,
                "m_isPercentage": false,
                "m_isIndexedTo100": false,
                "m_isLog": false,
                "m_isLockScale": false,
                "m_isInverted": false,
                "m_height": 104.8,
                "m_topMargin": 0.1,
                "m_bottomMargin": 0.08,
                "alignLabels": true,
                "logFormula": {
                  "logicalOffset": 4,
                  "coordOffset": 0.0001
                }
              },
              "sources": [
                "5r8HBF"
              ]
            }
          ],
          "overlayPriceScales": {},
          "stretchFactor": 1000,
          "mainSourceId": "5r8HBF",
          "priceScaleRatio": null
        }
      ],
      "timeScale": {
        "m_barSpacing": 33.740498216700004,
        "m_rightOffset": 10
      },
      "chartProperties": {
        "paneProperties": {
          "backgroundType": "gradient",
          "background": "#131722",
          "backgroundGradientStartColor": "#181C27",
          "backgroundGradientEndColor": "#131722",
          "vertGridProperties": {
            "color": "rgba(240, 243, 250, 0.06)",
            "style": 0
          },
          "horzGridProperties": {
            "color": "rgba(240, 243, 250, 0.06)",
            "style": 0
          },
          "crossHairProperties": {
            "color": "#9598A1",
            "style": 2,
            "transparency": 0,
            "width": 1
          },
          "topMargin": 10,
          "bottomMargin": 8,
          "axisProperties": {
            "autoScale": true,
            "autoScaleDisabled": false,
            "lockScale": false,
            "percentage": false,
            "percentageDisabled": false,
            "indexedTo100": false,
            "log": false,
            "logDisabled": false,
            "alignLabels": true,
            "isInverted": false
          },
          "legendProperties": {
            "showStudyArguments": true,
            "showStudyTitles": true,
            "showStudyValues": true,
            "showSeriesTitle": true,
            "showSeriesOHLC": true,
            "showLegend": true,
            "showBarChange": true,
            "showVolume": false,
            "showBackground": true,
            "backgroundTransparency": 50,
            "wrapText": false
          }
        },
        "scalesProperties": {
          "backgroundColor": "#ffffff",
          "lineColor": "rgba(240, 243, 250, 0.12)",
          "textColor": "#B2B5BE",
          "fontSize": 12,
          "scaleSeriesOnly": false,
          "showSeriesLastValue": true,
          "seriesLastValueMode": 1,
          "showSeriesPrevCloseValue": false,
          "showStudyLastValue": false,
          "showSymbolLabels": false,
          "showStudyPlotLabels": false,
          "showBidAskLabels": false,
          "showPrePostMarketPriceLabel": true,
          "showFundamentalNameLabel": false,
          "showFundamentalLastValue": false,
          "barSpacing": 6,
          "showCurrency": true,
          "showUnit": true,
          "axisHighlightColor": "rgba(41, 98, 255, 0.25)",
          "axisLineToolLabelBackgroundColorCommon": "#2962FF",
          "axisLineToolLabelBackgroundColorActive": "#143EB3",
          "showPriceScaleCrosshairLabel": true,
          "showTimeScaleCrosshairLabel": true
        },
        "chartEventsSourceProperties": {
          "visible": true,
          "futureOnly": true,
          "breaks": {
            "color": "rgba(85, 85, 85, 1)",
            "visible": false,
            "style": 2,
            "width": 1
          }
        },
        "tradingProperties": {
          "showPositions": true,
          "positionPL": {
            "visibility": true,
            "display": 0
          },
          "showOrders": true,
          "showExecutions": true,
          "showReverse": true,
          "horizontalAlignment": 2,
          "extendLeft": true,
          "lineLength": 5,
          "lineWidth": 1,
          "lineStyle": 0
        },
        "priceScaleSelectionStrategyName": "auto"
      },
      "lineToolsGroups": {
        "groups": []
      },
      "version": 3,
      "timezone": "Etc/UTC",
      "sessions": {
        "properties": {
          "graphics": {
            "backgrounds": {
              "outOfSession": {
                "color": "#2962FF",
                "transparency": 92,
                "visible": false
              },
              "preMarket": {
                "color": "#FF9800",
                "transparency": 92,
                "visible": false
              },
              "postMarket": {
                "color": "#2962FF",
                "transparency": 92,
                "visible": false
              }
            },
            "vertlines": {
              "sessBreaks": {
                "color": "#4985e7",
                "style": 2,
                "visible": false,
                "width": 1
              }
            }
          }
        }
      },
      "shouldBeSavedEvenIfHidden": true,
      "chartId": "a9VAF1bk7QqD"
    }
  ]
}
