<template>
  <CHeader
    ref="headerRef"
    class="cheader justify-content-between"
  >
    <CHeaderBrand
      ref="logoRef"
      :class="'cheader-brand'"
      :href="!isCurrentUrl ? homepageWithLang : null"
      :to="isCurrentUrl ? homepageWithLang : null"
      :target="isCurrentUrl ? '_self' : '_blank'"
    >
      <LogoWrap :small-on-mobile="true"/>
    </CHeaderBrand>

    <!-- Селект выбора биржи и экрана: -->
    <div :class="'cheader-block-with-separator mr-auto'">
      <HeaderExchangAndType/>

      <!-- Главная навигация: -->
      <HeaderMainNav
        :items="mainNavItems"
        :class="'cheader-block-with-separator'"
      />
    </div>

    <!-- Социальные ссылки и "о нас": -->
    <HeaderSocials
      :items="mainSocialsItems"
      :hide-about-us="teleportAboutUs"
    />

    <CHeaderNav
      ref="secondMenuRef"
      class="cheader-nav"
    >
      <HeaderSchemaToggle class="_mobile-hidden"/>

      <CHeaderNavLink
        v-if="isGuest"
        class="custom-nav-item"
      >
        <CButton
          @click="toggleShowLoginModal({show: true})"
          color="primary"
          class="d-flex"
        >
          <CIcon name="cil-lock-locked" />
          <span class="ml-2">{{$t('formLogin.signIn')}}</span>
        </CButton>
      </CHeaderNavLink>

      <CDropdown
        :show.sync="showDropdown"
        :customPopperOptions="customPopperOptions"
        inNav
        class="profile"
      >
        <template #toggler>
          <CHeaderNavLink class="custom-nav-item custom-nav-item--profile">
            <CIcon
              name="menu"
              class="mobile-menu-open _mobile-tablet-only"
            />

            <CIcon
              name="cil-x"
              class="mobile-menu-close _mobile-tablet-only"
            />

            <span
              class="_mobile-tablet-hidden font-weight-bold"
            >
              {{$store.state.user.userData.name || 'Профиль'}}
            </span>

            <CIcon
              name="arrow-trends"
              class="arrow-bottom arrow-bottom--desktop _mobile-tablet-hidden"
              width="0.5rem"
            />
          </CHeaderNavLink>
        </template>

        <div :class="['social-links-wrap d-flex align-items-center', { 'justify-content-end': isMobile, isMobile }]">
          <!-- Социальные ссылки и "о нас": -->
          <HeaderSocials
            :items="burgerSocialsItems"
            :hide-about-us="true"
          />

          <!-- Таглер темы приложения: -->
          <HeaderSchemaToggle class="_mobile-only"/>
        </div>

        <CDropdownDivider class="_mobile-only"/>

        <template>
          <!-- Основная навигация: -->
          <HeaderMainNav
            :items="burgerNavItems"
            :is-mobile="true"
            :showDropdown.sync="showDropdown"
          />

          <!-- Кнопка "О нас": -->
          <CDropdownItem
            v-if="teleportAboutUs"
            :class="'nav-subitem'"
            :to="{ name: 'about-us' }"
            >
            {{$t('header.aboutUs')}}
          </CDropdownItem>

          <!-- Разделитель: -->
          <CDropdownDivider />
        </template>


        <template v-for="(item, i) in dropdownMenuItems">
          <template v-if="item.show">
            <CDropdownItem
              v-if="item.name"
              :key="i"
              :to="{ name: item.name }"
              class="nav-subitem"
            >
              <CIcon v-if="item.iconName" :name="item.iconName"/>
              <span class="ml-2">{{ item.i18nKey ? $t(item.i18nKey) : (item.label || '') }}</span>
            </CDropdownItem>
            <CDropdownItem
              v-else
              :key="i"
              :href="$t(item.path)"
              target="_blank"
              class="nav-subitem"
            >
              <CIcon v-if="item.iconName" :name="item.iconName"/>
              <span class="ml-2">{{ item.i18nKey ? $t(item.i18nKey) : (item.label || '') }}</span>
            </CDropdownItem>
          </template>
        </template>

        <CDropdownDivider/>

        <CDropdownItem
          v-if="isGuest"
          class="nav-subitem"
          @click.native="toggleShowLoginModal({show: true})"
        >
          <CIcon name="cil-lock-locked" />
          <span class="ml-2">{{$t('formLogin.signIn')}}</span>
        </CDropdownItem>
        <CDropdownItem
          v-else
          class="nav-subitem"
          @click.native="logoutBtnClick"
        >
          <CIcon name="cil-lock-locked" />
          <span class="ml-2">{{$t('header.logout')}}</span>
        </CDropdownItem>
      </CDropdown>

      <onboarding-btn
        v-if="showOnboarding"
        :steps-data="onboardingSteps"
        name="delta"
        class="onboarding-btn"
      />
    </CHeaderNav>
  </CHeader>
</template>

<script>
import Author from "@/assets/js/Author.class"
import {mapActions, mapGetters} from "vuex"
import {dropdownMenuItems} from "@/assets/js/dropdownMenuItems"

// Компоненты:
import LogoWrap from "@/components/LogoWrap"
import OnboardingBtn from "@/components/onboarding/OnboardingBtn"
import HeaderMainNav from "./HeaderMainNav.vue"
import HeaderSocials from "./HeaderSocials.vue"
import HeaderExchangAndType from "./HeaderExchangAndType.vue"
import HeaderSchemaToggle from "./HeaderSchemaToggle.vue"
import cloneDeep from "lodash/cloneDeep"

const TELEGRAM_ICON = Author.socialNetworkSvg('telegram');
const TWITTER_ICON = Author.socialNetworkSvg('x').replace(/^<svg(\s{1,})/, '$&style="padding:3px"$1');

export default {
  name: 'Header',
  components: {
    LogoWrap,
    OnboardingBtn,
    HeaderMainNav,
    HeaderSocials,
    HeaderSchemaToggle,
    HeaderExchangAndType,
  },
  data() {
    return {
      headerIsOverflow: false,
      headerOverflowSize: 0,
      showDropdown: false,
      mobileMenuWidth: 10,
      headerWidth: 10,
      logoWidth: 10,
      customPopperOptions: {
        strategy: 'fixed'
      },
      onboardingSteps: this.$t('onboarding.deltaPage'),
      dropdownMenuItems: dropdownMenuItems(),
      homepage: process.env.VUE_APP_HOMEPAGE_URL || this.$store.getters['user/homepage'],
      teleportAboutUs: false,
      teleportAboutUsBreakpoint: 0,
      headerNavItemsBreakpoints: [],
      headerSocialItemsBreakpoints: [],
    }
  },
  watch: {
    initialNavItems (value) {
      this.initNavItems({ items: cloneDeep(value), target: this.isMobile ? 'burger' : 'main'})
    },
    initialSocialsItems (value) {
      this.initSocialsItems({ items: cloneDeep(value), target: this.isMobile ? 'burger' : 'main'})
    },
    isMobile (value) {
      this.teleportHeaderItems(value ? 'burger' : 'main')
    }
  },
  computed: {
    ...mapGetters({
      isDesktop: 'mediaQuery/isDesktop',
      isTablet: 'mediaQuery/isTablet',
      isMobile: 'mediaQuery/isMobile',
      isGuest: 'user/isGuest',
      mainNavItems: 'headerItems/mainNavItems',
      mainSocialsItems: 'headerItems/mainSocialsItems',
      burgerNavItems: 'headerItems/burgerNavItems',
      burgerSocialsItems: 'headerItems/burgerSocialsItems',
      permissions: 'user/getPermissions'
    }),
    initialSocialsItems () {
      return [
        {
          svg: TELEGRAM_ICON,
          link: this.$t('aboutUs.tgTTTools')
        },
        {
          svg: TWITTER_ICON,
          link: 'https://twitter.com/cvizorcom',
        },
      ];
    },
    initialNavItems () {
      const items = [
        {
          route: { name: 'delta' },
          title: this.$t('header.delta'),
          isShow: this.permissions('view_delta'),
          hasAccess: true
        },
        {
          route: {
            name: 'screener',
            params: {
              screen: this.$store.getters.getLastScreen,
              exchange: this.$store.getters.getLastExchange,
            }
          },
          title: this.$t('header.screener'),
          isShow: this.permissions('view_screener'),
          hasAccess: true
        },
        {
          route: {
            name: 'trends',
            params: {
              screen: this.$store.getters.getLastScreen,
              exchange: this.$store.getters.getLastExchange,
            }
          },
          title: this.$t('header.trends'),
          isShow: true,
          hasAccess: this.permissions('view_trends')
        },
        {
          route: { path: '/marketplace' },
          title: this.$t('marketplace.title'),
          isShow: true /*getPermission('view_trends')*/,
          hasAccess: true
        },
        {
          route: { path: '/lk/study' },
          title: this.$t('header.education'),
          isShow: this.permissions('view-education') || this.permissions('control-education'),
          hasAccess: true
        },
        {
          route: { path: '/lk/subscribe?study=1' },
          title: this.$t('header.education'),
          isShow: !(this.permissions('view-education') || this.permissions('control-education')),
          hasAccess: true
        },
        /*{
          class: '_black-friday',
          route: { path: '/lk/subscribe' },
          title: 'Black Friday',
          isShow: true,
          hasAccess: true
        }*/
      ];
      // console.log(items.filter(item => item.isShow))
      return items.filter(item => item.isShow)
    },
    homepageWithLang(){
      let hp = process.env.VUE_APP_HOMEPAGE_URL
      if (hp && hp.slice(-1) !== '/') {
        hp += '/'
      }
      if (this.$root.$i18n.locale === 'ru') {
        hp += 'ru/'
      }
      return hp
    },
    userData() {
      return this.$store.state.user.userData;
    },
    showOnboarding() {
      return this.isDesktop && ['delta'].some(name => name === this.$route.name)
    },
    isCurrentUrl() {
      return !process.env.VUE_APP_HOMEPAGE_URL
    },
  },
  methods: {
    ...mapActions({
      logout: 'user/logout',
      toggleShowLoginModal: 'user/toggleShowLoginModal',
      initNavItems: 'headerItems/initNavItems',
      initSocialsItems: 'headerItems/initSocialsItems',
      setMainNavItems: 'headerItems/setMainNavItems',
      setMainSocialsItems: 'headerItems/setMainSocialsItems',
      setBurgerNavItems: 'headerItems/setBurgerNavItems',
      setBurgerSocialsItems: 'headerItems/setBurgerSocialsItems',
      cutMainNavItem: 'headerItems/cutMainNavItem',
      putMainNavItem: 'headerItems/putMainNavItem',
      cutBurgerNavItem: 'headerItems/cutBurgerNavItem',
      putBurgerNavItem: 'headerItems/putBurgerNavItem',
      cutMainSocialsItem: 'headerItems/cutMainSocialsItem',
      putMainSocialsItem: 'headerItems/putMainSocialsItem',
      cutBurgerSocialsItem: 'headerItems/cutBurgerSocialsItem',
      putBurgerSocialsItem: 'headerItems/putBurgerSocialsItem',
    }),
    async logoutBtnClick () {
      const hasToken = this.$store.state.user.accessToken
      if (hasToken) await this.logout()
      window.location.reload();
    },
    signInBtnClick () {
      this.$router.push({name: 'auth'})
    },
    teleportHeaderItems (targer, breakpoint) {
      if (targer === 'burger') {
        const items = cloneDeep(this.initialNavItems);
        items.unshift(items.pop())

        this.teleportAboutUs = true
        this.teleportAboutUsBreakpoint = breakpoint || window.innerWidth
        this.initNavItems({ items: items, target: 'burger' })
        this.initSocialsItems({ items: cloneDeep(this.initialSocialsItems), target: 'burger' })
      }
      else {
        this.teleportAboutUs = false
        this.initNavItems({ items: cloneDeep(this.initialNavItems) })
        this.initSocialsItems({ items: cloneDeep(this.initialSocialsItems) })
      }
    },
    async windowResizeHandler () {
      const header = this.$refs.headerRef?.$el;
      if (!header || header.clientWidth < 250) return;

      // Ширина шапки:
      const headerPaddings = (parseInt(getComputedStyle(header).paddingLeft) + parseInt(getComputedStyle(header).paddingRight))
      const headerContentWidth = header.clientWidth - (isNaN(headerPaddings) ? 0 : headerPaddings)
      const headerScrollWidth = header.scrollWidth - (isNaN(headerPaddings) ? 0 : headerPaddings)

      // Перенос кнопки "О нас":
      if (!this.teleportAboutUs) {
        if (headerContentWidth < headerScrollWidth && !this.teleportAboutUs) {
          this.teleportAboutUs = true;
          this.teleportAboutUsBreakpoint = headerScrollWidth;
          return this.$nextTick(() => this.windowResizeHandler());
        }
      }
      else if (this.teleportAboutUs && (headerContentWidth > this.teleportAboutUsBreakpoint)) {
        this.teleportAboutUs = false;
        return this.$nextTick(() => this.windowResizeHandler());
      }

      // Перенос иконок соц. сетей:
      if (headerContentWidth < headerScrollWidth) {
        if (this.initialSocialsItems.length !== this.burgerSocialsItems.length) {
          this.headerSocialItemsBreakpoints.push(headerScrollWidth);
          this.setBurgerSocialsItems(cloneDeep(this.initialSocialsItems))
          this.setMainSocialsItems([])
          return this.$nextTick(() => this.windowResizeHandler());
        }
      }
      else if (headerContentWidth > this.headerSocialItemsBreakpoints.at(-1)) {
        this.headerSocialItemsBreakpoints.pop();
        this.setMainSocialsItems(cloneDeep(this.initialSocialsItems))
        this.setBurgerSocialsItems([])
        return this.$nextTick(() => this.windowResizeHandler());
      }

      // Перенос элементов навигации:
      if (headerContentWidth < headerScrollWidth) {
        this.headerNavItemsBreakpoints.push(headerScrollWidth);
        const item = await this.cutMainNavItem({ sideOrIndex: 'end' })

        const isBlackFriday = item?.class === '_black-friday'
        const sideOrIndex = isBlackFriday ? 'start' : 1

        this.putBurgerNavItem({ sideOrIndex, item });
        this.$nextTick(() => this.windowResizeHandler());
      }
      else if (headerContentWidth > this.headerNavItemsBreakpoints.at(-1)) {
        this.headerNavItemsBreakpoints.pop();
        this.putMainNavItem({ sideOrIndex: 'end', item: await this.cutBurgerNavItem({ sideOrIndex: 'end' }) });
        this.$nextTick(() => this.windowResizeHandler());
      }
    },
  },
  mounted(){
    window.addEventListener('resize', this.windowResizeHandler, { passive: true })

    this.$root.$on('dropdownMenuUpdate', () => {
      this.dropdownMenuItems = dropdownMenuItems()
    })

    this.teleportHeaderItems(this.isMobile ? 'burger' : 'main', 768)
    this.windowResizeHandler()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.windowResizeHandler)
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/includes/dark-schema";

$cheader-height-mobile: 3.55rem;

@mixin media-600 {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin media-400 {
  @media (max-width: 400px) {
    @content;
  }
}

.cheader {
  padding: 0 0 0 .875rem;
  flex-wrap: nowrap;

  & > * {
    flex-shrink: 0;
  }

  @include media-600 {
    justify-content: space-between;
  }

  @include media-breakpoint-up(lg) {
    padding: 0 1.5rem;
  }
  @include media-breakpoint-up(xl) {
    padding: 0 3rem;
  }

  @include media-breakpoint-down(sm) {
    min-height: $cheader-height-mobile;
  }
}

.cheader-nav,
.cheader,
.cheader-brand {
  @include media-breakpoint-down(md) {
    min-height: $cheader-height-mobile;
  }
}

.cheader-nav--top {
  flex-grow: 1;
  @include media-600 {
    //display: none;
  }
  // @include media-400 {
  //   .custom-nav-item{
  //     display: none;
  //   }
  // }
}

.profile {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 56px;
  min-height: 56px;

  /deep/ .dropdown-menu {
    @include media-breakpoint-down(sm) {
      position: fixed !important;
      top: $cheader-height-mobile !important;
      left: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      overflow-y: auto;
      transform: unset !important;
      box-shadow: none !important;
      padding: 0 !important;
      border-radius: 0 !important;

      .dropdown-divider {
        margin-left: 1.25rem;
        margin-right: 1.25rem;
      }
    }
  }

  /deep/ .c-header-nav-link {
    height: 100%;
  }

  .mobile-menu-close {
    display: none;
  }

  &.show {
    .mobile-menu-close {
      display: inline;
    }

    .mobile-menu-open {
      display: none;
    }
  }
}

.nav-subitem {
  cursor: pointer;
  @include media-breakpoint-down(md) {
    font-size: .8125rem;
    height: 2.25rem;
    font-weight: 500;

    &--large {
      font-size: 1rem;
      height: 3.625rem;
    }

    &--title {
      font-weight: normal;
      pointer-events: none;
    }

    &--project {
      padding-left: 2rem;
    }
  }

  &:active,
  &.router-link-active {
    color: rgba(255, 255, 255, 0.87);
    background: var(--primary);
  }
}
.arrow-bottom {
  transform: rotate(180deg) translateY(-1px);

  &--desktop {
    @include media-breakpoint-up(lg) {
      margin-left: .5rem;
    }
  }
}

.social-link {
  width: fit-content;

  @include media-breakpoint-up(lg) {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.2rem;
    transition: 150ms ease-out;

    @media (hover) {
      filter: grayscale(1);
    }

    &:hover {
      filter: none;
    }
  }

  &--tablet {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  &:hover {
    path, circle, polygon, g{
      transition: 150ms ease-out;
      fill: #3686F6;
    }
  }
}

.social-links-wrap {
  max-height: 3.625rem;

  // Модификаторы:
  &.isMobile {
    padding: 10px 1.25rem 5px;
  }

  // Дочерние:
  & > * + * {
    margin-left: 1rem;
  }
}

._mobile-hidden {
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}

._mobile-only {
  @include media-breakpoint-up(md) {
    display: none !important;
  }
}

._mobile-tablet-only {
  @include media-breakpoint-up(lg) {
    display: none !important;
  }
}

._mobile-tablet-hidden {
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

.onboarding-btn {
  margin-left: 2rem;
}
.cheader-nav-svg-wrap{
  /deep/ svg {
    display: block;
    margin: auto;
    width: 1.5rem;
    height: 1.5rem;

    path, circle, polygon, g {
      transition: 150ms ease-out;
      fill: var(--color);
    }
    &:hover {
      path, circle, polygon, g{
        transition: 150ms ease-out;
        fill: #3686F6;
      }
    }
  }
}
/deep/ .social-link:hover{
  path, circle, polygon, g{
    transition: 150ms ease-out;
    fill: #3686F6;
  }
}

.black-friday {
  text-shadow: 0px 0px 8px #D60000, 0px 0px 10px #F10000;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 32px;
  border: 1px solid;
  box-shadow: 0px 0px 3px 0px #D60000, 0px 0px 6px 0px #FFF, 0px 0px 3px 0px #F10000, 0px 0px 6px 0px #F10000;
  display: inline-block;
  padding: 3px 8px;
  margin-left: 10px;
  text-align: center;
  font-size: 12px;
  text-decoration: none !important;
}

.cheader-block-with-separator {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 24px;
  margin-left: 24px;

  @include media-breakpoint-down(md) {
    padding-left: 16px;
    margin-left: 16px;
  }
  @include media-breakpoint-down(sm) {
    padding-left: 12px;
    margin-left: 12px;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 50% auto auto 0;
    transform: translate(0, -50%);
    display: block;
    height: 16px;
    border-left: 2px solid #f0f0f0;

    // Модификаторы:
    @include dark-schema {
      border-color: #3C3C3C;
    }
  }
}
</style>
