var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CButton",
    {
      ref: "button",
      class: _vm.buttonClass,
      attrs: {
        active: _vm.active,
        activeClass: _vm.activeClass,
        append: _vm.append,
        block: _vm.block,
        color: _vm.color,
        disabled: _vm.disabled,
        event: _vm.event,
        exact: _vm.exact,
        exactActiveClass: _vm.exactActiveClass,
        href: _vm.href,
        pressed: _vm.pressed,
        rel: _vm.rel,
        replace: _vm.replace,
        routerTag: _vm.routerTag,
        shape: _vm.shape,
        size: _vm.size,
        target: _vm.target,
        to: _vm.to,
        type: _vm.type,
        variant: _vm.variant,
        "data-coreui": "loading-button",
      },
      on: { click: _vm.methodOnClick },
    },
    [
      _vm.progress
        ? _c("div", {
            staticClass: "c-loading-button-progress",
            style: _vm.progressStyle,
            attrs: { "aria-hidden": "true", role: "progressbar" },
          })
        : _vm._e(),
      _c("span", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.didspinner,
            expression: "didspinner",
          },
        ],
        class: _vm.spinnerClass,
        attrs: { "aria-hidden": "true", role: "status" },
      }),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }